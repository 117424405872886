/* @flow */
import styled from "styled-components";

const StyledEntryContent = styled.div`
  flex: 1 1 auto;
  position: relative;
  overflow-y: auto;
`;

export default StyledEntryContent;
