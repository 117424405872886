/** @flow */
import styled from "styled-components";
import { meetsContrastGuidelines, invert } from "polished";

const StyledDropdownItem = styled.a`
  display: block;
  padding: 0.5rem 1.5rem;
  clear: both;
  font-weight: 400;
  opacity: 0.75;
  color: ${(props) => props.theme.global.colors.secondaryColor};
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  text-decoration: none;
  background-color: transparent;
  cursor: pointer;

  &:focus,
  &:hover {
    opacity: 1;
    text-decoration: none;
    color: ${(props) => props.theme.global.colors.secondaryColor};
    background-color: #f8f9fa;
  }

  &:active {
    ${(props) => {
      const { AA } = meetsContrastGuidelines(
        props.theme.global.colors.brand,
        props.theme.global.colors.secondaryColor
      );

      const color = AA
        ? props.theme.global.colors.secondaryColor
        : invert(props.theme.global.colors.secondaryColor);

      return `
        color: ${color};
      `;
    }}

    text-decoration: none;
    background-color: ${(props) => props.theme.global.colors.brand};
  }
`;

export default StyledDropdownItem;
