/* @flow */
import React from "react";

import StyledWidgetReadOnlyEmptyValue from "./styled";
import strings from "../../config/strings";

const WidgetReadOnlyEmptyValue = () => (
  <StyledWidgetReadOnlyEmptyValue>
    {strings.components.widgetReadOnlyEmptyValue}
  </StyledWidgetReadOnlyEmptyValue>
);

export default WidgetReadOnlyEmptyValue;
