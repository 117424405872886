/* @flow */
import React, {
  type ComponentType,
  useCallback,
  useEffect,
  useState,
} from "react";
import { FastField as FormikField, connect as formikConnect } from "formik";
import { get, isArray, isObject } from "lodash-es";

import typeUtils from "../../utils/type";
import { useOrderedList } from "../../utils/hooks";
import Button from "../Button";
import Fieldset from "../Fieldset";
import FieldErrorLabel from "../FieldErrorLabel";
import FieldTitleLabel from "../FieldTitleLabel";
import EntryModel from "../../models/Entry";

import type { JSONSchema, UISchema, ContentType, Tenant } from "../../types";

type WidgetWrapperProps = {
  id: string,
  field: Object,
  form: Object,
  widgetComponent: ComponentType<any>,
  widgetComponentProps: Object,
  widgetSchema?: JSONSchema,
  widgetUISchema?: UISchema,
  schemaType: "boolean" | "number" | "string",
  required?: boolean,
  isOnline?: boolean,
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  entryId: $PropertyType<EntryModel, "id">,
};

const WidgetWrapper = (props: WidgetWrapperProps) => {
  const {
    id,
    field: { onBlur, onChange, value },
    form: { errors, setFieldValue, submitCount, touched },
    widgetComponent: WidgetComponent,
    widgetComponentProps,
    widgetSchema = {},
    widgetUISchema = {},
    schemaType,
    required = false,
    isOnline,
    tenantId,
    contentTypeId,
    entryId,
  } = props;

  /*
   * Problem: Formik has a performance issue with big forms (more than 15/20 fields)
   * Issue: https://github.com/jaredpalmer/formik/issues/1026
   *
   * Solution: update the field value when the onBlur event is triggered or after
   * some time the user has stopped typing
   * TODO: remove the workaround when the issue is fixed
   */
  const [innerValue, setInnerValue] = useState(value);
  const [propagateValueId, setPropagateValueId] = useState(value);

  // Upon field creation, if no value is present, notify Formik about the default value
  useEffect(() => {
    if (value === undefined && widgetComponentProps.defaultValue != null) {
      setFieldValue && setFieldValue(id, widgetComponentProps.defaultValue);
    }
  }, []);

  useEffect(() => {
    setInnerValue(value);
  }, [setInnerValue, value]);

  // change the field value after some time after the user has stopped typing
  useEffect(() => {
    if (propagateValueId != null) {
      clearTimeout(propagateValueId);
    }
    setPropagateValueId(
      setTimeout(() => setFieldValue && setFieldValue(id, innerValue), 50)
    );
    // eslint-disable-next-line
  }, [innerValue]);

  // change the field value after the onBlur event is triggered
  const handleBlur = useCallback(
    (event: any) => {
      if (propagateValueId != null) {
        clearTimeout(propagateValueId);
      }
      setFieldValue && setFieldValue(id, innerValue);
      onBlur(event);
    },
    [propagateValueId, onBlur, setFieldValue, id, innerValue]
  );

  /*
   * Problem: Formik has an issue with initialValues and touched fields: the touched
   * property is reset on form submit if an initial value is not provided for the field.
   * Issue: https://github.com/jaredpalmer/formik/issues/691
   * Issue: https://github.com/jaredpalmer/formik/issues/445
   * Solution: We want to touch all fields on submit (which should be Formik's default behavior).
   *           So the field is touched if it's in the touched object or a submit has happened.
   * TODO: remove the workaround when the issue is fixed
   */
  const fieldError = get(errors, id);
  const isFieldTouched = !!get(touched, id) || submitCount > 0;

  const handleChange = useCallback(
    (eventOrValue: any) => {
      let value = eventOrValue;
      const eventTarget = isObject(eventOrValue)
        ? eventOrValue.currentTarget || eventOrValue.target
        : null;
      if (eventTarget != null) {
        value =
          eventTarget.type === "checkbox"
            ? eventTarget.checked
            : eventTarget.value;
        if (eventTarget.files != null && eventTarget.files.length > 0) {
          value = Array.from(eventTarget.files);
        }
      }
      const targetValueType = [
        "number",
        "integer",
        "boolean",
        "array",
      ].includes(schemaType)
        ? schemaType
        : "string";
      value = typeUtils.coerceToType(targetValueType, value);

      setInnerValue(value);

      // TODO: Remove this when we fix the previous issue where we limit updating Formik on onBlur.
      // HACK: Formik doesn't intercept the onBlur events for checkboxes and radios.
      // So we don't just leave a local state in innerState but we set the field value manually.
      if (
        eventTarget &&
        eventTarget.type &&
        [
          "radio",
          "checkbox",
          "file",
          "fieldRelation",
          "hidden",
          "objectWidgetChange",
        ].includes(eventTarget.type)
      ) {
        setFieldValue && setFieldValue(id, value);
      }
    },
    [id, setFieldValue, setInnerValue, schemaType]
  );

  return (
    <React.Fragment>
      <WidgetComponent
        {...widgetComponentProps}
        id={id}
        name={id}
        onBlur={handleBlur}
        onChange={handleChange}
        value={innerValue}
        schema={widgetSchema}
        uiSchema={widgetUISchema}
        required={required}
        isOnline={isOnline}
        tenantId={tenantId}
        contentTypeId={contentTypeId}
        entryId={entryId}
      />
      {isFieldTouched && fieldError != null && (
        <FieldErrorLabel>{fieldError}</FieldErrorLabel>
      )}
    </React.Fragment>
  );
};

type FieldProps = {
  formik: any,
  id: string,
  type?: "single" | "array",
  name: string,
  validate: (value: any) => string,
  widgetComponent: ComponentType<any>,
  widgetComponentProps: Object,
  widgetSchema?: JSONSchema,
  widgetUISchema?: UISchema,
  schema?: JSONSchema,
  uiSchema?: UISchema,
  required?: boolean,
  readOnly?: boolean,
  isOnline?: boolean,
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  entryId: $PropertyType<EntryModel, "id">,
};

const Field = (props: FieldProps) => {
  const {
    formik,
    id,
    type = "single",
    name,
    validate,
    widgetComponent,
    widgetComponentProps,
    widgetSchema = {},
    widgetUISchema = {},
    schema = {},
    uiSchema = {},
    required = false,
    readOnly = false,
    isOnline = true,
    tenantId,
    contentTypeId,
    entryId,
  } = props;
  const isArrayField = type === "array";
  const isFixedSize = !!uiSchema["ui:fixedSize"];
  const fieldValue = get(
    formik.values,
    id,
    schema.default || uiSchema["ui:emptyValue"]
  );
  const {
    list,
    addItem: listAddItem,
    removeItem: listRemoveItem,
  } = useOrderedList(isArray(fieldValue) ? fieldValue : []);
  const handleAddItem = useCallback(() => {
    const nextFieldValue = (isArray(fieldValue) ? fieldValue : []).concat(
      undefined
    );
    formik.setFieldValue(id, nextFieldValue);
    listAddItem();
  }, [listAddItem, fieldValue, id, formik]);
  const handleRemoveItem = useCallback(
    (index: number) => {
      const nextFieldValue = isArray(fieldValue)
        ? index >= 0 && index < fieldValue.length
          ? [
              ...fieldValue.slice(0, index),
              ...fieldValue.slice(index + 1, fieldValue.length),
            ]
          : fieldValue
        : [];
      formik.setFieldValue(id, nextFieldValue);
      listRemoveItem(index);
    },
    [listRemoveItem, fieldValue, id, formik]
  );
  const renderSingleField = () => (
    <FormikField
      id={id}
      name={id}
      widgetComponent={widgetComponent}
      widgetComponentProps={widgetComponentProps}
      widgetSchema={widgetSchema}
      widgetUISchema={widgetUISchema}
      component={WidgetWrapper}
      validate={validate}
      schemaType={schema.type}
      required={required}
      isOnline={isOnline}
      tenantId={tenantId}
      contentTypeId={contentTypeId}
      entryId={entryId}
    />
  );
  const renderArrayField = () => (
    <Fieldset readOnly={readOnly} elevated={false}>
      {list != null &&
        list.map((_, index) => (
          <div
            key={`${_}${index}`}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <FormikField
              id={`${id}[${index}]`}
              name={`${id}[${index}]`}
              widgetComponent={widgetComponent}
              widgetComponentProps={widgetComponentProps}
              widgetSchema={widgetSchema}
              widgetUISchema={widgetUISchema}
              component={WidgetWrapper}
              validate={validate}
              schemaType={widgetSchema.type || "string"}
              required={required}
              isOnline={isOnline}
              tenantId={tenantId}
              contentTypeId={contentTypeId}
              entryId={entryId}
            />
            <div
              style={{
                paddingLeft: "1em",
              }}
            >
              {!readOnly && !isFixedSize && (
                <Button
                  model={"secondary"}
                  icon={"delete"}
                  onClick={() => handleRemoveItem(index)}
                />
              )}
            </div>
          </div>
        ))}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <span />
        <div style={{ paddingLeft: "1em" }}>
          {!readOnly && !isFixedSize && (
            <Button
              model={"secondary"}
              icon={"add"}
              onClick={() => handleAddItem()}
            />
          )}
        </div>
      </div>
    </Fieldset>
  );

  return (
    <div>
      {schema.type !== "hidden" && (uiSchema["ui:title"] || schema.title) && (
        <FieldTitleLabel
          title={uiSchema["ui:title"] || schema.title}
          description={uiSchema["ui:description"] || schema.description}
          required={required}
          htmlFor={id}
        />
      )}
      {isArrayField ? renderArrayField() : renderSingleField()}
    </div>
  );
};

export default formikConnect(Field);
