/* @flow */
import React, { useCallback } from "react";

import StyledCheckbox from "./styled";

type Props = {|
  id: string,
  name: string,
  onBlur?: (event: any) => any,
  onChange?: (event: any) => any,
  value?: boolean,
  defaultValue?: boolean,
  label?: string,
  readOnly?: boolean,
  isOnline?: boolean,
|};

const CheckboxWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue = false,
  label,
  readOnly = false,
  isOnline,
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );
  return (
    <StyledCheckbox.Container readOnly={readOnly || !isOnline} htmlFor={id}>
      <StyledCheckbox.Label>{label}</StyledCheckbox.Label>
      <StyledCheckbox.Input
        type={"checkbox"}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        checked={(value != null ? value : defaultValue) || false}
        value={(value != null ? value : defaultValue) || false}
        readOnly={readOnly || !isOnline}
      />
      <StyledCheckbox.Box
        checked={(value != null ? value : defaultValue) || false}
      />
    </StyledCheckbox.Container>
  );
};

CheckboxWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.readOnly = schema.readOnly;
  return props;
};

export default CheckboxWidget;
