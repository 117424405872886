/* @flow */
import React from "react";

import StyledEntryList from "./styled";
import Sequence from "../Sequence";
import EntryHeader from "../EntryHeader";

import { CubeProvider } from "@cubejs-client/react";
import cubejs from "@cubejs-client/core";

import DashboardItem from "../DashboardItem";
import ChartRenderer from "../ChartRenderer";
import DashboardLayout from "./layout";

import keys from "../../config/keys";

import type { ContentType } from "../../types";

type DashboardContainerProps = {
  contentType: ContentType | null,
  authToken: string | null,
};

const deserializeItem = (i) => ({
  ...i,
  layout: JSON.parse(i.layout) || {},
  vizState: JSON.parse(i.vizState),
});

const defaultLayout = (i) => ({
  x: i.layout.x || 0,
  y: i.layout.y || 0,
  w: i.layout.w || 4,
  h: i.layout.h || 8,
  minW: 1,
  minH: 1,
});

const DashboardContainer = ({
  contentType,
  authToken,
}: DashboardContainerProps) => {
  const cubejsApi = cubejs(authToken, {
    apiUrl: keys.STAZA_BI_ENDPOINT_URL,
  });

  let dashboards = [];
  let items = null;

  const mapDashboardItem = (item) => {
    return (
      <div key={item.id} data-grid={defaultLayout(item)}>
        <DashboardItem key={item.id} itemId={item.id} title={item.name}>
          <ChartRenderer vizState={item.vizState} />
        </DashboardItem>
      </div>
    );
  };

  if (contentType && contentType.schema) {
    dashboards = Object.keys(contentType.schema.properties);

    items = contentType.schema.properties[dashboards[0]].configuration
      .map(deserializeItem)
      .map(mapDashboardItem);
  }

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <Sequence type={"vertical"}>
        <EntryHeader.Header>
          <StyledEntryList.Title>
            {contentType && contentType.schema ? contentType.schema.title : ""}
          </StyledEntryList.Title>
        </EntryHeader.Header>
        <StyledEntryList.ContentWrapper>
          <DashboardLayout>{items}</DashboardLayout>
        </StyledEntryList.ContentWrapper>
      </Sequence>
    </CubeProvider>
  );
};
export default DashboardContainer;
