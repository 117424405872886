/* @flow */
import * as React from "react";
import { Redirect, Route } from "react-router-dom";

import navigationUtils from "../../utils/navigation";

type PrivateRouteProps = {
  component: React.ComponentType<any>,
  isAuthenticated: boolean,
};

const PrivateRoute = ({
  component: Component,
  isAuthenticated,
  ...rest
}: PrivateRouteProps) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} {...rest} />
        ) : (
          <Redirect
            to={{
              pathname: navigationUtils.routes.auth.login(),
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
