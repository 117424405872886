/* @flow */
import React, { useCallback, useEffect } from "react";
import { inject, Observer } from "mobx-react";
import { withTranslation } from "react-i18next";
import type {
  Location as RouterLocation,
  Match as RouterMatch,
  RouterHistory,
} from "react-router-dom";
import type { TFunction } from "react-i18next";

import StyledContentTypeList from "./styled";
import Sidebar from "../Sidebar";
import ScreenContainer from "../../components/ScreenContainer";
import navigationUtils from "../../utils/navigation";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import EntryHeader from "../../components/EntryHeader";
import Card from "../../components/Card";

import type { ContentType, Stores, Tenant } from "../../types";

type OwnProps = {
  history: RouterHistory,
  location: RouterLocation,
  match: RouterMatch,
  t: TFunction,
};

type StoresProps = {
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypes: ContentType[],
  isLoading: boolean,
  fetchTenants: () => Promise<any>,
  fetchContentTypes: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Promise<any>,
  getTenant: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Tenant | null,
  setApplicationConfiguration: (configuration: Object) => any,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const tenantId = props.match.params.tenantId;

  return {
    tenantId,
    contentTypes: stores.contentType.contentTypes,
    isLoading: stores.tenant.isLoading || stores.contentType.isLoading,
    fetchTenants: stores.tenant.fetchTenants,
    fetchContentTypes: stores.contentType.fetchContentTypes,
    getTenant: stores.tenant.getTenant,
    setApplicationConfiguration: stores.app.setApplicationConfiguration,
  };
};

const ContentTypeListScreen = (props: Props) => {
  const {
    tenantId,
    contentTypes,
    history,
    location,
    isLoading,
    fetchTenants,
    fetchContentTypes,
    setApplicationConfiguration,
    getTenant,
    t,
  } = props;

  const initialize = async () => {
    if (tenantId) {
      await fetchContentTypes(tenantId);

      const tenant = getTenant(tenantId);
      if (tenant) {
        setApplicationConfiguration(tenant.configuration);
      }
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleContentTypeClick = useCallback(
    (contentTypeId) => {
      navigationUtils.goTo(
        { history, location },
        navigationUtils.routes.entry.list(tenantId, contentTypeId)
      );
    },
    [history, location, tenantId]
  );

  return (
    <Observer
      render={() => (
        <ScreenContainer>
          <Sidebar />
          <Container>
            <EntryHeader.Header>
              <StyledContentTypeList.Title>
                {t("title")}
              </StyledContentTypeList.Title>
            </EntryHeader.Header>
            {isLoading && <Spinner size={150} />}
            <StyledContentTypeList.CardsContainer>
              {contentTypes.map((contentType, index) => (
                <Card
                  key={contentType.id}
                  icon={contentType.uiSchema["ui:icon"] || "thLarge"}
                  title={contentType.schema.title}
                  onClick={() => handleContentTypeClick(contentType.id)}
                />
              ))}
            </StyledContentTypeList.CardsContainer>
          </Container>
        </ScreenContainer>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation(["contentTypeList"])(ContentTypeListScreen)
);
