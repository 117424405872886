/** @flow */
/* https://github.com/react-bootstrap/react-bootstrap/blob/master/src/Dropdown.js */
import React, { useContext } from "react";
import BaseDropdown from "react-overlays/Dropdown";
import { useUncontrolled } from "uncontrollable";
import useEventCallback from "@restart/hooks/useEventCallback";
import SelectableContext from "./SelectableContext";

import StyledDropdown from "./styled";

type Props = {
  /**
   * Determines the direction and location of the Menu in relation to it's Toggle.
   */
  drop?: "up" | "left" | "right" | "down",

  as?: any,

  /**
   * Align the menu to the right side of the Dropdown toggle
   */
  alignRight?: boolean,

  /**
   * Whether or not the Dropdown is visible.
   *
   * @controllable onToggle
   */
  show?: boolean,

  /**
   * Allow Dropdown to flip in case of an overlapping on the reference element. For more information refer to
   * Popper.js's flip [docs](https://popper.js.org/popper-documentation.html#modifiers..flip.enabled).
   *
   */
  flip?: boolean,

  /**
   * A callback fired when the Dropdown wishes to change visibility. Called with the requested
   * `show` value, the DOM event, and the source that fired it: `'click'`,`'keydown'`,`'rootClose'`, or `'select'`.
   *
   * ```js
   * function(
   *   isOpen: boolean,
   *   event: SyntheticEvent,
   *   metadata: {
   *     source: 'select' | 'click' | 'rootClose' | 'keydown'
   *   }
   * ): void
   * ```
   *
   * @controllable show
   */
  onToggle?: () => any,

  /**
   * A callback fired when a menu item is selected.
   *
   * ```js
   * (eventKey: any, event: Object) => any
   * ```
   */
  onSelect?: () => any,

  /**
   * Controls the focus behavior for when the Dropdown is opened. Set to
   * `true` to always focus the first menu item, `keyboard` to focus only when
   * navigating via the keyboard, or `false` to disable completely
   *
   * The Default behavior is `false` **unless** the Menu has a `role="menu"`
   * where it will default to `keyboard` to match the recommended [ARIA Authoring practices](https://www.w3.org/TR/wai-aria-practices-1.1/#menubutton).
   */
  focusFirstItemOnShow?: false | true | "keyboard",
};

const Dropdown = React.forwardRef<Props, HTMLDivElement>(
  (uncontrolledProps, ref) => {
    const {
      drop,
      show,
      className,
      alignRight,
      onSelect,
      onToggle,
      focusFirstItemOnShow,
      // Need to define the default "as" during prop destructuring to be compatible with styled-components github.com/react-bootstrap/react-bootstrap/issues/3595
      as: Component = StyledDropdown,
      ...props
    } = useUncontrolled(uncontrolledProps, { show: "onToggle" });

    const onSelectCtx = useContext(SelectableContext);
    const prefix = "dropdown";

    const handleToggle = useEventCallback(
      (nextShow, event, source = event.type) => {
        if (event.currentTarget === document) source = "rootClose";
        onToggle(nextShow, event, { source });
      }
    );

    const handleSelect = useEventCallback((key, event) => {
      if (onSelectCtx) onSelectCtx(key, event);
      if (onSelect) onSelect(key, event);
      handleToggle(false, event, "select");
    });

    return (
      <SelectableContext.Provider value={handleSelect}>
        <BaseDropdown
          drop={drop}
          show={show}
          alignEnd={alignRight}
          onToggle={handleToggle}
          focusFirstItemOnShow={focusFirstItemOnShow}
          itemSelector={`.${prefix}-item:not(.disabled):not(:disabled)`}
        >
          {({ props: dropdownProps }) => (
            <Component
              {...props}
              {...dropdownProps}
              ref={ref}
              className={`${className} ${show ? "show" : ""} ${
                !drop || drop === "down" ? prefix : ""
              } ${drop === "up" ? "dropup" : ""} ${
                drop === "right" ? "dropright" : ""
              } ${drop === "left" ? "dropleft" : ""}`}
            />
          )}
        </BaseDropdown>
      </SelectableContext.Provider>
    );
  }
);

export default Dropdown;
