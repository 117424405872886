/* @flow */
import React, { useCallback, useEffect, useState } from "react";
import { inject, Observer } from "mobx-react";
import { toJS } from "mobx";
import type {
  Match as RouterMatch,
  Location as RouterLocation,
  RouterHistory,
} from "react-router-dom";
import { withTranslation } from "react-i18next";

import StyledEntryDetail from "./styled";

import strings from "../../config/strings";
import aclUtils from "../../utils/acl";
import navigationUtils from "../../utils/navigation";
import { difference } from "../../utils/object";
import EntryModel from "../../models/Entry";
import Sidebar from "../Sidebar";
import ScreenContainer from "../../components/ScreenContainer";
import Container from "../../components/Container";
import EntryHeader from "../../components/EntryHeader";
import EntryContent from "../../components/EntryContent";
import BackButton from "../../components/BackButton";
import Form from "../../components/Form";
import Spinner from "../../components/Spinner";
import EmptyState from "../../components/EmptyState";
import Button from "../../components/Button";

import type {
  ContentTypesACLRules,
  ContentType,
  Stores,
  Tenant,
} from "../../types";
import type { TFunction } from "react-i18next";

type OwnProps = {
  tenantId?: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId?: $PropertyType<ContentType, "id">,
  match: RouterMatch,
  history: RouterHistory,
  location: RouterLocation,
  t: TFunction,
};

type StoresProps = {
  entryId: $PropertyType<EntryModel, "id">,
  createEntry: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    formData: FormData
  ) => Promise<EntryModel | null>,
  updateEntry: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<EntryModel, "id">,
    formData: FormData
  ) => Promise<EntryModel | null>,
  contentTypesACLRules: ContentTypesACLRules,
  isFetching: boolean,
  contentTypes: ContentType[],
  fetchContentTypes: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Promise<any>,
  fetchEntry: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<EntryModel, "id">
  ) => Promise<any>,
  getTenant: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Tenant | null,
  getContentType: (
    contentTypeId: $PropertyType<ContentType, "id">
  ) => ContentType | null,
  entry: EntryModel | null,
  setApplicationConfiguration: (configuration: Object) => any,
  isAppOnline: boolean,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const entryId = props.match.params.entryId;

  return {
    entryId,
    createEntry: stores.entry.createEntry,
    updateEntry: stores.entry.updateEntry,
    contentTypesACLRules: stores.contentType.aclRules,
    isFetching: stores.contentType.isLoading || stores.entry.isFetching,
    contentTypes: stores.contentType.contentTypes,
    fetchContentTypes: stores.contentType.fetchContentTypes,
    fetchEntry: stores.entry.fetchEntry,
    getTenant: stores.tenant.getTenant,
    getContentType: stores.contentType.getContentType,
    entry: stores.entry.entries.find((entry) => entry.id === entryId) || null,
    setApplicationConfiguration: stores.app.setApplicationConfiguration,
    isAppOnline: stores.pwa.isOnline,
  };
};

const EntryDetailScreen = (props: Props) => {
  const {
    tenantId,
    contentTypeId,
    entryId,
    createEntry,
    updateEntry,
    contentTypesACLRules,
    isFetching,
    location,
    history,
    contentTypes,
    fetchContentTypes,
    fetchEntry,
    getTenant,
    getContentType,
    entry,
    setApplicationConfiguration,
    isAppOnline,
    t,
  } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contentType, setContentType] = useState();
  const [tenant, setTenant] = useState(null);
  const [formData, setFormData] = useState([]);
  const [canRead, setCanRead] = useState(false);
  const [canCreate, setCanCreate] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const initialize = async () => {
    if (tenantId) {
      const fetchs = [fetchContentTypes(tenantId)];

      if (entryId !== "new" && tenantId && contentTypeId && entryId) {
        fetchs.push(fetchEntry(tenantId, Number(contentTypeId), entryId));
      }

      await Promise.all(fetchs);

      const tenantData = getTenant(tenantId);
      if (tenantData) {
        setTenant(tenantData);
        setApplicationConfiguration(tenantData.configuration);
      }
      setContentType(getContentType(Number(contentTypeId)));
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  useEffect(() => {
    if (contentType) {
      setFormData(entry != null ? entry.data : contentType.formData);

      const canRead = aclUtils.checkACL(
        contentTypesACLRules,
        tenant ? tenant.role : null,
        String(contentType.id),
        "entries:read"
      );
      setCanRead(canRead);

      const canCreate = aclUtils.checkACL(
        contentTypesACLRules,
        tenant ? tenant.role : null,
        String(contentType.id),
        "entries:create"
      );
      setCanCreate(canCreate);

      const canUpdate = aclUtils.checkACL(
        contentTypesACLRules,
        tenant ? tenant.role : null,
        String(contentType.id),
        "entries:update"
      );
      setCanUpdate(canUpdate);
    }
  }, [
    entry,
    contentType,
    tenant,
    setFormData,
    setCanCreate,
    setCanRead,
    setCanUpdate,
    contentTypesACLRules,
  ]);

  const handleGoToEntryId = useCallback(
    (
      tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
      contentTypeId: $PropertyType<ContentType, "id">,
      entryId: $PropertyType<EntryModel, "id">
    ) => {
      navigationUtils.goTo(
        { location, history },
        navigationUtils.routes.entry.find(tenantId, contentTypeId, entryId)
      );
    },
    [location, history]
  );

  let submitEntryForm = null;
  const bindSubmitForm = (submitForm) => (submitEntryForm = submitForm);

  const canSubmit = entry == null ? canCreate : canUpdate;
  const handleSubmit = async (e) => {
    if (submitEntryForm) {
      submitEntryForm(e);
    }
  };

  const handleStoreData = useCallback(
    async (values: Object) => {
      let newEntry = null;
      setIsSubmitting(true);

      if (entry != null) {
        const initialFormState = toJS(formData);
        const editedData = difference(values, initialFormState);

        newEntry = await updateEntry(
          tenantId || "",
          contentType.id,
          entry.id,
          editedData
        );
      } else {
        newEntry = await createEntry(tenantId || "", contentType.id, values);
      }
      newEntry != null &&
        handleGoToEntryId(tenantId || "", contentType.id, newEntry.id);
      setIsSubmitting(false);
    },
    [
      entry,
      setIsSubmitting,
      contentType,
      tenantId,
      formData,
      updateEntry,
      createEntry,
      handleGoToEntryId,
    ]
  );

  return (
    <Observer
      render={() => (
        <ScreenContainer>
          <Sidebar />
          <Container>
            {isFetching && <Spinner size={150} />}
            {!isFetching && (
              <EntryHeader.Header bordered>
                <StyledEntryDetail.TitleWrapper>
                  <BackButton
                    size={"large"}
                    location={location}
                    history={history}
                  />
                  <EntryHeader.Title
                    icon={contentType && contentType.uiSchema["ui:icon"]}
                    title={
                      contentType && contentType.schema
                        ? contentType.schema.title
                        : ""
                    }
                  />
                  <StyledEntryDetail.SubTitle>
                    {t(entry ? "edit" : "new")}
                  </StyledEntryDetail.SubTitle>
                </StyledEntryDetail.TitleWrapper>
                <EntryHeader.ActionContainer>
                  {canSubmit && (
                    <Button
                      icon={"check"}
                      onClick={handleSubmit}
                      loading={isSubmitting}
                    >
                      {t("save")}
                    </Button>
                  )}
                </EntryHeader.ActionContainer>
              </EntryHeader.Header>
            )}
            {!isFetching && contentType && (
              <EntryContent>
                {(entry != null && !canRead) ||
                (entry == null && !canCreate) ? (
                  <EmptyState reason={"unauthorized"} />
                ) : (
                  <Form
                    schema={contentType.schema}
                    uiSchema={contentType.uiSchema}
                    formData={entry != null ? entry.data : contentType.formData}
                    stagesMetadata={entry != null ? entry.stagesMetadata : []}
                    onSubmit={handleStoreData}
                    hasSubmitButton={false}
                    submitLabel={strings.screens.entryDetail.save}
                    shouldPreventLeavingDirtyForm={true}
                    contentTypes={contentTypes}
                    contentTypesACL={contentTypesACLRules}
                    isOnline={isAppOnline}
                    tenantId={tenantId}
                    contentTypeId={contentTypeId}
                    entryId={entryId && entryId !== "new" ? entryId : null}
                    bindSubmitForm={bindSubmitForm}
                  />
                )}
              </EntryContent>
            )}
          </Container>
        </ScreenContainer>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation(["common"])(EntryDetailScreen)
);
