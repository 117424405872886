/** @flow */
import React from "react";

import StyledDashboardItem from "./styled";

type DashboardItemProps = {
  title: string,
  children: any,
};

const DashboardItem = ({ children, title }: DashboardItemProps) => (
  <StyledDashboardItem.Container>
    <StyledDashboardItem.Header>{title}</StyledDashboardItem.Header>
    <div>{children}</div>
  </StyledDashboardItem.Container>
);

export default DashboardItem;
