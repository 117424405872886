/* @flow */
import React, { useCallback, useState } from "react";
import { inject, Observer } from "mobx-react";
import type {
  Location as RouterLocation,
  Match as RouterMatch,
  RouterHistory,
} from "react-router-dom";
import { withTranslation } from "react-i18next";

import StyledEntryDetail from "../EntryDetail/styled";

import Spinner from "../../components/Spinner";
import Container from "../../components/Container";
import EntryHeader from "../../components/EntryHeader";
import BackButton from "../../components/BackButton";
import Button from "../../components/Button";
import Form from "../../components/Form";
import strings from "../../config/strings";
import mockProfileForm from "../../mocks/forms/profile";
import ScreenContainer from "../../components/ScreenContainer";
import Sidebar from "../Sidebar";

import type { Stores, User } from "../../types";
import type { TFunction } from "react-i18next";

type OwnProps = {
  history: RouterHistory,
  location: RouterLocation,
  match: RouterMatch,
  t: TFunction,
};

type StoresProps = {
  isLoading: boolean,
  user: ?User,
  updateUserProfile: (user: User) => any,
  isAppOnline: boolean,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: OwnProps): StoresProps => {
  return {
    isLoading: stores.auth.isUpdatingUserProfile,
    user: stores.auth.user,
    updateUserProfile: stores.auth.updateUserProfile,
    isAppOnline: stores.pwa.isOnline,
  };
};

const ProfileScreen = ({
  location,
  history,
  isLoading,
  isAppOnline,
  user,
  updateUserProfile,
  t,
}: Props) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  let submitProfileForm = null;
  const bindSubmitForm = (submitForm) => (submitProfileForm = submitForm);

  const handleStoreData = useCallback(
    async (data) => {
      setIsSubmitting(true);
      await updateUserProfile(data.user);
      setIsSubmitting(false);
    },
    [updateUserProfile]
  );

  const handleSubmit = useCallback(
    (event) => {
      if (submitProfileForm) {
        submitProfileForm(event);
      }
    },
    [submitProfileForm]
  );

  return (
    <Observer
      render={() => (
        <ScreenContainer>
          <Sidebar />
          <Container>
            <EntryHeader.Header>
              <StyledEntryDetail.TitleWrapper>
                <BackButton
                  size={"large"}
                  location={location}
                  history={history}
                />
                <EntryHeader.Title
                  icon={"user"}
                  title={mockProfileForm.schema.title}
                />
              </StyledEntryDetail.TitleWrapper>
              <EntryHeader.ActionContainer>
                <Button
                  icon={"check"}
                  onClick={handleSubmit}
                  loading={isSubmitting}
                >
                  {t("save")}
                </Button>
              </EntryHeader.ActionContainer>
            </EntryHeader.Header>

            {isLoading && <Spinner size={150} />}

            <Form
              schema={mockProfileForm.schema}
              uiSchema={mockProfileForm.uiSchema}
              formData={{ user }}
              onSubmit={handleStoreData}
              submitLabel={strings.screens.entryDetail.save}
              shouldPreventLeavingDirtyForm={true}
              isOnline={isAppOnline}
              bindSubmitForm={bindSubmitForm}
              hasSubmitButton={false}
            />
          </Container>
        </ScreenContainer>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation(["common"])(ProfileScreen)
);
