/* @flow */
import React from "react";
import { withTranslation } from "react-i18next";

import Tips from "../Tips";
import StyledLayout from "./styled";
import constants from "../../config/constants";

import assetTip1 from "../../assets/img/tips/tip1.svg";
import assetTip2 from "../../assets/img/tips/tip2.svg";
import assetTip3 from "../../assets/img/tips/tip3.svg";
import assetTip4 from "../../assets/img/tips/tip4.svg";
import assetTip5 from "../../assets/img/tips/tip5.svg";
import assetTip6 from "../../assets/img/tips/tip6.svg";
import assetTip7 from "../../assets/img/tips/tip7.svg";

import type { TFunction } from "react-i18next";

type Props = {
  title: string,
  children: any,
  t: TFunction,
};

const AuthLayout = ({ title, children, t }: Props) => {
  const tips = [
    {
      imageURL: assetTip1,
      description: t("tip1"),
    },
    {
      imageURL: assetTip2,
      description: t("tip2"),
    },
    {
      imageURL: assetTip3,
      description: t("tip3"),
    },
    {
      imageURL: assetTip4,
      description: t("tip4"),
    },
    {
      imageURL: assetTip5,
      description: t("tip5"),
    },
    {
      imageURL: assetTip6,
      description: t("tip6"),
    },
    {
      imageURL: assetTip7,
      description: t("tip7"),
    },
  ];

  return (
    <StyledLayout.Container>
      <Tips title={t("title")} tips={tips} />
      <StyledLayout.AuthFormContainer>
        <StyledLayout.ContentWrapper>
          <StyledLayout.LogoWrapper>
            <img
              src={constants.DEFAULT_ICON_URL}
              alt={constants.APPLICATION_NAME}
            />
          </StyledLayout.LogoWrapper>
          <StyledLayout.AuthFormTitle>{title}</StyledLayout.AuthFormTitle>
          {children}
        </StyledLayout.ContentWrapper>
      </StyledLayout.AuthFormContainer>
    </StyledLayout.Container>
  );
};

export default withTranslation(["tips"])(AuthLayout);
