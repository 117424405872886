/** @flow */
import React from "react";

import StyledContainer from "./styled";

type ContainerProps = {
  children?: any,
};

const ScreenContainer = ({ children }: ContainerProps) => (
  <StyledContainer>{children}</StyledContainer>
);
export default ScreenContainer;
