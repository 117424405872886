/* @flow */
import styled from "styled-components";

const Label = styled.label`
  position: relative;
  box-sizing: border-box;
  color: ${(props) => props.theme.global.colors.text.light};
  line-height: 1.5em;
  font-weight: bold;
`;

export default {
  Label,
};
