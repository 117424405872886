/* @flow */
import React from "react";
import ReactDOM from "react-dom";
import "normalize.css";
import "./index.css";
import Root from "./screens/Root";

const root = document.getElementById("root");

if (root !== null) {
  ReactDOM.render(<Root />, root);
} else {
  console.error("ReactDOM root node not found");
}
