/* @flow */
// import DJV from "djv";
import dayjs from "dayjs";
import {
  isArray,
  isEmpty,
  isEqual,
  isNaN,
  isNil,
  isPlainObject,
  isString,
} from "lodash-es";
import { floatSafeRemainder } from "../number";
import { getSchemaType } from "./index";

// export const validateSchema = (values: Object, schema: Object) => {
//   const djv = new DJV();
//   djv.addSchema("anonymous", schema);
//   const err = djv.validate("anonymous", values);
//   return err;
// };

const isEmptyValue = (value) =>
  isNil(value) ||
  (isString(value) && value.trim().length === 0) ||
  (isPlainObject(value) && isEmpty(value)) ||
  (isArray(value) && value.length === 0);

export const validate = (
  value: any,
  name: string,
  schema: Object,
  uiSchema: Object,
  parentSchema: Object
) => {
  const type = getSchemaType(schema);
  if (
    isArray(parentSchema.required) &&
    parentSchema.required.includes(name) &&
    isEmptyValue(value)
  ) {
    return "È richiesto un valore";
  }

  if (type === "number") {
    const parsedValue = parseFloat(value);
    if (!isNil(value) && isNaN(parsedValue)) {
      return "Il valore deve essere un numero";
    }
    if (!isNil(value) && !isNil(schema.maximum)) {
      const isValid = schema.exclusiveMaximum
        ? parsedValue < schema.maximum
        : parsedValue <= schema.maximum;
      if (!isValid) {
        return schema.exclusiveMaximum
          ? `Il valore deve essere inferiore a ${schema.maximum}`
          : `Il valore non deve essere superiore a ${schema.maximum}`;
      }
    }
    if (!isNil(value) && !isNil(schema.minimum)) {
      const isValid = schema.exclusiveMinimum
        ? parsedValue > schema.minimum
        : parsedValue >= schema.minimum;
      if (!isValid) {
        return schema.exclusiveMinimum
          ? `Il valore deve essere superiore a ${schema.minimum}`
          : `Il valore non deve essere inferiore a ${schema.minimum}`;
      }
    }
    if (
      !isNil(value) &&
      !isNil(schema.multipleOf) &&
      floatSafeRemainder(parsedValue, schema.multipleOf) !== 0
    ) {
      return `Il valore deve essere multiplo di ${schema.multipleOf}`;
    }
    // exclusiveMaximum / exclusiveMinimum
  } else if (type === "string") {
    const parsedValue = String(value);
    if (
      !isNil(value) &&
      !isNil(schema.maxLength) &&
      parsedValue.length > schema.maxLength
    ) {
      return `Il valore non deve superare i ${schema.maxLength} caratteri`;
    }
    if (
      !isNil(value) &&
      !isNil(schema.minLength) &&
      parsedValue.length < schema.minLength
    ) {
      return `Il valore deve contenere almeno ${schema.minLength} caratteri`;
    }
    if (
      !isNil(value) &&
      !isNil(schema.pattern) &&
      !new RegExp(schema.pattern).test(parsedValue)
    ) {
      return `Il valore deve seguire il formato RegExp ${schema.pattern}`;
    }
    // format
    // formatMaximum / formatMinimum and formatExclusiveMaximum / formatExclusiveMinimum (proposed)
    if (!isNil(value) && !isNil(schema.format)) {
      switch (schema.format) {
        case "date": {
          const isValidDate = dayjs(value, "YYYY-MM-DD").isValid();
          if (!isValidDate) {
            return "Il valore deve essere una data di tipo YYYY-MM-DD";
          }
          break;
        }
        case "time": {
          const isValidDate = dayjs(value, "HH:MM:SS").isValid();
          if (!isValidDate) {
            return "Il valore deve essere un'ora di tipo YYYY-MM-DD";
          }
          break;
        }
        case "date-time": {
          const isValidDate = dayjs(value, "YYYY-MM-DDTHH:MM:SS").isValid();
          if (!isValidDate) {
            return "Il valore deve essere una data di tipo YYYY-MM-DDTHH:MM:SS";
          }
          break;
        }
        case "uri": {
          // TODO
          break;
        }
        case "uri-reference": {
          // TODO
          break;
        }
        case "uri-template": {
          // TODO
          break;
        }
        case "url": {
          // TODO
          break;
        }
        case "email": {
          // TODO
          break;
        }
        case "ipv4": {
          // TODO
          break;
        }
        case "ipv6": {
          // TODO
          break;
        }
        case "regex": {
          // TODO
          break;
        }
        case "uuid": {
          // TODO
          break;
        }
        case "json-pointer": {
          // TODO
          break;
        }
        case "relative-json-pointer": {
          // TODO
          break;
        }
        default:
      }
    }
  } else if (type === "array") {
    // maxItems/minItems
    // uniqueItems
    // items
    // additionalItems
    // contains
  } else if (type === "object") {
    // maxProperties/minProperties
    // required
    // properties
    // patternProperties
    // additionalProperties
    // dependencies
    // propertyNames (added in draft-06)
    // patternRequired (proposed)
  } else if (type === "integer") {
    const parsedValue = parseFloat(value);
    if (
      !isNil(value) &&
      (isNaN(parsedValue) || !Number.isInteger(parsedValue))
    ) {
      return "Il valore deve essere un numero intero";
    }
    if (!isNil(value) && !isNil(schema.maximum)) {
      const isValid = schema.exclusiveMaximum
        ? parsedValue < schema.maximum
        : parsedValue <= schema.maximum;
      if (!isValid) {
        return schema.exclusiveMaximum
          ? `Il valore deve essere inferiore a ${schema.maximum}`
          : `Il valore non deve essere superiore a ${schema.maximum}`;
      }
    }
    if (!isNil(value) && !isNil(schema.minimum)) {
      const isValid = schema.exclusiveMinimum
        ? parsedValue > schema.minimum
        : parsedValue >= schema.minimum;
      if (!isValid) {
        return schema.exclusiveMinimum
          ? `Il valore deve essere superiore a ${schema.minimum}`
          : `Il valore non deve essere inferiore a ${schema.minimum}`;
      }
    }
    if (
      !isNil(value) &&
      !isNil(schema.multipleOf) &&
      floatSafeRemainder(parsedValue, schema.multipleOf) !== 0
    ) {
      return `Il valore deve essere multiplo di ${schema.multipleOf}`;
    }
  } else if (type === "single_file") {
  } else if (type === "content_relation") {
  } else if (type === "boolean") {
    if (!isNil(value) && typeof value !== "boolean") {
      return `Il valore inserito è invalido, deve essere vero/sì o falso/no`;
    }
  }

  // Keywords for all types
  if (
    !isNil(value) &&
    !isNil(schema.enum) &&
    isArray(schema.enum) &&
    !schema.enum.includes(value)
  ) {
    return `Il valore ${value} deve essere uno tra ${schema.enum}`;
  }
  if (!isNil(value) && !isNil(schema.const) && !isEqual(value, schema.const)) {
    return `${
      isArray(schema.const) ? "I valori devono" : "Il valore deve"
    } essere ${schema.const}`;
  }
  if (schema.readOnly != null) {
    //
  }
  if (schema.writeOnly != null) {
    //
  }

  // Compound keywords
  if (schema.not != null) {
    //
  }
  if (schema.oneOf != null) {
    //
  }
  if (schema.anyOf != null) {
    //
  }
  if (schema.allOf != null) {
    //
  }
  if (schema.if != null) {
    // then, else
  }
};
