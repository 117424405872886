/** @flow */
const schema = {
  title: "Profilo",
  description: "",
  type: "object",
  properties: {
    user: {
      title: "",
      type: "object",
      properties: {
        name: {
          title: "Nome",
          type: "string",
        },
        surname: {
          title: "Cognome",
          type: "string",
        },
        email: {
          title: "Email",
          type: "string",
        },
      },
    },
  },
};

const uiSchema = {};

export default {
  schema,
  uiSchema,
};
