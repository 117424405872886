/** @flow */
import { action, observable } from "mobx";

import LogStore from "./Log";
import EntryStore from "./Entry";
import * as serviceWorker from "../serviceWorker";

export default class PWA {
  @observable
  isUpdateAvailable: boolean = false;
  @observable
  isOnline: boolean = navigator.onLine;
  logStore: LogStore;

  constructor(logStore: LogStore) {
    this.logStore = logStore;
  }

  @action
  initialize = async (): Promise<void> => {
    serviceWorker.register({
      onUpdate: () => {
        this.isUpdateAvailable = true;
      },
    });
    window.addEventListener("online", this.setOnlineStatus);
    window.addEventListener("offline", this.setOfflineStatus);
  };

  @action
  destroy = async (): Promise<void> => {
    window.removeEventListener("online", this.setOnlineStatus);
    window.removeEventListener("offline", this.setOfflineStatus);
  };

  @action
  setOfflineStatus = () => {
    this.isOnline = false;
  };

  @action
  setOnlineStatus = () => {
    this.isOnline = true;
  };

  @action
  reload = () => window.location.reload();
}
