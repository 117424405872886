/* @flow */
import React from "react";

import StyledSequence from "./styled";

type SequenceType = "horizontal" | "vertical";

type Props = {
  children?: any,
  type?: SequenceType,
};

const Sequence = ({ children, type = "horizontal" }: Props) => {
  return <StyledSequence type={type}>{children}</StyledSequence>;
};

export default Sequence;
