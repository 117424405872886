/* @flow */
import styled, { css } from "styled-components";

const cellStyles = css`
  text-align: left;
  vertical-align: middle;
  padding: ${(props) => props.theme.global.padding.xsmall}
    ${(props) => props.theme.global.padding.xsmall};
  min-width: 5em;
  ${(props) => props.onClick && "cursor: pointer;"}
`;

const Th = styled.th`
  ${cellStyles}
  color: ${(props) => props.theme.global.colors.table.title};
  text-transform: uppercase;
  font-size: 0.833em;
`;

const Td = styled.td`
  ${cellStyles};
`;

const Row = styled.tr``;

const tableStyleList = css`
  ${Row} {
    border-bottom: 1px solid
      ${(props) => props.theme.global.colors.border.light};
  }
`;

const tableStyleGrid = css`
  ${Td} {
    background-color: ${(props) => props.theme.global.colors.white};
    border: 1px solid ${(props) => props.theme.global.colors.border.light};

    & input {
      border: 0px;
      background-color: transparent;
      padding: ${(props) => props.theme.global.padding.xsmall} 0em 0em 0em;
    }
    & label {
      font-weight: lighter;
      font-size: 0.8;
    }
  }
`;

const readOnlyTableStyles = css`
  cursor: not-allowed;
`;

const Table = styled.table`
  box-sizing: border-box;
  border-collapse: collapse;
  margin-left: -1px;
  width: 100%;
  ${(props) => (props.type === "list" ? tableStyleList : tableStyleGrid)}
  ${(props) => props.readOnly && readOnlyTableStyles}
`;

const Caption = styled.caption`
  color: ${(props) => props.theme.global.colors.text.light};
  font-weight: 500;
  line-height: 2rem;
  font-size: 0.875rem;
  text-transform: uppercase;
`;

export default {
  Table,
  Caption,
  Row,
  Th,
  Td,
};
