/* @flow */
import styled, { keyframes } from "styled-components";

const syncAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-360deg);
  }`;

const Container = styled.div`
  width: 40px;
  height: 40px;
  position: fixed;
  right: 1.5em;
  bottom: 1em;
  border: 1px solid;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.dark};
  border-radius: ${(props) => props.theme.global.border.size.medium};
  box-shadow: ${(props) => props.theme.global.elevation.small};
  background: ${(props) => props.theme.global.colors.brand};
`;

const IconWrapper = styled.div`
  box-sizing: border-box;
  animation: fadein 0.5s, ${syncAnimation} infinite 1s linear;

  svg {
    fill: ${(props) => props.theme.global.colors.text.dark};
  }
`;

export default {
  Container,
  IconWrapper,
};
