/* @flow */
import React, { useCallback } from "react";
import _ from "lodash-es";

import Input from "../../components/Input";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";

type Props = {|
  id: string,
  name?: string,
  onBlur?: (event: any) => any,
  onChange?: (event: any) => any,
  value?: string,
  type?: string,
  defaultValue?: string,
  autoFocus?: boolean,
  readOnly?: boolean,
  isOnline?: boolean,
  placeholder?: string,
|};

const TextWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  type = "text",
  defaultValue = "",
  autoFocus = false,
  readOnly = false,
  isOnline,
  placeholder = "",
}: Props) => {
  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;
      onChange && onChange(event);
    },
    [onChange, readOnly]
  );
  if (readOnly) {
    return <div>{value || defaultValue || <WidgetReadOnlyEmptyValue />}</div>;
  }
  return (
    <div>
      <Input
        type={type}
        id={id}
        name={name}
        onBlur={onBlur}
        onChange={handleChange}
        value={value != null ? value : defaultValue}
        autoFocus={autoFocus}
        readOnly={readOnly || !isOnline}
        placeholder={placeholder}
      />
    </div>
  );
};

TextWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.type = uiSchema["ui:widget"] === "password" ? "password" : "text";
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.readOnly = _.isNil(uiSchema["ui:readOnly"])
    ? schema.readOnly
    : uiSchema["ui:readOnly"];
  props.placeholder = uiSchema["ui:placeholder"];
  return props;
};

export default TextWidget;
