/* @flow */
import type { StorageKey } from "../types";

const setItem = (name: StorageKey, data: Object): void => {
  try {
    const jsonData = JSON.stringify(data);
    localStorage.setItem(name, jsonData);
  } catch (err) {
    throw new Error(`Storage setItem error: ${err.message}`);
  }
};

const getItem = (name: StorageKey): Object => {
  try {
    const jsonData = localStorage.getItem(name);
    if (jsonData) return JSON.parse(jsonData);
  } catch (err) {
    throw new Error(`Storage getItem error: ${err.message}`);
  }
  return undefined;
};

const removeItem = (name: StorageKey): void => {
  try {
    const jsonData = localStorage.removeItem(name);
  } catch (err) {
    throw new Error(`Storage removeItem error: ${err.message}`);
  }
};

export default {
  setItem,
  getItem,
  removeItem,
};
