/** @flow @format */
import { action, observable, computed } from "mobx";

import LogStore from "./Log";
import AppStore from "./App";
import ContentTypeStore from "./ContentType";
import EntryStore from "./Entry";
import backendService from "../services/backend";

import type { Tenant } from "../types";

export default class TenantStore {
  @observable
  isInitialized: boolean = false;
  @observable
  tenants: Tenant[] = [];
  @observable
  isFetching: boolean = false;
  logStore: LogStore;
  appStore: AppStore;
  contentTypeStore: ContentTypeStore;
  entryStore: EntryStore;

  constructor(
    logStore: LogStore,
    appStore: AppStore,
    contentTypeStore: ContentTypeStore,
    entryStore: EntryStore
  ) {
    this.logStore = logStore;
    this.appStore = appStore;
    this.contentTypeStore = contentTypeStore;
    this.entryStore = entryStore;
  }

  @action
  initialize = async (): Promise<void> => {
    try {
    } catch (err) {
      this.logStore.warning(`Errore nella inizializzazione TenantStore`, err);
    }
    this.isInitialized = true;
  };

  @action
  resetStores = () => {
    this.appStore.destroy();
    this.contentTypeStore.destroy();
    this.entryStore.destroy();
  };

  @action
  destroy = async (): Promise<void> => {
    this.tenants = [];
    this.isFetching = false;
  };

  @action
  fetchTenants = async (): Promise<void> => {
    this.isFetching = true;
    let tenants = [];
    try {
      tenants = await backendService.getTenants();
    } catch (err) {
      this.logStore.warning(
        `Errore nel caricamento tipologia dati, assicurarsi di essere connessi a internet`,
        err
      );
    }
    this.tenants = tenants;
    this.isFetching = false;
  };

  @action
  getTenant = (
    tenantNameOrId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ): Tenant | null => {
    return (
      this.tenants.find(
        (tenant) =>
          tenant.id === tenantNameOrId || tenant.name === tenantNameOrId
      ) || null
    );
  };

  @computed
  get isLoading(): boolean {
    return this.isFetching;
  }

  @computed
  get isMultiProducts(): boolean {
    return this.tenants.length > 1;
  }
}
