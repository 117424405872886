/* @flow */
import React, { useCallback } from "react";
import { inject, Observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import Form from "../../components/Form";
import AuthLayout from "../../components/AuthLayout";
import Link from "../../components/Link";

import navigationUtils from "../../utils/navigation";

import type {
  Location as RouterLocation,
  RouterHistory,
} from "react-router-dom";

import type {
  ContextRouter,
  FormData,
  JSONSchema,
  Stores,
  UISchema,
  Translate,
} from "../../types";

type OwnProps = {
  t: Translate,
  history: RouterHistory,
  location: RouterLocation,
};
type StoresProps = {|
  resetPassword: (
    username: string,
    password: string,
    token: string
  ) => Promise<any>,
|};
type Props = OwnProps & StoresProps & ContextRouter;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  resetPassword: stores.auth.resetPassword,
});

const ResetPasswordScreen = ({
  location,
  history,
  resetPassword,
  t,
}: Props) => {
  const URLParams = new URLSearchParams(location.search);

  const handleSubmit = useCallback(
    async (values: FormData) => {
      const token = URLParams.get("token") || "";

      const result = await resetPassword(values.email, values.password, token);

      if (result) {
        navigationUtils.goTo({ history, location }, "/");
      }
    },
    [URLParams, resetPassword, history, location]
  );

  const schema: JSONSchema = {
    title: t("newPassword"),
    type: "object",
    required: ["email", "password"],
    properties: {
      email: {
        title: t("email"),
        type: "string",
      },
      password: {
        title: t("newPassword"),
        type: "string",
      },
    },
  };
  const uiSchema: UISchema = {
    email: {
      "ui:autofocus": true,
      "ui:placeholder": t("enterEmail"),
    },
    password: {
      "ui:widget": "password",
    },
  };

  return (
    <Observer
      render={() => (
        <AuthLayout title={schema.title}>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={{ email: URLParams.get("email") || "" }}
            onSubmit={handleSubmit}
            submitLabel={t("confirm")}
          />
          <Link
            href={navigationUtils.routes.auth.login()}
            text={t("backToLogin")}
          />
        </AuthLayout>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation("auth")(ResetPasswordScreen)
);
