/* @flow */
import React, { useCallback, useState } from "react";

import { withRouter, Link } from "react-router-dom";
import { inject, Observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import Button from "../../components/Button";
import Icon from "../../components/Icon";
import navigationUtils from "../../utils/navigation";
import Avatar from "../../components/Avatar";
import Dropdown from "../../components/Dropdown";
import DropdownToggle from "../../components/DropdownToggle";
import DropdownMenu from "../../components/DropdownMenu";
import DropdownItem from "../../components/DropdownItem";

import StyledSidebar from "./styled";
import type { ContentType, Stores, Tenant, User } from "../../types";
import type {
  Location as RouterLocation,
  RouterHistory,
  Match as RouterMatch,
} from "react-router-dom";
import type { TFunction } from "react-i18next";

type StoresProps = {
  getTenant: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Tenant | null,
  appTitle?: string,
  appLogo?: string,
  contentTypes: ContentType[],
  logout: () => Promise<void>,
  currentUser?: User | null,
  isMultiProducts: boolean,
};

type Props = StoresProps & {
  history: RouterHistory,
  location: RouterLocation,
  match: RouterMatch,
  t: TFunction,
};

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => ({
  getTenant: stores.tenant.getTenant,
  appTitle: stores.app.title,
  appLogo: stores.app.logo,
  contentTypes: stores.contentType.contentTypes,
  logout: stores.auth.logout,
  currentUser: stores.auth.user,
  isMultiProducts: stores.tenant.isMultiProducts,
});

const Sidebar = (props: Props) => {
  const {
    getTenant,
    appLogo,
    appTitle,
    contentTypes,
    logout,
    location,
    history,
    currentUser,
    t,
    isMultiProducts,
  } = props;

  const selectedTenantId = navigationUtils.fromRoutes.tenantId(
    location.pathname
  );

  let currentUsername =
    currentUser && currentUser.name
      ? `${currentUser.name} ${currentUser.surname || ""}`
      : currentUser && currentUser.email
      ? currentUser.email
      : "";

  let currentUserRole = "";
  if (selectedTenantId) {
    const tenant = getTenant(selectedTenantId);
    currentUserRole = tenant ? tenant.role.label : "";
  }

  const selectContentTypeId = navigationUtils.fromRoutes.contentTypeId(
    location.pathname
  );

  const [isMenuOpen, setMenuOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const handleSelectContentTypeId = useCallback(
    (contentTypeId: $PropertyType<ContentType, "id"> | null) => {
      setMenuOpen(false);
      selectedTenantId != null && contentTypeId != null
        ? navigationUtils.goTo(
            { location, history },
            navigationUtils.routes.entry.list(selectedTenantId, contentTypeId)
          )
        : navigationUtils.goTo(
            { location, history },
            navigationUtils.routes.tenant.list()
          );
    },
    [location, history, setMenuOpen, selectedTenantId]
  );

  const handleGoToProfile = useCallback(() => {
    navigationUtils.goTo(
      { location, history },
      navigationUtils.routes.users.profile()
    );
  }, [location, history]);

  const handleToggleMenu = useCallback(() => {
    setMenuOpen((isMenuOpen) => !isMenuOpen);
  }, [setMenuOpen]);

  const handleLogoutClick = useCallback(() => {
    setMenuOpen(false);
    logout();
  }, [logout, setMenuOpen]);

  const handleCollapseSidebar = useCallback(() => {
    setIsCollapsed(!isCollapsed);
  }, [isCollapsed, setIsCollapsed]);

  return (
    <Observer
      render={() => (
        <React.Fragment>
          <StyledSidebar.MobileHeader>
            <StyledSidebar.NavToggler>
              <Button
                model={"secondary"}
                icon={isMenuOpen ? "close" : "menu"}
                onClick={handleToggleMenu}
              />
            </StyledSidebar.NavToggler>
            <StyledSidebar.NavLogo to={"/"}>
              <img src={appLogo} alt={appTitle} />
            </StyledSidebar.NavLogo>
          </StyledSidebar.MobileHeader>
          <StyledSidebar.Container
            isOpen={isMenuOpen}
            isCollapsed={isCollapsed}
          >
            <StyledSidebar.BrandContainer>
              <Link
                to={
                  selectedTenantId
                    ? navigationUtils.routes.contentType.list(selectedTenantId)
                    : navigationUtils.routes.tenant.list()
                }
              >
                <StyledSidebar.Brand>
                  <img src={appLogo} alt={appTitle} />
                </StyledSidebar.Brand>
              </Link>
              <h5>{appTitle}</h5>
            </StyledSidebar.BrandContainer>
            <StyledSidebar.CollapseSidebarButton
              onClick={handleCollapseSidebar}
            >
              <Icon
                type={isCollapsed ? "angleRight" : "angleLeft"}
                size={"xsmall"}
              />
            </StyledSidebar.CollapseSidebarButton>
            <StyledSidebar.ScrollableNavWrapper>
              {selectedTenantId &&
                contentTypes.map((contentType) => (
                  <StyledSidebar.NavItem
                    className={
                      String(selectContentTypeId) === String(contentType.id)
                        ? "active"
                        : ""
                    }
                    key={contentType.id}
                    onClick={() => handleSelectContentTypeId(contentType.id)}
                  >
                    <StyledSidebar.NavItemIcon>
                      <Icon type={contentType.uiSchema["ui:icon"]} />
                    </StyledSidebar.NavItemIcon>
                    <StyledSidebar.NavItemText>
                      {contentType.schema.title}
                    </StyledSidebar.NavItemText>
                  </StyledSidebar.NavItem>
                ))}
            </StyledSidebar.ScrollableNavWrapper>
            <StyledSidebar.NavWrapper>
              {isMultiProducts && (
                <StyledSidebar.NavItem
                  className={!selectedTenantId ? "active" : ""}
                  onClick={() => handleSelectContentTypeId(null)}
                >
                  <StyledSidebar.NavItemIcon>
                    <Icon type={"apps"} />
                  </StyledSidebar.NavItemIcon>
                  <StyledSidebar.NavItemText>
                    {t("common:tenants")}
                  </StyledSidebar.NavItemText>
                </StyledSidebar.NavItem>
              )}

              <Dropdown drop={"right"} alignRight>
                <DropdownToggle
                  variant={"success"}
                  id={"sidebar-user-dropdown"}
                >
                  <StyledSidebar.NavItem>
                    <StyledSidebar.NavItemIcon>
                      <Avatar user={currentUsername.split(" ")} />
                    </StyledSidebar.NavItemIcon>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <StyledSidebar.NavItemText>
                        {currentUsername}
                      </StyledSidebar.NavItemText>
                      <StyledSidebar.NavItemText
                        style={{ fontSize: "0.75rem", marginTop: "3px" }}
                      >
                        {currentUserRole}
                      </StyledSidebar.NavItemText>
                    </div>
                  </StyledSidebar.NavItem>
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={handleGoToProfile}>
                    {t("common:profile")}
                  </DropdownItem>
                  <DropdownItem onClick={handleLogoutClick}>
                    {t("logout")}
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>

              {/* Mobile additional items */}

              <StyledSidebar.MobileNavItem>
                <StyledSidebar.NavItemIcon>
                  <Avatar user={currentUsername.split(" ")} />
                </StyledSidebar.NavItemIcon>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <span>{currentUsername}</span>
                  <span style={{ fontSize: "0.75rem", marginTop: "3px" }}>
                    {currentUserRole}
                  </span>
                </div>
              </StyledSidebar.MobileNavItem>
              <StyledSidebar.MobileNavItem onClick={handleGoToProfile}>
                <StyledSidebar.NavItemIcon>
                  <Icon type={"user"} />
                </StyledSidebar.NavItemIcon>
                {t("common:profile")}
              </StyledSidebar.MobileNavItem>
              <StyledSidebar.MobileNavItem onClick={handleLogoutClick}>
                <StyledSidebar.NavItemIcon>
                  <Icon type={"logout"} />
                </StyledSidebar.NavItemIcon>
                {t("logout")}
              </StyledSidebar.MobileNavItem>
            </StyledSidebar.NavWrapper>
          </StyledSidebar.Container>
        </React.Fragment>
      )}
    />
  );
};

export default withRouter(
  inject(mapStoresToProps)(withTranslation(["auth", "common"])(Sidebar))
);
