/* @flow */
import styled, { css } from "styled-components";

const readOnlyStyle = css`
  background: rgba(0, 0, 0, 0.025);
  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.global.colors.border.light};
  }
  cursor: not-allowed;
`;

const StyledTextarea = styled.textarea`
  position: relative;
  box-sizing: border-box;
  -webkit-appearance: none;
  background: transparent;
  width: 100%;
  padding: ${(props) => props.theme.global.padding.xsmall};
  transition: border-color
    ${(props) => props.theme.global.animation.fade.duration} ease;
  border: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.normal};
  border-radius: ${(props) => props.theme.global.border.radius.small};
  &:hover {
    border-color: ${(props) => props.theme.global.colors.brand};
  }
  &:focus {
    outline: none;
    border-color: ${(props) => props.theme.global.colors.brand};
  }
  &::-moz-focus-inner {
    border: none;
  }
  &::-moz-focus-outer {
    border: none;
  }

  ${(props) => props.readOnly && readOnlyStyle}
`;

export default StyledTextarea;
