import styled, { keyframes } from "styled-components";

const fadeInAnimation = keyframes`
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 1%;
    opacity: 1;
  }
`;

const Container = styled.div`
  visibility: ${(props) => (props.isVisible ? "visible" : "hidden")};
  min-width: 250px;
  background-color: ${(props) => props.theme.global.colors.black};
  color: ${(props) => props.theme.global.colors.white};
  text-align: center;
  padding: ${(props) => props.theme.global.padding.small};
  border-radius: ${(props) => props.theme.global.border.radius.medium};
  font-size: 0.75em;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 1%;
  transform: translateX(-50%);
  font-size: 0.875em;
  animation: ${fadeInAnimation} 0.5s;
  cursor: pointer;
`;

export default {
  Container,
};
