/* @flow */
import React from "react";
import { MdNavigateBefore as NavigateBeforeIcon } from "react-icons/md";
import type {
  Location as RouterLocation,
  RouterHistory,
} from "react-router-dom";

import Button from "../Button";

import navigationUtils from "../../utils/navigation";

type Props = {
  history: RouterHistory,
  location: RouterLocation,
};

const BackButton = (props: Props) => {
  const { location, history, ...othersProps } = props;

  const handleGoBack = () => {
    const tenantId = navigationUtils.fromRoutes.tenantId(location.pathname);
    const contentTypeId = navigationUtils.fromRoutes.contentTypeId(
      location.pathname
    );

    if (tenantId && contentTypeId) {
      navigationUtils.goTo(
        { location, history },
        navigationUtils.routes.entry.list(tenantId, contentTypeId)
      );
    } else {
      navigationUtils.goBack({ location, history });
    }
  };

  return (
    <Button model={"secondary"} onClick={handleGoBack} {...othersProps}>
      <NavigateBeforeIcon />
    </Button>
  );
};

export default BackButton;
