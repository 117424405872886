/* @flow */
import type {
  Location as RouterLocation,
  RouterHistory,
} from "react-router-dom";

const goTo = (
  { location, history }: { location: RouterLocation, history: RouterHistory },
  destination: string
) => {
  location.pathname !== destination && history.push(destination);
};

const goBack = ({
  location,
  history,
}: {
  location: RouterLocation,
  history: RouterHistory,
}) => {
  history.goBack();
};

const openInNewTab = (
  { location, history }: { location: RouterLocation, history: RouterHistory },
  destination: string
) => {
  const newTab = window.open(destination, "_blank");
  newTab.focus();
};

const reloadCurrentPage = () => {
  window && window.location.reload();
};

const routesUtils = {
  newId: "new",
};
const fromRoutes = {
  tenantId: (pathname: string): string | null => {
    const tenantRegex = /\/tenants\/([^\/?#]+)/i;
    const found = pathname.match(tenantRegex);
    return (found && found[1]) || null;
  },
  contentTypeId: (pathname: string): string | null => {
    const contentTypeRegex = /\/content_types\/([^\/?#]+)/i;
    const found = pathname.match(contentTypeRegex);
    return (found && found[1]) || null;
  },
  entryId: (pathname: string): string | null => {
    const entryRegex = /\/entries\/([^\/?#]+)/i;
    const found = pathname.match(entryRegex);
    return (found && found[1]) || null;
  },
};
const routes = {
  auth: {
    login: () => "/auth/login",
    signup: () => "/auth/signup",
    recover: () => "/auth/change-password",
    lostPassword: () => "/auth/lost-password",
  },
  contentType: {
    list: (tenantId: string | number) => `/tenants/${tenantId}/content_types`,
  },
  entry: {
    list: (tenantId: string | number, contentTypeId: string | number) =>
      `/tenants/${tenantId}/content_types/${contentTypeId}/entries`,
    find: (
      tenantId: string | number,
      contentTypeId: string | number,
      entryId: string | number
    ) =>
      `/tenants/${tenantId}/content_types/${contentTypeId}/entries/${entryId}`,
    create: (tenantId: string | number, contentTypeId: string | number) =>
      `/tenants/${tenantId}/content_types/${contentTypeId}/entries/${routesUtils.newId}`,
  },
  notFound: {
    main: () => "/404",
  },
  tenant: {
    list: () => `/tenants`,
  },
  users: {
    invite: (tenantId: string | number) => `/tenants/${tenantId}/users/invite`,
    profile: () => `/profile`,
  },
};

export default {
  reloadCurrentPage,
  goTo,
  goBack,
  openInNewTab,
  routes,
  fromRoutes,
  routesUtils,
};
