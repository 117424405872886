/* @flow */

import type {
  ACLAction,
  ContentTypesACLRules,
  ACLSubject,
  UserRole,
} from "../types";

const DEFAULT_ACL = {
  policy: "deny",
  policyExceptions: [],
};

const checkACL = (
  rules: ContentTypesACLRules,
  userRole: ?UserRole,
  subject: ACLSubject,
  action: ACLAction,
  data?: any
): boolean => {
  if (!userRole) {
    return false;
  }

  const permissions =
    rules[subject] && rules[subject][userRole.name]
      ? rules[subject][userRole.name]
      : DEFAULT_ACL;
  if (!permissions) {
    return false;
  }

  const policy = permissions.policy;
  const exceptions = permissions.policyExceptions;

  const actionVerb = action.split(":")[0];
  if (
    exceptions &&
    (exceptions.includes(action) || exceptions.includes(`${actionVerb}:*`))
  ) {
    return policy === "deny" ? true : false;
  }

  return policy === "deny" ? false : true;
};

export default {
  checkACL,
};
