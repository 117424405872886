/** @flow */
import React from "react";

import StyledCard from "./styled";
import Icon from "../Icon";

import type { Icon as TypeIcon } from "../../types/Icon";

type CardProps = {
  icon?: TypeIcon,
  title: string,
  logo?: string,
};

const Card = ({ icon = "", logo, title, ...othersProps }: CardProps) => (
  <StyledCard.CardContainer {...othersProps}>
    {(icon || logo) && (
      <StyledCard.IconWrapper>
        {logo && <img src={logo} alt={title} />}
        {icon && !logo && <Icon type={icon} size={"xlarge"} />}
      </StyledCard.IconWrapper>
    )}
    <StyledCard.Title>{title}</StyledCard.Title>
  </StyledCard.CardContainer>
);
export default Card;
