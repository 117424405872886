/* @flow */
import styled from "styled-components";

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1001;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  display: block;
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  overflow-x: hidden;
  overflow-y: auto;
`;

const StyledModalDialog = styled.div`
  position: relative;
  width: auto;
  pointer-events: none;
  max-width: 80%;
  margin: 1.75rem auto;

  font-family: ${(props) => props.theme.global.typography.paragraph.fontFamily};
  font-size: ${(props) => props.theme.global.typography.paragraph.fontSize};
`;

const StyledModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: ${(props) => props.theme.global.colors.white};
  background-clip: padding-box;
  border: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.normal};
  border-radius: 0.3rem;
  outline: 0;
  z-index: 1002;
`;

const StyledModalHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${(props) => props.theme.global.padding.small};
  padding-bottom: 0;
`;

const StyledModalTitle = styled.h5`
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h5.fontWeight};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
`;

const StyledModalBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: ${(props) => props.theme.global.padding.small};
`;

const StyledCloseButton = styled.button`
  float: right;
  color: ${(props) => props.theme.global.colors.black};
  text-shadow: 0 1px 0 ${(props) => props.theme.global.colors.white};
  opacity: 0.5;
  ackground-color: transparent;
  border: 0;
  appearance: none;
  padding: 0;
  cursor: pointer;
`;

export default {
  Modal: StyledModal,
  Overlay: StyledOverlay,
  ModalDialog: StyledModalDialog,
  ModalContent: StyledModalContent,
  ModalHeader: StyledModalHeader,
  ModalTitle: StyledModalTitle,
  CloseButton: StyledCloseButton,
  ModalBody: StyledModalBody,
};
