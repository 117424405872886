/** @flow */
/* https://github.com/react-bootstrap/react-bootstrap/blob/master/src/SelectableContext.js */
import React from "react";

const SelectableContext = React.createContext<any, () => any>(/* () => {} */);

export const makeEventKey = (eventKey: any, href: any) => {
  if (eventKey != null) return String(eventKey);
  return href || null;
};

export default SelectableContext;
