/* @flow */
import React from "react";

import StyledFieldset from "./styled";

type Props = {
  children?: any,
  title?: string,
  readOnly?: boolean,
  direction?: "horizontal" | "vertical",
};

const Fieldset = ({
  children,
  title,
  readOnly = false,
  direction = "vertical",
}: Props) => {
  return (
    <StyledFieldset.Container readOnly={readOnly}>
      {title && <StyledFieldset.Heading>{title}</StyledFieldset.Heading>}
      <StyledFieldset.Content direction={direction}>
        {children}
      </StyledFieldset.Content>
    </StyledFieldset.Container>
  );
};

export default Fieldset;
