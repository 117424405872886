/** @flow @format */
import { action, observable } from "mobx";

import keys from "../config/keys";
import { uuidv4 } from "../utils";

import type { Timestamp } from "../types";

type LogEventSeverity = "info" | "success" | "warning" | "error";

type LogEvent = {
  id: string,
  severity: LogEventSeverity,
  message: string,
  payload: any,
  createdAt: Timestamp,
};

export default class LogStore {
  @observable
  isInitialized: boolean = false;
  @observable
  logs: LogEvent[] = [];

  @action
  initialize = async () => {
    this.isInitialized = true;
  };

  @action
  destroy = async (): Promise<void> => {
    this.logs = [];
  };

  @action
  log = async (
    severity: $PropertyType<LogEvent, "severity">,
    message: $PropertyType<LogEvent, "message">,
    payload: $PropertyType<LogEvent, "payload">
  ): Promise<void> => {
    if (keys.LOGS_RETENTION_MILLISECONDS <= 0) {
      return;
    }
    const createdLog = {
      id: uuidv4(),
      severity,
      message,
      payload,
      createdAt: Date.now(),
    };
    const nextLogs = this.logs
      .filter(
        (log) => Date.now() - log.createdAt < keys.LOGS_RETENTION_MILLISECONDS
      )
      .concat([createdLog]);
    this.logs = nextLogs;
  };

  @action
  info = async (
    message: $PropertyType<LogEvent, "message">,
    payload: $PropertyType<LogEvent, "payload">
  ): Promise<void> => {
    await this.log("info", message, payload);
  };
  @action
  success = async (
    message: $PropertyType<LogEvent, "message">,
    payload: $PropertyType<LogEvent, "payload">
  ): Promise<void> => {
    await this.log("success", message, payload);
  };
  @action
  warning = async (
    message: $PropertyType<LogEvent, "message">,
    payload: $PropertyType<LogEvent, "payload">
  ): Promise<void> => {
    await this.log("warning", message, payload);
  };
  @action
  error = async (
    message: $PropertyType<LogEvent, "message">,
    payload: $PropertyType<LogEvent, "payload">
  ): Promise<void> => {
    await this.log("error", message, payload);
  };
}
