/* @flow */
import keys from "../../config/keys";
import storageServices from "../storage";
import navigationUtils from "../../utils/navigation";
import { jsonToFormData } from "../../utils";
import backendUtils from "./utils";

import type {
  Tenant,
  ContentType,
  Entry,
  FormData,
  User,
  UserRole,
} from "../../types";

const callBackendEndpoint = async (
  method: string,
  endpoint: string,
  params: any = {}
) => {
  const prevSessionToken = await storageServices.getItem("sessionToken");
  let headers = {};
  if (prevSessionToken) {
    headers["Authorization"] = `Bearer ${prevSessionToken}`;
  }
  headers["Content-Type"] = "application/json";
  headers["Access-Control-Allow-Origin"] = "*";
  let parsedParams = JSON.stringify(params);

  if (["POST", "PUT", "PATCH"].includes(method)) {
    delete headers["Content-Type"];
    parsedParams = jsonToFormData(params);
  }

  const url = `${keys.BACKEND_API_URL}${endpoint}`;
  const response = await fetch(url, {
    method: method,
    headers: headers,
    body: method === "GET" || method === "HEAD" ? undefined : parsedParams,
  });
  let responseBody;
  let responseText;
  try {
    responseBody = await response.clone().json();
  } catch (err) {
    responseText = await response.clone().text();
  }

  // TODO: remove this when backend will support JSON responses in all APIs
  if (method === "DELETE") {
    responseText = null;
    responseBody = {};
  }

  if (
    !response.ok ||
    responseText != null ||
    responseBody == null ||
    responseBody.error
  ) {
    if (
      response.status === 401 &&
      window.location.pathname !== navigationUtils.routes.auth.login()
    ) {
      storageServices.removeItem("sessionToken");
      storageServices.removeItem("currentUser");
      navigationUtils.reloadCurrentPage();
    }
    const error =
      (responseBody && responseBody.error) || { message: responseText } || {};
    throw new Error(error.message || response.statusText || "Connection error");
  }
  const sessionToken = response.headers.get(`Authorization`);
  if (sessionToken) {
    storageServices.setItem(
      "sessionToken",
      sessionToken.replace(/(^Bearer\s*)?/i, "")
    );
  }
  return responseBody;
};

const getCustomersForm = async () => {
  return await callBackendEndpoint("GET", "customers");
};

/*
 * AUTH
 */

const getCurrentUser = async (): Promise<User | null> => {
  const sessionToken = await storageServices.getItem("sessionToken");
  const currentUser = await storageServices.getItem("currentUser");
  if (!sessionToken || !currentUser) return null;
  const result = await callBackendEndpoint("GET", `users/${currentUser.id}`);
  return backendUtils.toUser(result);
};

const updateUserProfile = async (user: User) => {
  const params = {
    name: user.name,
    surname: user.surname,
    email: user.email,
  };

  await callBackendEndpoint("POST", `users/${user.id}`, params);
};

const login = async (email: $PropertyType<User, "email">, password: string) => {
  // delete old token
  storageServices.removeItem("sessionToken");
  const body = {
    user: {
      email,
      password,
    },
  };
  const result = await callBackendEndpoint("POST", "login", body);
  const user = backendUtils.toUser(result);
  await storageServices.setItem("currentUser", user);
  return user;
};

const logout = async () => {
  storageServices.removeItem("sessionToken");
  storageServices.removeItem("currentUser");
};

const forgotPassword = async (email: $PropertyType<User, "email">) => {
  const params = {
    email,
  };

  await callBackendEndpoint("POST", `password/forgot`, params);
};

const resetPassword = async (
  email: $PropertyType<User, "email">,
  password: string,
  token: string
) => {
  const params = {
    email,
    password,
    token,
  };

  await callBackendEndpoint("POST", `password/reset`, params);
};

const getTenants = async (): Promise<Tenant[]> => {
  const result = await callBackendEndpoint("GET", "tenants");
  const tenants = result.map(backendUtils.toTenant);
  return tenants;
};

const getContentTypes = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
): Promise<ContentType[]> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenants/${tenantId}/content_types`
  );
  const contentTypes = result.map(backendUtils.toContentType);
  return contentTypes;
};

const getEntries = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">
): Promise<Entry[]> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenants/${tenantId}/content_types/${contentTypeId}/entries`
  );
  const entries = result.map(backendUtils.toEntry);
  return entries;
};

const getEntry = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  entryId: $PropertyType<Entry, "id">
): Promise<Entry> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenants/${tenantId}/content_types/${contentTypeId}/entries/${entryId}`
  );
  const entry = backendUtils.toEntry(result);
  return entry;
};

const createEntry = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  formData: FormData
): Promise<Entry> => {
  const params = {
    entry: {
      content_type_id: contentTypeId,
      properties: formData,
    },
  };
  const result = await callBackendEndpoint(
    "POST",
    `tenants/${tenantId}/content_types/${contentTypeId}/entries`,
    params
  );
  const createdEntry = backendUtils.toEntry(result);
  return createdEntry;
};

const updateEntry = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  entryId: $PropertyType<Entry, "id">,
  formData: FormData
): Promise<Entry> => {
  const params = {
    entry: {
      content_type_id: contentTypeId,
      properties: formData,
    },
  };
  const result = await callBackendEndpoint(
    "PUT",
    `tenants/${tenantId}/content_types/${contentTypeId}/entries/${entryId}`,
    params
  );
  const updatedEntry = backendUtils.toEntry(result);
  return updatedEntry;
};

const deleteEntry = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  entryId: $PropertyType<Entry, "id">
): Promise<void> => {
  await callBackendEndpoint(
    "DELETE",
    `tenants/${tenantId}/content_types/${contentTypeId}/entries/${entryId}`
  );
};

const getRoles = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
): Promise<UserRole[]> => {
  const result = await callBackendEndpoint("GET", `tenants/${tenantId}/roles`);
  const roles = result.map(backendUtils.toUserRole);
  return roles;
};

const inviteUser = async (
  email: $PropertyType<User, "email">,
  role: $PropertyType<UserRole, "name">,
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
): Promise<void> => {
  await callBackendEndpoint(
    "GET",
    `invite?email=${encodeURIComponent(email)}&role=${encodeURIComponent(
      role
    )}&tenant=${encodeURIComponent(tenantId.toString())}`
  );
};

const getUserRelatedEntry = async (
  userId: $PropertyType<User, "id">,
  contentTypeId: $PropertyType<ContentType, "id">
): Promise<Entry> => {
  const result = await callBackendEndpoint(
    "GET",
    `users/${userId}/related-entry/${contentTypeId}`
  );
  const entry = backendUtils.toEntry(result);
  return entry;
};

const getExportConfiguration = async (
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId:
    | $PropertyType<ContentType, "id">
    | $PropertyType<ContentType, "name">
): Promise<any> => {
  const result = await callBackendEndpoint(
    "GET",
    `tenants/${tenantId}/content_types/${contentTypeId}/exports`
  );
  return result;
};

export default {
  getCurrentUser,
  updateUserProfile,
  login,
  logout,
  forgotPassword,
  resetPassword,
  getTenants,
  getCustomersForm,
  getContentTypes,
  getEntries,
  getEntry,
  createEntry,
  updateEntry,
  deleteEntry,
  getRoles,
  inviteUser,
  getUserRelatedEntry,
  getExportConfiguration,
};
