/* @flow */
import styled from "styled-components";

const StyledFormWrapper = styled.div`
  display: flex;
  position: relative;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  & > * {
    margin-bottom: ${(props) => props.theme.global.padding.medium};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const StyledFormContent = styled.div`
  height: 100%;
  & > * {
    margin-bottom: ${(props) => props.theme.global.padding.medium};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export default {
  Wrapper: StyledFormWrapper,
  Content: StyledFormContent,
};
