/* @flow */
import React from "react";
import { isArray, times } from "lodash-es";

import StyledTable from "./styled";
import Container from "../../components/Container";
import Table from "../../components/Table";

import { chunkArray } from "../../utils";

type Cell = any;

type Props = {
  title?: string,
  cells: Cell[],
  renderCell: (cell: Cell) => any,
  getCellKey: (cell: Cell) => string,
  rowLength?: number,
  rowLabels?: string[],
  columnLabels?: string[],
  readOnly?: boolean,
  customStyle?: any,
};

const TableGrouping = ({
  title,
  cells,
  renderCell,
  getCellKey,
  rowLength,
  rowLabels = [],
  columnLabels = [],
  readOnly = false,
  customStyle = {},
}: Props) => {
  const cellsChunks = chunkArray(cells, rowLength || cells.length);
  const numRows = cellsChunks.length;
  const hasColumnNames =
    isArray(columnLabels) &&
    columnLabels.length >= (rowLength || cells.length) &&
    columnLabels.some((label) => String(label).trim() !== "");
  const hasRowNames =
    isArray(rowLabels) &&
    rowLabels.length >= numRows &&
    rowLabels.some((label) => String(label).trim() !== "");
  return (
    <div style={{ ...customStyle.container }}>
      <StyledTable.Label>{title}</StyledTable.Label>
      <Container>
        <Table.Table type={"grid"} readOnly={readOnly}>
          {hasColumnNames && (
            <Table.Row>
              {hasRowNames && (
                <Table.Cell heading={true} style={customStyle.columnHeader} />
              )}
              {times(rowLength, (index) => (
                <Table.Cell key={`${index}`} heading={true}>
                  {columnLabels[index]}
                </Table.Cell>
              ))}
            </Table.Row>
          )}
          {cellsChunks.map((cellsChunk, index) => (
            <Table.Row key={`${index}`}>
              {hasRowNames && (
                <Table.Cell heading={true} style={customStyle.rowHeader}>
                  {rowLabels[index]}
                </Table.Cell>
              )}
              {cellsChunk.map((cell) => (
                <Table.Cell
                  key={getCellKey(cell)}
                  style={{ ...customStyle.cell }}
                >
                  {renderCell(cell)}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Table>
      </Container>
    </div>
  );
};

export default TableGrouping;
