/* @flow */
import styled, { css } from "styled-components";

const cellStyles = css`
  text-align: left;
  vertical-align: middle;
  padding: ${(props) => props.theme.global.padding.small}
    ${(props) => props.theme.global.padding.xsmall};
  min-width: 5rem;
  ${(props) => props.onClick && "cursor: pointer;"}
`;

const stickyLeftCell = css`
  position: sticky !important;
  left: 0;
  top: 0;
  z-index: 1;
  background-color: white;
`;

const stickyRigthCell = css`
  position: sticky !important;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: white;
`;

const Th = styled.th`
  ${cellStyles}
  color: ${(props) => props.theme.global.colors.table.title};
  text-transform: uppercase;
  font-size: 0.833em;

  ${(props) => props.sticky === "left" && stickyLeftCell}
  ${(props) => props.sticky === "right" && stickyRigthCell}
`;

const Td = styled.td`
  ${cellStyles};

  ${(props) => props.sticky === "left" && stickyLeftCell}
  ${(props) => props.sticky === "right" && stickyRigthCell}
`;

const Row = styled.tr``;

const Table = styled.table`
  box-sizing: border-box;
  border-collapse: collapse;
  width: 100%;
  margin-bottom: 1rem;

  ${Row} {
    border-bottom: ${(props) =>
      `${props.theme.global.border.size.small} solid ${props.theme.global.colors.border.normal}`};
  }
`;

const Caption = styled.caption`
  color: ${(props) => props.theme.global.colors.text.light};
  font-weight: 500;
  line-height: 2rem;
  font-size: 0.875rem;
  text-transform: uppercase;
`;

const ScrollableContainer = styled.div`
  display: block;
  width: 100%;
  overflow-x: auto;
`;

export default {
  ScrollableContainer,
  Table,
  Caption,
  Row,
  Th,
  Td,
};
