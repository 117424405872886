/* @flow */
import React from "react";

import Tabs from "../../components/Tabs";

import type { StageStatus } from "../../types";

type Tab = any;

type TabState = StageStatus;

type Props = {
  tabs: Tab[],
  renderTab: (tab: Tab) => any,
  getTabKey: (tab: Tab) => string,
  getTabTitle: (tab: Tab) => string,
  getTabState: (tab: Tab) => ?TabState,
  readOnly?: boolean,
  orientation?: "horizontal" | "vertical",
};

const mapTabStateToIcon = (tabState: ?TabState) => {
  const tabStateToIcon = {
    empty: "circle",
    inProgress: "check",
    completed: "check",
    dirty: "pen",
    error: "alert",
  };
  if (!tabState) return tabStateToIcon["empty"];
  return tabStateToIcon[tabState] || tabStateToIcon["empty"];
};

const TabsGrouping = ({
  tabs,
  renderTab,
  getTabKey,
  getTabTitle,
  readOnly = false,
  orientation,
  getTabState,
}: Props) => {
  return (
    <Tabs
      readOnly={readOnly}
      orientation={orientation}
      tabs={tabs.map((tab) => ({
        id: getTabKey(tab),
        title: getTabTitle(tab),
        render: () => renderTab(tab),
        icon: mapTabStateToIcon(getTabState(tab)),
      }))}
    />
  );
};

export default TabsGrouping;
