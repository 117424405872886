/** @flow */
import React from "react";

import StyledAvatar from "./style";

type Props = {
  user: string | string[],
};

const Avatar = ({ user }: Props) => {
  const initials = Array.isArray(user)
    ? user.map((value) => value.substr(0, 1)).join("")
    : user.substr(1, 1);

  return (
    <StyledAvatar.Container>
      <StyledAvatar.Username>{initials}</StyledAvatar.Username>
    </StyledAvatar.Container>
  );
};

export default Avatar;
