/* @flow */
import React, { useState } from "react";
import { inject, Observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";

import navigationUtils from "../../utils/navigation";

import AuthLayout from "../../components/AuthLayout";
import Form from "../../components/Form";
import Link from "../../components/Link";

import type {
  ContextRouter,
  FormData,
  JSONSchema,
  Stores,
  UISchema,
  Translate,
} from "../../types";

type OwnProps = {|
  t: Translate,
|};
type StoresProps = {|
  login: (username: string, password: string) => Promise<any>,
|};
type Props = OwnProps & StoresProps & ContextRouter;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  login: stores.auth.login,
});

const AuthScreen = (props: Props) => {
  const { t } = props;

  const [shouldRedirectToReferrer, setShouldRedirectToReferrer] = useState(
    false
  );
  const { from } = { from: { pathname: "/" } };
  if (shouldRedirectToReferrer) return <Redirect to={from} />;

  const schema: JSONSchema = {
    title: t("login"),
    type: "object",
    required: ["username", "password"],
    properties: {
      username: {
        title: t("username"),
        type: "string",
      },
      password: {
        title: t("password"),
        type: "string",
      },
    },
  };
  const uiSchema: UISchema = {
    username: {
      "ui:autofocus": true,
    },
    password: {
      "ui:widget": "password",
    },
  };

  return (
    <Observer
      render={() => (
        <AuthLayout title={schema.title}>
          <React.Fragment>
            <Form
              schema={schema}
              uiSchema={uiSchema}
              formData={{}}
              onSubmit={async (values: FormData) => {
                await props.login(values.username, values.password);
                setShouldRedirectToReferrer(true);
              }}
              submitLabel={t("submitLogin")}
            />
            <Link
              href={navigationUtils.routes.auth.lostPassword()}
              text={t("lostPassword")}
            />
          </React.Fragment>
        </AuthLayout>
      )}
    />
  );
};

export default inject(mapStoresToProps)(withTranslation(["auth"])(AuthScreen));
