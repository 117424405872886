/* @flow */
import styled from "styled-components";

const StyledSubTitle = styled.h5`
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h5.fontWeight};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
  color: ${(props) => props.theme.global.colors.secondaryText.light};
  margin-left: ${(props) => props.theme.global.padding.xsmall};
`;

const StyledTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export default {
  SubTitle: StyledSubTitle,
  TitleWrapper: StyledTitleWrapper,
};
