/** @flow @format */
import { action, computed, observable } from "mobx";
import { merge } from "lodash-es";

import backendService from "../services/backend";
import FontModel from "../models/Font";
import baseTheme from "../themes/base";
import LogStore from "./Log";
import constants from "../config/constants";

import type { Theme, TenantConfiguration } from "../types";

export default class AppStore {
  @observable isInitialized: boolean = false;
  @observable fonts: FontModel[] = [];
  @observable title: string = constants.APPLICATION_NAME;
  @observable logo: string = constants.DEFAULT_ICON_URL;
  @observable theme: Theme = baseTheme;
  logStore: LogStore;

  constructor(logStore: LogStore) {
    this.logStore = logStore;
  }

  @action
  initialize = async (): Promise<void> => {
    try {
    } catch (err) {
      this.logStore.warning(`Errore nella inizializzazione AppStore`, err);
    }
    this.isInitialized = true;
  };

  @action
  destroy = async (): Promise<void> => {
    this.fonts = [];
    this.theme = baseTheme;
    this.title = constants.APPLICATION_NAME;
    this.logo = constants.DEFAULT_ICON_URL;
  };

  @action
  setApplicationConfiguration = async (
    tenantConfiguration?: TenantConfiguration
  ) => {
    this.title = tenantConfiguration
      ? tenantConfiguration.strings.title
      : constants.APPLICATION_NAME;

    this.logo =
      tenantConfiguration && tenantConfiguration.logo
        ? tenantConfiguration.logo
        : constants.DEFAULT_ICON_URL;

    this.theme = tenantConfiguration
      ? merge({ ...baseTheme }, tenantConfiguration.theme || {})
      : baseTheme;

    if (tenantConfiguration) {
      const fonts = tenantConfiguration.fonts.map(
        (font) => new FontModel(font)
      );
      this.fonts = fonts;
      this.fonts.forEach((font) => font.load());
    }
  };

  @computed
  get areFontsLoaded() {
    return this.fonts.length > 0 && this.fonts.every((font) => font.isLoaded);
  }
}
