/* @flow */
import styled, { css } from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  max-width: 50%;
  position: relative;
`;

const ActionGroupContainer = styled.div`
  display:flex;
  flex:direction:row;
  align-items: center;

  svg {
      margin-left: ${(props) => props.theme.global.padding.xsmall};
      margin-right: ${(props) => props.theme.global.padding.xsmall};
  }
`;

const emptyField = css``;

const notEmptyField = css`
  color: inherit;
  font-weight: ${(props) => props.theme.global.typography.text.boldWeight};
  cursor: pointer;
`;

const FieldValue = styled.div`
  ${(props) => (props.isEmpty ? emptyField : notEmptyField)}
`;

const EmptyFieldPlaceholder = styled.div`
  color: ${(props) => props.theme.global.colors.secondaryText.light};
  font-weight: ${(props) => props.theme.global.typography.text.fontWeight};
`;

export default {
  Container,
  ActionGroupContainer,
  FieldValue,
  EmptyFieldPlaceholder,
};
