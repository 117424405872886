/* @flow */
import React, { useCallback, useState, useEffect } from "react";
import { inject, Observer } from "mobx-react";
import type {
  Location as RouterLocation,
  Match as RouterMatch,
  RouterHistory,
} from "react-router-dom";
import { get } from "lodash-es";

import navigationUtils from "../../utils/navigation";
import EntryModel from "../../models/Entry";
import Sidebar from "../Sidebar";
import ScreenContainer from "../../components/ScreenContainer";
import Container from "../../components/Container";
import EntryList from "../../components/EntryList";
import DashboardContainer from "../../components/DashboardContainer";

import { getContentTypeFilterableProperties } from "../../utils/jsonSchema";
import storageServices from "../../services/storage";

import type {
  ContentTypesACLRules,
  ContentType,
  Entry,
  Stores,
  ItemAction,
  Tenant,
  User,
} from "../../types";

type OwnProps = {
  history: RouterHistory,
  location: RouterLocation,
  match: RouterMatch,
};

type StoresProps = {
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  deleteEntry: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<Entry, "id">
  ) => Promise<void>,
  contentType: ContentType | null,
  contentTypesACLRules: ContentTypesACLRules,
  isLoading: boolean,
  isLoadingEntries: boolean,
  contentTypes: ContentType[],
  fetchTenants: () => Promise<any>,
  fetchContentTypes: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Promise<any>,
  fetchEntries: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">
  ) => Promise<any>,
  getTenant: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Tenant | null,
  setApplicationConfiguration: (configuration: Object) => any,
  entries: EntryModel[],
  user: ?User,
  isAppOnline: boolean,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: OwnProps): StoresProps => {
  const tenantId = props.match.params.tenantId || null;
  const contentTypeId = props.match.params.contentTypeId || null;
  const contentTypes = stores.contentType.contentTypes;
  const contentType =
    stores.contentType.contentTypes.find(
      (c) => String(c.id) === contentTypeId
    ) || null;
  return {
    tenantId,
    contentTypeId,
    deleteEntry: stores.entry.deleteEntry,
    contentTypes,
    contentType,
    contentTypesACLRules: stores.contentType.aclRules,
    isLoading:
      stores.tenant.isLoading ||
      stores.contentType.isLoading ||
      stores.entry.isLoading,
    isLoadingEntries: stores.entry.isLoading,
    fetchTenants: stores.tenant.fetchTenants,
    fetchContentTypes: stores.contentType.fetchContentTypes,
    fetchEntries: stores.entry.fetchEntries,
    getTenant: stores.tenant.getTenant,
    entries: contentTypeId
      ? stores.entry.entries.filter(
          (entry) => entry.contentTypeId === Number(contentTypeId)
        )
      : [],
    setApplicationConfiguration: stores.app.setApplicationConfiguration,
    user: stores.auth.user,
    isAppOnline: stores.pwa.isOnline,
  };
};

const ContentTypeDetailScreen = (props: Props) => {
  const {
    tenantId,
    contentTypeId,
    deleteEntry,
    contentType,
    contentTypesACLRules,
    isLoading,
    history,
    location,
    contentTypes,
    fetchContentTypes,
    fetchEntries,
    getTenant,
    entries,
    setApplicationConfiguration,
    user,
    isAppOnline,
  } = props;
  const [tenant, setTenant] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  const initialize = async () => {
    if (tenantId) {
      const fetchs = [fetchContentTypes(tenantId)];

      if (contentTypeId) {
        fetchs.push(fetchEntries(tenantId, contentTypeId));
      }

      await Promise.all(fetchs);

      const tenantData = getTenant(tenantId);
      if (tenantData) {
        setTenant(tenantData);
        setApplicationConfiguration(tenantData.configuration);
      }

      setAuthToken(await storageServices.getItem("sessionToken"));
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const handleAddClick = useCallback(() => {
    const customLink = get(
      contentType,
      "uiSchema['ui:links']['entry']['create']"
    );
    if (customLink) {
      navigationUtils.goTo({ history, location }, customLink);
    } else {
      navigationUtils.goTo(
        { history, location },
        navigationUtils.routes.entry.create(tenantId, contentTypeId)
      );
    }
  }, [history, location, contentType, contentTypeId, tenantId]);

  const handleEditClick = useCallback(
    (entryId) => {
      navigationUtils.goTo(
        { history, location },
        navigationUtils.routes.entry.find(tenantId, contentTypeId, `${entryId}`)
      );
    },
    [history, location, contentTypeId, tenantId]
  );

  const handleDeleteClick = useCallback(
    (entryId) => {
      if (
        window.confirm(
          `Verrà cancellata la entry ${entryId} di content type ${contentTypeId}. Vuoi procedere?`
        )
      ) {
        deleteEntry(tenantId, Number(contentTypeId), entryId);
      }
    },
    [deleteEntry, contentTypeId, tenantId]
  );

  const handleDocumentClick = useCallback(
    async (entryId) => {
      const entry = entries.find((entry) => entry.id === entryId);
      if (entry) {
        const entryData = getContentTypeFilterableProperties(
          get(contentType, "schema", {}),
          get(contentType, "uiSchema", {}),
          entry
        );

        await entry.export(entryData);
      }
    },
    [entries, contentType]
  );

  const entryActionButtons: ItemAction[] = [
    {
      type: "edit",
      action: handleEditClick,
      aclAction: "entries:read",
    },
    {
      type: "print",
      action: handleDocumentClick,
      aclAction: "entries:print",
    },
    {
      type: "delete",
      action: handleDeleteClick,
      aclAction: "entries:delete",
      disabled: !isAppOnline,
    },
  ];

  /*if (isLoading) {
    return <Spinner size={150} />;
  }*/

  let detailsComponent = "";

  if (
    contentType &&
    contentType.uiSchema &&
    contentType.uiSchema["ui:module"] === "staza-bi"
  ) {
    detailsComponent = (
      <DashboardContainer contentType={contentType} authToken={authToken} />
    );
  } else {
    detailsComponent = (
      <EntryList
        contentTypeEntries={entries}
        contentType={contentType}
        contentTypesACLRules={contentTypesACLRules}
        entryActions={entryActionButtons}
        onAddButtonClick={handleAddClick}
        contentTypes={contentTypes}
        user={user}
        isAddButtonEnabled={isAppOnline}
        userRole={tenant ? tenant.role : null}
        isLoading={isLoading}
      />
    );
  }

  return (
    <Observer
      render={() => (
        <ScreenContainer>
          <Sidebar />
          <Container>{detailsComponent}</Container>
        </ScreenContainer>
      )}
    />
  );
};

export default inject(mapStoresToProps)(ContentTypeDetailScreen);
