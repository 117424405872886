/* @flow */
import React, { useState } from "react";
import { get } from "lodash-es";

import Can from "../Can";
import EmptyState from "../EmptyState";
import Container from "../Container";
import Sequence from "../Sequence";
import DataTable from "../DataTable";
import EntryHeader from "../EntryHeader";
import EntryContent from "../EntryContent";
import Button from "../Button";
import SearchBar from "../SearchBar";

import EntryModel from "../../models/Entry";
import strings from "../../config/strings";
import { prettifyDate, fuzzyMatch } from "../../utils";
import {
  getContentTypeFilterableProperties,
  getComponentsFromSchema,
} from "../../utils/jsonSchema";

import type {
  ContentTypesACLRules,
  ItemAction,
  Entry,
  FilterableProperty,
  JSONSchema,
  ContentType,
  User,
  UserRole,
} from "../../types";

type EntryListProps = {
  contentTypeEntries: EntryModel[],
  contentType: ContentType | null,
  contentTypesACLRules: ContentTypesACLRules,
  entryActions: ItemAction[],
  isAddButtonEnabled?: boolean,
  onAddButtonClick?: () => any,
  contentTypes: ContentType[],
  user: ?User,
  userRole: ?UserRole,
  isLoading?: boolean,
};

const renderReadOnlyField = (
  property: FilterableProperty,
  parentSchema: JSONSchema,
  contentTypes: ContentType[],
  contentTypesACL: ContentTypesACLRules
) => {
  const {
    widgetComponent: WidgetComponent,
    widgetComponentProps,
  } = getComponentsFromSchema(
    property.name,
    property.schema,
    property.uiSchema,
    parentSchema,
    contentTypes,
    contentTypesACL
  );
  if (WidgetComponent == null) return null;

  return (
    <WidgetComponent
      {...widgetComponentProps}
      id={property.name}
      name={property.name}
      value={property.data}
      readOnly={true}
    />
  );
};

const EntryList = ({
  contentTypeEntries,
  contentType,
  contentTypesACLRules,
  entryActions,
  isAddButtonEnabled = true,
  onAddButtonClick,
  contentTypes,
  user,
  userRole,
  isLoading = false,
}: EntryListProps) => {
  const [searchedText, setSearchedText] = useState("");

  let filterablePropertiesByEntry: {
    [$PropertyType<Entry, "id">]: FilterableProperty[],
  } = {};
  contentTypeEntries.forEach((entry) => {
    filterablePropertiesByEntry[entry.id] = getContentTypeFilterableProperties(
      get(contentType, "schema", {}),
      get(contentType, "uiSchema", {}),
      entry
    );
  });

  const filterableProperties: FilterableProperty[] = getContentTypeFilterableProperties(
    contentType != null ? contentType.schema : {},
    contentType != null ? contentType.uiSchema : {}
  );

  const filteredEntries = contentTypeEntries.filter(
    (entry) =>
      !searchedText ||
      filterablePropertiesByEntry[entry.id].some((property) =>
        fuzzyMatch(property.data, searchedText)
      )
  );

  const sortedEntries = filteredEntries;

  const baseColumns = [
    {
      Header: "Data creazione",
      accessor: "createdAt",
      Cell: ({ cell: { value } }) => prettifyDate(value),
    },
    {
      Header: "Data modifica",
      accessor: "updatedAt",
      Cell: ({ cell: { value } }) => prettifyDate(value),
    },
    {
      id: "edit",
      accessor: "",
      sticky: true,
      disableSortBy: true,
      Cell: ({ cell }) => (
        <Sequence>
          {entryActions.map((entryAction) =>
            entryAction.aclAction ? (
              <Can
                key={entryAction.type}
                userRole={userRole}
                perform={entryAction.aclAction}
                subject={contentType ? String(contentType.id) : ""}
                acl={contentTypesACLRules}
                yes={() => (
                  <Button
                    icon={entryAction.type}
                    model={"secondary"}
                    onClick={() => entryAction.action(cell.row.original.id)}
                    disabled={
                      entryAction.disabled ? entryAction.disabled : false
                    }
                  />
                )}
              />
            ) : (
              <Button
                icon={entryAction.type}
                model={"secondary"}
                key={entryAction.type}
                onClick={() => entryAction.action(cell.row.original.id)}
                disabled={entryAction.disabled ? entryAction.disabled : false}
              />
            )
          )}
        </Sequence>
      ),
    },
  ];

  const filtrableColumns = filterableProperties.map((col) => ({
    Header: col.title,
    accessor: col.name,
    Cell: ({ cell }) => {
      return renderReadOnlyField(
        filterablePropertiesByEntry[cell.row.original.id].find(
          (property) => property.name === cell.column.id
        ),
        get(contentType, "schema", {}),
        contentTypes,
        contentTypesACLRules
      );
    },
  }));

  const columns = [...filtrableColumns, ...baseColumns];

  const data = sortedEntries;

  return (
    <Container>
      <EntryHeader.Header>
        <EntryHeader.Title
          icon={contentType && contentType.uiSchema["ui:icon"]}
          title={
            contentType && contentType.schema ? contentType.schema.title : ""
          }
        />
        <EntryHeader.ActionContainer>
          <SearchBar
            placeholder={strings.screens.entryList.search}
            onChange={setSearchedText}
            value={searchedText}
          />
          {onAddButtonClick && (
            <Can
              perform={"entries:create"}
              userRole={userRole}
              subject={contentType ? String(contentType.id) : ""}
              acl={contentTypesACLRules}
              yes={() => (
                <Button
                  onClick={onAddButtonClick}
                  disabled={!isAddButtonEnabled}
                >
                  {"Nuovo"}
                </Button>
              )}
            />
          )}
        </EntryHeader.ActionContainer>
      </EntryHeader.Header>
      <EntryContent>
        {!isLoading && (
          <Can
            perform={"entries:list"}
            userRole={userRole}
            subject={contentType ? String(contentType.id) : ""}
            acl={contentTypesACLRules}
            yes={() =>
              sortedEntries.length === 0 ? (
                <EmptyState reason={"noData"} />
              ) : (
                <DataTable columns={columns} data={data} />
              )
            }
            no={() => <EmptyState reason={"unauthorized"} />}
          />
        )}
      </EntryContent>
    </Container>
  );
};
export default EntryList;
