/** @flow @format */
import { action, computed, observable } from "mobx";
import { cloneDeep, set } from "lodash-es";

// TODO: remove when exports data is fetched from the backend
//import mockStructure from "../mocks/exports/analisienergetiche/index";
import api from "../services/backend";
import { randomInteger } from "../utils";
import LogStore from "../stores/Log";
import { getChildPropertySchema } from "../utils/jsonSchema";
import exportService from "../services/export";

import type { Entry } from "../types";

export default class EntryModel {
  id: $PropertyType<Entry, "id">;

  @observable contentTypeId: $PropertyType<Entry, "contentTypeId">;
  @observable contentTypeName: $PropertyType<Entry, "contentTypeName">;
  @observable data: $PropertyType<Entry, "data">;
  @observable createdAt: $PropertyType<Entry, "createdAt">;
  @observable updatedAt: $PropertyType<Entry, "updatedAt">;
  @observable stagesMetadata: $PropertyType<Entry, "stagesMetadata">;
  @observable isExporting: boolean = false;
  @observable tenantId: $PropertyType<Entry, "tenantId">;
  logStore: LogStore | null;

  constructor(
    id: $PropertyType<Entry, "id"> = randomInteger(),
    logStore?: LogStore
  ) {
    this.id = id;
    this.logStore = logStore || null;
  }

  @action
  updateFromJson(entryJson: Entry) {
    this.contentTypeId = entryJson.contentTypeId;
    this.data = entryJson.data;
    this.createdAt = entryJson.createdAt;
    this.updatedAt = entryJson.updatedAt;
    this.stagesMetadata = entryJson.stagesMetadata;
    this.contentTypeName = entryJson.contentTypeName;
    this.tenantId = entryJson.tenantId;
  }

  // TODO: we should remove metadata when ContentType refs are implemented
  @action
  export = async (
    metadata: Array<Object>,
    useMock: boolean = false
  ): Promise<void> => {
    this.isExporting = true;

    // the exportService has a huge bundle size caused by pdfMake
    // TODO: NOT working anymore: You may need an appropriate loader to handle this file type.
    // const exportService = await import("../services/export");
    try {
      // useMock ? mockValues : cloneDeep(this.data);
      const data = cloneDeep(this.data);

      for (let field of metadata) {
        if (field.uiSchema["ui:widget"] === "field-relation" && field.data) {
          const entryData = await api.getEntry(
            field.schema.tenantId,
            field.schema.contentTypeId,
            field.data
          );

          set(data, field.name, entryData.data);
        }
      }
      /*
       exportService.default.exportEntry(
        JSON.parse(mockStructure[this.contentTypeName]),
        data
      );*/
      const result = await api.getExportConfiguration(
        this.tenantId,
        this.contentTypeId
      );

      exportService.exportEntry(result, data);

      this.logStore &&
        this.logStore.success("Dati esportati correttamente", null);
    } catch (err) {
      this.logStore && this.logStore.error(`Errore nell'export dei dati`, err);
    }
    this.isExporting = false;
  };

  @computed
  get asJson(): Entry {
    return {
      id: this.id,
      contentTypeId: this.contentTypeId,
      data: this.data,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      stagesMetadata: this.stagesMetadata,
      contentTypeName: this.contentTypeName,
      tenantId: this.tenantId,
    };
  }
}
