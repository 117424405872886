/* @flow */
import React, { useState } from "react";
import { inject, Observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import navigationUtils from "../../utils/navigation";
import { withTranslation } from "react-i18next";

import AuthLayout from "../../components/AuthLayout";
import Form from "../../components/Form";
import Link from "../../components/Link";

import type {
  ContextRouter,
  FormData,
  JSONSchema,
  Stores,
  UISchema,
  Translate,
} from "../../types";

type OwnProps = {|
  t: Translate,
|};
type StoresProps = {|
  forgotPassword: (email: string) => Promise<any>,
|};
type Props = OwnProps & StoresProps & ContextRouter;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  forgotPassword: stores.auth.forgotPassword,
});

const LostPasswordScreen = ({ location, forgotPassword, t }: Props) => {
  const [shouldRedirectToReferrer, setShouldRedirectToReferrer] = useState(
    false
  );
  const { from } = location.state || { from: { pathname: "/" } };
  if (shouldRedirectToReferrer) return <Redirect to={from} />;

  const schema: JSONSchema = {
    title: "Recupero password",
    type: "object",
    required: ["email"],
    properties: {
      email: {
        title: t("lostPasswordLabel"),
        type: "string",
      },
    },
  };
  const uiSchema: UISchema = {
    email: {
      "ui:autofocus": true,
      "ui:placeholder": t("enterEmail"),
    },
  };

  return (
    <Observer
      render={() => (
        <AuthLayout title={schema.title}>
          <Form
            schema={schema}
            uiSchema={uiSchema}
            formData={{}}
            onSubmit={async (values: FormData) => {
              await forgotPassword(values.email);
            }}
            submitLabel={t("sendRecoveryLink")}
          />
          <Link
            href={navigationUtils.routes.auth.login()}
            text={t("backToLogin")}
          />
        </AuthLayout>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation("auth")(LostPasswordScreen)
);
