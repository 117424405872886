/* @flow */
import styled from "styled-components";

const StyledLabel = styled.label`
  position: relative;
  display: inline-block;
  box-sizing: border-box;
  color: ${(props) => props.theme.global.colors.text.light};
  line-height: ${(props) => props.theme.global.typography.lineHeight.text};
  font-weight: ${(props) => props.theme.global.typography.label.fontWeight};
  margin-bottom: ${(props) => props.theme.global.padding.xsmall};
  opacity: 0.85;
  font-size: 0.95em;
`;

export default StyledLabel;
