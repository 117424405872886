/** @flow */
import styled from "styled-components";
import { transparentize } from "polished";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: ${(props) => props.theme.global.padding.xlarge};
  color: ${(props) => props.theme.global.colors.secondaryText.light};
  opacity: 0.5;
  svg {
    fill: ${(props) => props.theme.global.colors.secondaryText.light};
  }
`;

const IconWrapper = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    transparentize(0.85, props.theme.global.colors.secondaryText.light)};
  margin-bottom: ${(props) => props.theme.global.padding.medium};
`;

export default {
  Container,
  IconWrapper,
};
