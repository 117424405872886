/* @flow */
import React from "react";
import { useTable, useSortBy } from "react-table";

import StyledTable from "./styled";

type DataTableColumn = {
  Header?: string,
  accessor?: string,
  sticky?: boolean,
  Cell?: (any) => any,
};

type DataTableProps = {
  caption?: string,
  columns: DataTableColumn[],
  data: [],
};

const DataTable = ({ caption, columns, data }: DataTableProps) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  return (
    <StyledTable.ScrollableContainer>
      <StyledTable.Table {...getTableProps()}>
        {caption && <StyledTable.Caption>{caption}</StyledTable.Caption>}
        <thead>
          {headerGroups.map((headerGroup) => (
            <StyledTable.Row {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <StyledTable.Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  sticky={
                    column.sticky && index === 0
                      ? "left"
                      : column.sticky &&
                        index === headerGroup.headers.length - 1
                      ? "right"
                      : false
                  }
                >
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? " ↓" : " ↑") : ""}
                  </span>
                </StyledTable.Th>
              ))}
            </StyledTable.Row>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);
            return (
              <StyledTable.Row {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <StyledTable.Td
                      {...cell.getCellProps()}
                      sticky={
                        cell.column.sticky && index === 0
                          ? "left"
                          : cell.column.sticky && index === row.cells.length - 1
                          ? "right"
                          : false
                      }
                    >
                      {cell.render("Cell")}
                    </StyledTable.Td>
                  );
                })}
              </StyledTable.Row>
            );
          })}
        </tbody>
      </StyledTable.Table>
    </StyledTable.ScrollableContainer>
  );
};

export default DataTable;
