/* @flow */
import aclMocks from "../../mocks/acl";

import type {
  ACLRule,
  ACLRules,
  ContentType,
  Entry,
  User,
  UserRole,
  Tenant,
} from "../../types";

const toACLRule = (backendACL: Object): ACLRule => ({
  policy: backendACL.policy,
  policyExceptions: backendACL.policyExceptions,
});

const toACLRules = (backendACLs: Object): ACLRules => {
  const aclRules = {};

  Object.keys(backendACLs).forEach(
    (backendACL) => (aclRules[backendACL] = toACLRule(backendACLs[backendACL]))
  );

  return aclRules;
};

const toContentType = (backendContentType: Object): ContentType => ({
  id: backendContentType.id,
  name: backendContentType.name,
  schema: backendContentType.schema,
  uiSchema: backendContentType.uischema,
  formData: backendContentType.form_data || {},
  acls: toACLRules(backendContentType.action_acls),
});

const toEntry = (backendEntry: Object): Entry => ({
  id: backendEntry.id,
  contentTypeId: backendEntry.content_type_id,
  data: backendEntry.data,
  createdAt: new Date(backendEntry.created_at).getTime(),
  updatedAt: new Date(backendEntry.updated_at).getTime(),
  stagesMetadata: backendEntry.stage_status,
  contentTypeName: backendEntry.content_type_name,
  tenantId: backendEntry.tenant_id,
});

const toUserEntry = (contentTypeId: number) => {
  return (backendUsersEntry: Object) => ({
    id: backendUsersEntry.id,
    contentTypeId,
    data: backendUsersEntry.data,
    createdAt: new Date(backendUsersEntry.created_at).getTime(),
    updatedAt: new Date(backendUsersEntry.updated_at).getTime(),
  });
};

const toUserRole = (backendUserRole: Object): UserRole => {
  return {
    id: backendUserRole.id,
    name: backendUserRole.name,
    label: backendUserRole.label,
    createdAt: new Date(backendUserRole.created_at).getTime(),
    updatedAt: new Date(backendUserRole.updated_at).getTime(),
  };
};

const toUser = (backendUser: Object): User => ({
  id: backendUser.id,
  email: backendUser.email,
  name: backendUser.name,
  surname: backendUser.surname,
  createdAt: new Date(backendUser.created_at).getTime(),
  updatedAt: new Date(backendUser.updated_at).getTime(),
});

const toTenant = (backendTenant: Object): Tenant => ({
  id: backendTenant.id,
  name: backendTenant.name,
  label: backendTenant.label,
  configuration: backendTenant.configuration,
  role: toUserRole(backendTenant.role),
});

export default {
  toContentType,
  toEntry,
  toUserEntry,
  toUserRole,
  toUser,
  toTenant,
};
