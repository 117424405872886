/* @flow */
import React, { useCallback, useEffect, useState } from "react";
import { inject } from "mobx-react";
import { eq, isNil, isEmpty } from "lodash-es";

import StyledFieldRelation from "./styled";
import { useModal } from "../../components/Modal";
import Button from "../../components/Button";
import EntryList from "../../components/EntryList";
import Form from "../../components/Form";
import Spinner from "../../components/Spinner";
import { getChildPropertySchema } from "../../utils/jsonSchema";
import formikUtils from "../../utils/formik";
import strings from "../../config/strings";
import EntryModel from "../../models/Entry";

import backendService from "../../services/backend";

import type {
  ContentTypesACLRules,
  ContentType,
  EntryFilter,
  FilterOperator,
  Tenant,
  Stores,
  User,
} from "../../types";

type OwnProps = {|
  id: string,
  name?: string,
  onBlur?: (event: any) => any,
  onChange?: (event: any) => any,
  relatedTenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  relatedContentTypeId: $PropertyType<ContentType, "id">,
  value?: string,
  defaultValue?: string,
  readOnly?: boolean,
  contentTypes?: ContentType[],
  contentTypesACL: ContentTypesACLRules,
  interpolation: string[],
  filters: EntryFilter[],
  isOnline?: boolean,
  tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  contentTypeId: $PropertyType<ContentType, "id">,
  entryId: $PropertyType<EntryModel, "id">,
  defaultTarget?: string,
|};

const mapFilterOperatorToFunction: { [FilterOperator]: (...any) => any } = {
  "=": eq,
};

type StoresProps = {
  user: ?User,
  getTenant: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Tenant | null,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => ({
  user: stores.auth.user,
  getTenant: stores.tenant.getTenant,
});

const FieldRelationWidget = ({
  id,
  name,
  onBlur,
  onChange,
  relatedTenantId,
  relatedContentTypeId,
  value,
  readOnly = false,
  contentTypes = [],
  contentTypesACL,
  interpolation,
  filters,
  user,
  isOnline,
  tenantId,
  contentTypeId,
  entryId,
  getTenant,
  defaultTarget,
}: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [relatedEntries, setRelatedEntries] = useState([]);
  const [isFetchingSearch, setIsFetchingSearch] = useState(false);
  const { openModal, closeModal } = useModal();
  const relatedContentType = contentTypes.find(
    (contentType) => contentType.id === relatedContentTypeId
  );
  const [userRole, setUserRole] = useState(null);

  useEffect(() => {
    let isSubscribed = true;

    const tenant = getTenant(tenantId);
    if (tenant) {
      setUserRole(tenant.role);
    }

    let relatedEntryId = value;
    let initCurrentUser = false;

    if (!relatedEntryId && defaultTarget === "currentUser") {
      relatedEntryId = user ? user.id : 0;
      initCurrentUser = true;

      setIsFetching(true);
      backendService
        .getUserRelatedEntry(relatedEntryId, relatedContentTypeId)
        .then((response) => {
          if (isSubscribed) {
            setIsFetching(false);
            setSelectedEntry(response);

            initCurrentUser &&
              onChange &&
              onChange(
                formikUtils.generateEvent(
                  id,
                  name || id,
                  response.id,
                  "fieldRelation"
                )
              );
          }
        })
        .catch(() => {
          setIsFetching(false);
        });
    } else if (
      relatedEntryId &&
      typeof relatedEntryId !== "object" &&
      Number(relatedEntryId) !== 0
    ) {
      setIsFetching(true);
      backendService
        .getEntry(relatedTenantId, relatedContentTypeId, Number(relatedEntryId))
        .then((response) => {
          if (isSubscribed) {
            setIsFetching(false);
            setSelectedEntry(response);

            onChange &&
              onChange(
                formikUtils.generateEvent(
                  id,
                  name || id,
                  relatedEntryId,
                  "fieldRelation"
                )
              );
          }
        })
        .catch(() => {
          setIsFetching(false);
        });
    }
    return () => {
      isSubscribed = false;
    };
  }, [
    value,
    getTenant,
    relatedContentTypeId,
    relatedTenantId,
    tenantId,
    defaultTarget,
    id,
    name,
    onChange,
    user,
  ]);

  const handleOpenModal = useCallback(
    (Component) => {
      openModal({
        content: Component,
      });
    },
    [openModal]
  );
  const handleSearchSelection = useCallback(
    (entryId) => {
      // Create fake event handled by Formik
      onChange &&
        onChange(
          formikUtils.generateEvent(id, name || id, entryId, "fieldRelation")
        );

      closeModal();
    },
    [onChange, closeModal, id, name]
  );
  const handleCleanEntry = useCallback(() => {
    setSelectedEntry(null);
    onChange &&
      onChange(
        formikUtils.generateEvent(id, name || id, null, "fieldRelation")
      );
  }, [onChange, id, name]);
  const handleSearch = useCallback(async () => {
    setIsFetchingSearch(true);
    let entries = relatedEntries;
    if (entries.length === 0) {
      let remoteEntries = await backendService.getEntries(
        relatedTenantId,
        relatedContentTypeId
      );
      remoteEntries
        .filter((entry) => {
          if (filters.length > 0) {
            return filters.reduce((result, filter) => {
              return (
                result &&
                mapFilterOperatorToFunction[filter.operator](
                  getChildPropertySchema(entry.data, filter.field_key),
                  filter.value
                )
              );
            }, true);
          }
          return true;
        })
        .forEach((entry) => {
          let entryModel = new EntryModel(entry.id);
          entryModel.updateFromJson(entry);
          entries.push(entryModel);
        });
      setRelatedEntries(entries);
    }
    setIsFetchingSearch(false);

    handleOpenModal(
      <EntryList
        contentTypeEntries={entries}
        contentType={relatedContentType ? relatedContentType : null}
        contentTypesACLRules={contentTypesACL}
        contentTypes={contentTypes}
        entryActions={[
          {
            type: "signInAlt",
            action: handleSearchSelection,
            aclAction: "entries:read",
          },
        ]}
        isAddButtonEnabled={false}
        user={user}
        userRole={userRole}
      />
    );
  }, [
    relatedEntries,
    setRelatedEntries,
    userRole,
    contentTypes,
    contentTypesACL,
    filters,
    handleOpenModal,
    handleSearchSelection,
    relatedContentType,
    relatedContentTypeId,
    relatedTenantId,
    user,
    setIsFetchingSearch,
  ]);

  if (isFetching) {
    return (
      <StyledFieldRelation.Container>
        <Spinner type={"spin"} relativeStyle={true} />
      </StyledFieldRelation.Container>
    );
  }

  return (
    <StyledFieldRelation.Container>
      {!selectedEntry && (
        <StyledFieldRelation.EmptyFieldPlaceholder>
          {strings.widgets.fieldRelation.emptyData}
        </StyledFieldRelation.EmptyFieldPlaceholder>
      )}

      {selectedEntry && (
        <StyledFieldRelation.FieldValue
          onClick={() =>
            handleOpenModal(
              <Form
                schema={relatedContentType ? relatedContentType.schema : null}
                uiSchema={
                  relatedContentType ? relatedContentType.uiSchema : null
                }
                formData={selectedEntry.data}
                hasSubmitButton={false}
                shouldPreventLeavingDirtyForm={false}
                disabled={true}
                tenantId={tenantId}
                contentTypeId={contentTypeId}
                entryId={entryId}
              />
            )
          }
        >
          {interpolation
            .map((interpolationPath, idx) =>
              getChildPropertySchema(selectedEntry.data, interpolationPath)
            )
            .filter((pathValue) => !isNil(pathValue) && !isEmpty(pathValue))
            .map((pathValue) => `${pathValue} `)}
        </StyledFieldRelation.FieldValue>
      )}

      <StyledFieldRelation.ActionGroupContainer>
        {selectedEntry && !readOnly && isOnline && (
          <Button
            model={"secondary"}
            icon={"delete"}
            onClick={handleCleanEntry}
          />
        )}

        {!selectedEntry && !readOnly && (
          <Button
            icon={"search"}
            model={"secondary"}
            onClick={handleSearch}
            loading={isFetchingSearch}
          />
        )}
      </StyledFieldRelation.ActionGroupContainer>
    </StyledFieldRelation.Container>
  );
};

FieldRelationWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.relatedContentTypeId = schema.contentTypeId;
  props.relatedTenantId = schema.tenantId;
  props.readOnly = isNil(uiSchema["ui:enabled"])
    ? schema.readOnly
    : !uiSchema["ui:enabled"];
  props.interpolation = uiSchema["ui:interpolation"] || [];
  props.filters = uiSchema["ui:filters"] || [];
  props.defaultTarget = uiSchema["ui:defaultTarget"] || null;
  return props;
};

export default inject(mapStoresToProps)(FieldRelationWidget);
