/* @flow */
import styled from "styled-components";

const StyledContainer = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  background-color: ${(props) => props.theme.global.colors.white};
  overflow: hidden;

  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    flex-direction: row;
  }
`;

export default StyledContainer;
