/* @flow */
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import StyledFieldset from "../Fieldset/styled";

const StyledHeader = styled.nav`
  position: relative;
  box-sizing: border-box;
  background-color: ${(props) => props.theme.global.colors.darkenBackground};
  display: flex;
  justify-content: flex-start;
  & > * {
    flex: 1;
  }
`;

const StyledHeaderTab = styled.div`
  margin-left: ${(props) => props.theme.global.padding.small};
  padding: ${(props) => props.theme.global.padding.medium}
    ${(props) => props.theme.global.padding.small};
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  opacity: ${(props) => (props.isActive ? "1" : ".75")};

  ${(props) =>
    props.isActive &&
    `
  background-color: ${props.theme.global.colors.white};
  z-index: 1;
  box-shadow: -4px 4px 4px 0px ${transparentize(
    0.97,
    props.theme.global.colors.secondaryColor
  ).toString()};
  border-right: 1px solid ${props.theme.global.colors.border.normal};
  `}
`;

const StyledContent = styled.div`
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: ${(props) => props.theme.global.padding.medium};
  background-color: ${(props) => props.theme.global.colors.white};

  ${StyledFieldset.Container} {
    border-radius: 0;
    border-left: 0;
    border-right: 0;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;

    & + ${StyledFieldset.Container} {
      border-top: 0;
      padding-top: 0;
    }
  }
`;

const StyledIconContainer = styled.div`
  margin-right: 5px;
`;

const StyledContentTab = styled.div`
  display: ${(props) => (props.isActive ? "initial" : "none")};
  & > * {
    margin: ${(props) => props.theme.global.padding.medium} 0;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const verticalTabsStyles = css`
  display: flex;
  flex-direction: row;
  min-height: 100%;

  ${StyledHeader} {
    display: flex;
    flex-direction: column;
    width: 260px;
    max-width: 260px;
  }

  ${StyledHeaderTab} {
    height: 3rem;
    min-height: 3rem;
    max-height: 3rem;
    padding-left: ${(props) => props.theme.global.padding.small};
    padding-right: ${(props) => props.theme.global.padding.xsmall};
  }

  ${StyledContent} {
    flex-grow: 1;
    box-shadow: 0px 4px 8px
      ${(props) =>
        transparentize(0.92, props.theme.global.colors.secondaryColor)};
  }
`;

const horizontalTabsStyles = css`
  display: block;

  ${StyledHeader} {
    border-left: ${(props) => props.theme.global.border.size.xsmall} solid
      ${(props) => props.theme.global.colors.border.light};
    border-right: ${(props) => props.theme.global.border.size.xsmall} solid
      ${(props) => props.theme.global.colors.border.light};
    border-radius: ${(props) => {
      const { medium } = props.theme.global.border.radius;
      return `${medium} ${medium} 0 0`;
    }};
    box-shadow: ${(props) =>
      props.elevated ? props.theme.global.elevation.medium : "none"};
    overflow-x: auto;
  }

  ${StyledHeaderTab} {
    border-right: ${(props) => props.theme.global.border.size.xsmall} solid
      ${(props) => props.theme.global.colors.border.light};
    border-top-left-radius: ${(props) =>
      props.theme.global.border.radius.medium};
    border-top-right-radius: ${(props) =>
      props.theme.global.border.radius.medium};
    &:last-child {
      border-right: none;
    }
    border-bottom: none;
  }

  ${StyledContent} {
    border: ${(props) => props.theme.global.border.size.xsmall} solid
      ${(props) => props.theme.global.colors.border.light};
    border-top: none;
    border-radius: ${(props) => {
      const { medium } = props.theme.global.border.radius;
      return `0 0 ${medium} ${medium}`;
    }};
    box-shadow: ${(props) =>
      props.elevated ? props.theme.global.elevation.medium : "none"};
    border-top: 5px solid ${(props) => props.theme.global.colors.brand};
  }
`;

const StyledTabsDropdownWrapper = styled.div`
  position: relative;
`;

const StyledTabsDropdownHeader = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  background-color: ${(props) => props.theme.global.colors.brand};
  color: ${(props) => props.theme.global.colors.nav.active};
  border-top: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.light};
  border-left: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.light};
  border-right: ${(props) => props.theme.global.border.size.xsmall} solid
    ${(props) => props.theme.global.colors.border.light};
  border-radius: ${(props) => {
    const { medium } = props.theme.global.border.radius;
    return `${medium} ${medium} 0 0`;
  }};
  padding: 1rem;

  ${StyledHeaderTab} {
    background-color: transparent;
    border: 0px;
    padding: 0;
  }

  svg {
    fill: ${(props) => props.theme.global.colors.nav.active};
  }
`;

const StyledTabsDropdownList = styled.ul`
  z-index: 10;
  position: relative;
  width: 100%;
  background: ${(props) => props.theme.global.colors.darkenBackground};
`;

const dropdownItem = css`
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  opacity: ${(props) => (props.selected ? "1" : ".75")};

  ${(props) =>
    props.selected && `background-color: ${props.theme.global.colors.white};`}
`;

const StyledTabsDropdownListItem = styled.li`
  list-style-type: none;
  border-top: 1px solid ${(props) => props.theme.global.colors.background};
  ${dropdownItem}
`;

const mobileCollapseTabsNav = css`
  ${StyledHeader} {
    display: none;
  }
  ${StyledContent} {
    border-top: 5px solid ${(props) => props.theme.global.colors.brand};
  }
`;

const mobileVisibleTabsNav = css`
  ${StyledTabsDropdownWrapper} {
    display: none;
  }
  ${horizontalTabsStyles}
`;

const StyledTabsContainer = styled.div`
  // default mobile view
  @media (max-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    ${(props) =>
      props.tabsNumber > 5 ? mobileCollapseTabsNav : mobileVisibleTabsNav}
  }

  @media (min-width: ${(props) =>
      props.theme.global.breakpoints.medium.value}) {
    ${StyledTabsDropdownWrapper} {
      display: none;
    }
    ${(props) =>
      props.orientation === "vertical"
        ? verticalTabsStyles
        : horizontalTabsStyles}
  }
`;

export default {
  Header: StyledHeader,
  HeaderTab: StyledHeaderTab,
  IconContainer: StyledIconContainer,
  Content: StyledContent,
  ContentTab: StyledContentTab,
  TabsContainer: StyledTabsContainer,
  TabsDropdownWrapper: StyledTabsDropdownWrapper,
  TabsDropdownHeader: StyledTabsDropdownHeader,
  TabsDropdownList: StyledTabsDropdownList,
  TabsDropdownListItem: StyledTabsDropdownListItem,
};
