/* @flow */
import styled from "styled-components";

const StyledContainer = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const StyledHeader = styled.div`
  display: inline-block;
  flex: 1 1;
  padding: ${(props) => props.theme.global.padding.small} 0;
  overflow: hidden;
  white-space: nowrap;
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.global.typography.h5.fontWeight};
`;

export default {
  Container: StyledContainer,
  Header: StyledHeader,
};
