/* @flow */
import React, {
  useRef,
  useContext,
  useState,
  useCallback,
  useEffect,
} from "react";
import { createPortal } from "react-dom";
import { useClickOutside } from "../../utils/hooks";
import Icon from "../Icon";

import StyledModal from "./styled";

export type ModalData = {
  title?: string,
  content: any,
  callback?: () => any,
};

type ModalContextType = {
  openModal: (event: ModalData) => any,
  closeModal: () => any,
};

const ModalContext: React$Context<ModalContextType> = React.createContext({
  openModal: () => {},
  closeModal: () => {},
});

type ModalComponentProps = {
  title?: $PropertyType<ModalData, "title">,
  content: $PropertyType<ModalData, "content">,
  onClose: () => any,
};

const ModalComponent = ({
  title = "",
  content,
  onClose,
  ...props
}: ModalComponentProps) => {
  const ref = useRef();
  useClickOutside(ref, onClose);
  return (
    <StyledModal.Modal tabIndex={"-1"} role={"dialog"} {...props}>
      <StyledModal.Overlay />
      <StyledModal.ModalDialog ref={ref}>
        <StyledModal.ModalContent>
          <StyledModal.ModalHeader>
            <StyledModal.ModalTitle>{title}</StyledModal.ModalTitle>
            <StyledModal.CloseButton type={"button"} onClick={onClose}>
              <Icon type={"close"} size={"small"} />
            </StyledModal.CloseButton>
          </StyledModal.ModalHeader>
          <StyledModal.ModalBody>{content}</StyledModal.ModalBody>
        </StyledModal.ModalContent>
      </StyledModal.ModalDialog>
    </StyledModal.Modal>
  );
};

type ModalProviderProps = {
  children?: any,
};

const Provider = ({ children, ...props }: ModalProviderProps) => {
  const [modalData, setModalData] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const rootNode = useRef(null);

  useEffect(() => {
    rootNode.current = document.createElement("div");
    document.body && document.body.appendChild(rootNode.current);
    return () => {
      rootNode.current &&
        document.body &&
        document.body.removeChild(rootNode.current);
    };
  }, []);

  const closeModal = useCallback(() => {
    modalData && modalData.callback && modalData.callback();
    setIsOpen(false);
    setModalData({});
  }, [setIsOpen, setModalData, modalData]);

  const openModal = useCallback(
    (modalData: ModalData) => {
      setIsOpen(true);
      setModalData(modalData);
    },
    [setIsOpen, setModalData]
  );

  const [modalValue, setModalValue] = useState({ openModal, closeModal });

  return (
    <ModalContext.Provider value={modalValue}>
      {children}
      {rootNode &&
        isOpen &&
        createPortal(
          <ModalComponent
            title={modalData.title}
            content={modalData.content}
            onClose={closeModal}
          />,
          // $FlowFixMe
          rootNode.current
        )}
    </ModalContext.Provider>
  );
};

const useModal = () => useContext(ModalContext);

export { Provider, useModal };
