/* @flow */
import * as React from "react";

import aclUtils from "../../utils/acl";

import type {
  ACLAction,
  ContentTypesACLRules,
  ACLSubject,
  UserRole,
} from "../../types";

type Props = {
  acl: ContentTypesACLRules,
  userRole: ?UserRole,
  subject: ACLSubject,
  perform: ACLAction,
  data?: any,
  yes: () => React.Node,
  no?: () => React.Node,
};

const Can = ({ acl, userRole, subject, perform, data, yes, no }: Props) =>
  aclUtils.checkACL(acl, userRole, subject, perform, data) ? (
    yes()
  ) : no ? (
    no()
  ) : (
    <React.Fragment />
  );

Can.defaultProps = {
  yes: () => <React.Fragment />,
  no: () => <React.Fragment />,
};

export default Can;
