/** @flow */
import styled from "styled-components";
import { meetsContrastGuidelines, invert } from "polished";

const StyledContainer = styled.div`
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  text-align: center;
  width: 2rem;
  height: 2rem;
  line-height: 2rem;
  border-radius: 50%;

  background-color: ${(props) => props.theme.global.colors.brand};
`;

const StyledUsername = styled.span`
  position: absolute;
  left: 50%;
  transform-origin: 0 center;
  transform: scale(1) translateX(-50%);
  text-transform: uppercase;

  ${(props) => {
    const { AA } = meetsContrastGuidelines(
      props.theme.global.colors.brand,
      props.theme.global.colors.secondaryColor
    );

    return `color: ${
      AA
        ? props.theme.global.colors.secondaryColor
        : invert(props.theme.global.colors.secondaryColor)
    }`;
  }}
`;

export default { Container: StyledContainer, Username: StyledUsername };
