/* @flow */
import React, { useEffect } from "react";
import { inject } from "mobx-react";

import keys from "../../config/keys";
import { useAlert } from "../../components/Alert";
import type { AlertSeverity } from "../../components/Alert";
import type { Stores, Timestamp } from "../../types";

type OwnProps = {};

type AlertEvent = {
  id: string,
  severity: AlertSeverity,
  message: string,
  createdAt: Timestamp,
  expiresIn: number,
};

type StoresProps = {|
  alertEvents: AlertEvent[],
|};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores): StoresProps => ({
  alertEvents: stores.log.logs.map(
    ({ id, severity, message, createdAt, payload }) => ({
      id,
      severity,
      message:
        keys.IS_DEV && payload ? `${message}: ${payload.message}` : message,
      createdAt,
      expiresIn: 5000,
    })
  ),
});

let previousEventIds = [];

const AlertRebound = ({ alertEvents }: Props) => {
  const addAlert = useAlert();
  useEffect(() => {
    alertEvents.forEach((alertEvent) => {
      if (
        !(
          alertEvent.expiresIn != null &&
          Date.now() - alertEvent.createdAt >= alertEvent.expiresIn
        ) &&
        !previousEventIds.includes(alertEvent.id)
      ) {
        previousEventIds = [...previousEventIds, alertEvent.id];
        addAlert(alertEvent.message, alertEvent.severity);
        setTimeout(() => {
          previousEventIds = previousEventIds.filter(
            (id) => id !== alertEvent.id
          );
        }, alertEvent.expiresIn);
      }
    });
  }, [alertEvents, addAlert]);
  return <React.Fragment />;
};

export default inject(mapStoresToProps)(AlertRebound);
