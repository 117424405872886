/* @flow */
import React, { Component, Suspense, lazy } from "react";
import { withRouter } from "react-router";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Match as RouterMatch,
} from "react-router-dom";
import {
  inject,
  observer,
  Observer,
  Provider as StoreProvider,
} from "mobx-react";
import { ThemeProvider } from "styled-components";
import { Helmet } from "react-helmet";

import i18nService from "../../services/i18n";

import AppStore from "../../stores/App";
import AuthStore from "../../stores/Auth";
import ContentTypeStore from "../../stores/ContentType";
import EntryStore from "../../stores/Entry";
import LogStore from "../../stores/Log";
import TenantStore from "../../stores/Tenant";
import PWAStore from "../../stores/PWA";
import navigationUtils from "../../utils/navigation";

import Spinner from "../../components/Spinner";
import FullscreenWrapper from "../../components/FullscreenWrapper";
import { Provider as ModalProvider } from "../../components/Modal";
import { Provider as AlertProvider } from "../../components/Alert";
import SyncProgress from "../../components/SyncProgress";
import UpdatePWASnackbar from "../../components/UpdatePWASnackbar";
import AuthScreen from "../Auth";
import ContentTypeListScreen from "../ContentTypeList";
import ContentTypeDetailScreen from "../ContentTypeDetail";
import EntryDetailScreen from "../EntryDetail";
import TenantScreen from "../TenantList";
import AlertRebound from "./AlertRebound";
import PrivateRoute from "./PrivateRoute";
import UserDetailScreen from "../UserDetail";
import LostPasswordScreen from "../LostPassword";
import ResetPasswordScreen from "../ResetPassword";
import ProfileScreen from "../Profile";
import StyledRoot from "./styled";
import keys from "../../config/keys";

import type { Stores, Theme } from "../../types";

const logStore = new LogStore();
const appStore = new AppStore(logStore);
const pwaStore = new PWAStore(logStore);
const contentTypeStore = new ContentTypeStore(logStore);
const entryStore = new EntryStore(logStore, pwaStore);
const tenantStore = new TenantStore(
  logStore,
  appStore,
  contentTypeStore,
  entryStore
);
const authStore = new AuthStore(
  logStore,
  appStore,
  contentTypeStore,
  entryStore,
  tenantStore
);

type OwnProps = {
  location: { hash: string, key?: string, pathname: string, search: string },
  match: RouterMatch,
};
type StoresProps = {|
  tenantId: any,
  tenants: any,
  initializeAppStore: () => Promise<void>,
  initializeAuthStore: () => Promise<void>,
  initializeContentTypeStore: () => Promise<void>,
  initializeEntryStore: () => Promise<void>,
  initializeLogStore: () => Promise<void>,
  initializeTenantStore: () => Promise<void>,
  initializePWAStore: () => Promise<void>,
  isInitialized: boolean,
  isLoggedIn: boolean,
  isLoading: boolean,
  fetchTenants: () => Promise<void>,
  isSynchronizing: boolean,
  isUpdateAvailable: boolean,
  reloadPWA: () => any,
|};
type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const tenantId = navigationUtils.fromRoutes.tenantId(props.location.pathname);
  return {
    tenantId,
    tenants: stores.tenant.tenants,
    initializeAppStore: stores.app.initialize,
    initializeAuthStore: stores.auth.initialize,
    initializeContentTypeStore: stores.contentType.initialize,
    initializeEntryStore: stores.entry.initialize,
    initializeLogStore: stores.log.initialize,
    initializeTenantStore: stores.tenant.initialize,
    initializePWAStore: stores.pwa.initialize,
    isInitialized:
      stores.app.isInitialized &&
      stores.auth.isInitialized &&
      stores.contentType.isInitialized &&
      stores.entry.isInitialized &&
      stores.log.isInitialized &&
      stores.tenant.isInitialized,
    isLoggedIn: stores.auth.isLoggedIn,
    isLoading: false,
    fetchTenants: stores.tenant.fetchTenants,
    isSynchronizing: stores.entry.isSynchronizing,
    isUpdateAvailable: stores.pwa.isUpdateAvailable,
    reloadPWA: stores.pwa.reload,
  };
};

@inject(mapStoresToProps)
@observer
class Root extends Component<Props, void> {
  componentDidMount() {
    this.initialize();
  }

  initialize = async () => {
    await this.props.initializeLogStore();
    await Promise.all([
      this.props.initializeAppStore(),
      this.props.initializeAuthStore(),
      this.props.initializeTenantStore(),
      this.props.initializeContentTypeStore(),
      this.props.initializeEntryStore(),
      this.props.initializePWAStore(),
    ]);
    if (this.props.isLoggedIn) {
      await Promise.all([this.props.fetchTenants()]);
    }
  };

  renderMetadata = () => {
    const { tenantId, tenants } = this.props;
    if (!tenantId) {
      return (
        <Helmet>
          <title>Staza</title>
          <link rel={"manifest"} href={`${keys.BACKEND_PWA_URL}manifest`} />
        </Helmet>
      );
    }

    const tenant = tenants.find(
      (tenant) => tenant.id === tenantId || tenant.name === tenantId
    );
    if (!tenant) {
      return null;
    }
    return (
      <Helmet>
        <title>{tenant.configuration.strings.title}</title>
        <link
          rel={"manifest"}
          href={`${keys.BACKEND_PWA_URL}manifest?tenant=${tenant.name}`}
        />

        {tenant.configuration.manifest.icons.map((icon) => (
          <link
            key={`icon-${icon.src}`}
            rel={"icon"}
            sizes={icon.sizes}
            href={icon.src}
          />
        ))}
        {tenant.configuration.manifest.icons.map((icon) => (
          <link
            key={`apple-touch-icon-${icon.src}`}
            rel={"apple-touch-icon"}
            sizes={icon.sizes}
            href={icon.src}
          />
        ))}

        <meta
          name={"theme-color"}
          content={tenant.configuration.theme.global.colors.brand}
        />
      </Helmet>
    );
  };

  render() {
    const { isInitialized, isLoggedIn, isLoading } = this.props;
    if (!isInitialized) {
      return <Spinner size={150} />;
    }

    const EntryPath = ({ match }) => {
      return (
        <Switch>
          <PrivateRoute
            path={navigationUtils.routes.entry.find(
              match.params.tenantId,
              match.params.contentTypeId,
              `:entryId`
            )}
            component={EntryDetailScreen}
            isAuthenticated={isLoggedIn}
            tenantId={match.params.tenantId}
            contentTypeId={match.params.contentTypeId}
          />
          <PrivateRoute
            exact={true}
            path={match.path}
            component={ContentTypeDetailScreen}
            isAuthenticated={isLoggedIn}
            tenantId={match.params.tenantId}
            contentTypeId={match.params.contentTypeId}
          />
        </Switch>
      );
    };

    return (
      <FullscreenWrapper>
        {this.renderMetadata()}
        {/*<Header />*/}
        {/* TODO: improve suspense spinner */}
        <Suspense fallback={<div />}>
          {/*<Main>*/}
          {isLoading ? (
            <Spinner size={150} />
          ) : (
            <Switch>
              <Route
                path={navigationUtils.routes.auth.login()}
                component={AuthScreen}
              />
              <Route
                path={navigationUtils.routes.auth.lostPassword()}
                component={LostPasswordScreen}
              />
              <Route
                path={navigationUtils.routes.auth.recover()}
                component={ResetPasswordScreen}
              />
              <PrivateRoute
                path={navigationUtils.routes.users.profile()}
                component={ProfileScreen}
                isAuthenticated={isLoggedIn}
              />
              <PrivateRoute
                path={navigationUtils.routes.users.invite(`:tenantId`)}
                component={UserDetailScreen}
                isAuthenticated={isLoggedIn}
              />
              <PrivateRoute
                path={navigationUtils.routes.entry.list(
                  `:tenantId`,
                  `:contentTypeId`
                )}
                component={EntryPath}
                isAuthenticated={isLoggedIn}
              />
              <PrivateRoute
                path={navigationUtils.routes.contentType.list(`:tenantId`)}
                component={ContentTypeListScreen}
                isAuthenticated={isLoggedIn}
              />
              <PrivateRoute
                path={navigationUtils.routes.tenant.list()}
                component={TenantScreen}
                isAuthenticated={isLoggedIn}
              />
              <Redirect to={navigationUtils.routes.tenant.list()} />
            </Switch>
          )}
          <SyncProgress isSynchronizing={this.props.isSynchronizing} />
          <UpdatePWASnackbar
            isVisible={this.props.isUpdateAvailable}
            onClick={this.props.reloadPWA}
          />
          {/*</Main>*/}
        </Suspense>
      </FullscreenWrapper>
    );
  }
}

const RoutedRoot = withRouter(Root);

type ThemedContainerProps = {
  children?: any,
  theme: Theme,
};

const mapStoresToThemedContainerProps = (
  stores: Stores
): ThemedContainerProps => ({
  theme: stores.app.theme,
});

const ThemedContainer = inject(mapStoresToThemedContainerProps)(
  (props: ThemedContainerProps) => (
    <Observer
      render={() => (
        <ThemeProvider theme={props.theme}>
          <StyledRoot>{props.children}</StyledRoot>
        </ThemeProvider>
      )}
    />
  )
);

export default () => (
  <StoreProvider
    app={appStore}
    auth={authStore}
    contentType={contentTypeStore}
    entry={entryStore}
    log={logStore}
    tenant={tenantStore}
    pwa={pwaStore}
  >
    <Router>
      <ThemedContainer>
        <ModalProvider>
          <AlertProvider>
            <AlertRebound />
            <RoutedRoot />
          </AlertProvider>
        </ModalProvider>
      </ThemedContainer>
    </Router>
  </StoreProvider>
);
