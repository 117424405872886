/** @flow */
import React from "react";
import { withTranslation } from "react-i18next";

import StyledUpdatePWASnackbar from "./styled";

import type { TFunction } from "react-i18next";

type Props = {
  isVisible: boolean,
  onClick: () => any,
  t: TFunction,
};

const UpdatePWASnackbar = ({ isVisible, onClick, t }: Props) => (
  <StyledUpdatePWASnackbar.Container isVisible={isVisible} onClick={onClick}>
    {t("message")}
  </StyledUpdatePWASnackbar.Container>
);

export default withTranslation("updatePWASnackbar")(UpdatePWASnackbar);
