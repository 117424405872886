/** @flow */
import styled from "styled-components";
import backgroundTipImage from "../../assets/img/tips/background.svg";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
`;

const StyledTitle = styled.h5`
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.global.typography.text.fontWeight};
  font-style: italic;
  margin-bottom: ${(props) => props.theme.global.padding.large};
`;

const StyleTipImageWrapper = styled.div`
  position: relative;
  background: url(${backgroundTipImage});
  background-position: 10% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  text-align: center;
`;

const StyledTipImage = styled.img`
  max-width: 50%;
`;

const StyledTipDescription = styled.div`
  margin-top: ${(props) => props.theme.global.padding.small};
  text-align: center;
  font-size: ${(props) => props.theme.global.typography.h5.fontSize};
  font-weight: ${(props) => props.theme.global.typography.text.fontWeight};
`;

export default {
  Container: StyledContainer,
  Title: StyledTitle,
  TipImageWrapper: StyleTipImageWrapper,
  TipImage: StyledTipImage,
  TipDescription: StyledTipDescription,
};
