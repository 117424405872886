/* @flow */
import React from "react";

import StyledTable from "./styled";

type CellProps = {
  heading?: boolean,
  children?: any,
};

const Cell = ({ heading = false, children, ...props }: CellProps) => {
  return React.createElement(
    heading ? StyledTable.Th : StyledTable.Td,
    props,
    children
  );
};

type RowProps = {
  children?: any,
  reverse?: boolean,
};

const Row = ({ children, reverse = false, ...props }: RowProps) => {
  return <StyledTable.Row reverse={reverse}>{children}</StyledTable.Row>;
};

type TableProps = {
  head?: any,
  foot?: any,
  caption?: string,
  reverse?: boolean,
  children?: any,
  type?: "grid" | "list",
  readOnly?: boolean,
};

const Table = ({
  caption,
  head,
  foot,
  reverse = false,
  children,
  type = "list",
  readOnly = false,
  ...props
}: TableProps) => {
  return (
    <StyledTable.Table type={type} readOnly={readOnly} {...props}>
      {caption && (
        <StyledTable.Caption reverse={reverse}>{caption}</StyledTable.Caption>
      )}
      {head && <thead>{head}</thead>}
      {foot && <tfoot>{foot}</tfoot>}
      <tbody>{children}</tbody>
    </StyledTable.Table>
  );
};

export default {
  Table,
  Row,
  Cell,
};
