/* @flow */
import React, { useCallback, useEffect } from "react";
import { inject, Observer } from "mobx-react";
import { Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import type {
  Location as RouterLocation,
  Match as RouterMatch,
  RouterHistory,
} from "react-router-dom";
import type { TFunction } from "react-i18next";

import StyledTenantList from "./styled";
import navigationUtils from "../../utils/navigation";
import Sidebar from "../Sidebar";
import ScreenContainer from "../../components/ScreenContainer";
import Container from "../../components/Container";
import Spinner from "../../components/Spinner";
import EntryHeader from "../../components/EntryHeader";
import Card from "../../components/Card";

import type { Tenant, Stores } from "../../types";

type OwnProps = {
  history: RouterHistory,
  location: RouterLocation,
  match: RouterMatch,
  t: TFunction,
};

type StoresProps = {
  tenants: Tenant[],
  fetchTenants: () => Promise<void>,
  isLoading: boolean,
  resetStores: () => any,
  setApplicationConfiguration: () => any,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores): StoresProps => {
  return {
    tenants: stores.tenant.tenants,
    fetchTenants: stores.tenant.fetchTenants,
    isLoading: stores.tenant.isLoading,
    resetStores: stores.tenant.resetStores,
    setApplicationConfiguration: stores.app.setApplicationConfiguration,
  };
};

const TenantList = (props: Props) => {
  const {
    tenants,
    history,
    location,
    isLoading,
    fetchTenants,
    resetStores,
    t,
    setApplicationConfiguration,
  } = props;

  useEffect(() => {
    resetStores();
    setApplicationConfiguration();
    fetchTenants();
  }, [resetStores, fetchTenants, setApplicationConfiguration]);

  const handleTenantClick = useCallback(
    (tenantName) => {
      navigationUtils.goTo(
        { history, location },
        navigationUtils.routes.contentType.list(tenantName)
      );
    },
    [history, location]
  );

  if (tenants && tenants.length === 1) {
    return (
      <Redirect to={navigationUtils.routes.contentType.list(tenants[0].name)} />
    );
  }

  return (
    <Observer
      render={() => (
        <ScreenContainer>
          <Sidebar />
          <Container>
            <EntryHeader.Header>
              <StyledTenantList.Title>{t("title")}</StyledTenantList.Title>
            </EntryHeader.Header>
            {isLoading && <Spinner size={150} />}
            <StyledTenantList.CardsContainer>
              {tenants.map((tenant, index) => (
                <Card
                  key={tenant.id}
                  icon={"thLarge"}
                  logo={tenant.configuration.logo}
                  title={tenant.configuration.strings.title}
                  onClick={() => handleTenantClick(tenant.name)}
                />
              ))}
            </StyledTenantList.CardsContainer>
          </Container>
        </ScreenContainer>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation(["tenantList"])(TenantList)
);
