/* @flow */
import styled, { css } from "styled-components";

import StyledFieldRelationWidget from "../../widgets/FieldRelationWidget/styled";

const readOnlyStyles = css`
  cursor: not-allowed;
`;

const horizontalStyles = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: no-wrap;
  justify-content: space-between;

  & ${StyledFieldRelationWidget.Container} {
    max-width: none;
  }
`;

const verticalStyles = css`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: ${(props) => props.theme.global.padding.medium};
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Content = styled.div`
  ${(props) =>
    props.direction === "horizontal" ? horizontalStyles : verticalStyles}
`;

const Heading = styled.h3`
  display: block;
  position: relative;
  box-sizing: border-box;
  font-weight: ${(props) => props.theme.global.typography.h3.fontWeight};
  font-size: ${(props) => props.theme.global.typography.h3.fontSize};
  line-height: ${(props) => props.theme.global.typography.lineHeight.title};
  color: ${(props) => props.theme.global.colors.text.light};
  padding-bottom: ${(props) => props.theme.global.padding.medium};
`;

const Container = styled.fieldset`
  display: block;
  position: relative;
  box-sizing: border-box;
  padding: ${(props) => props.theme.global.padding.medium};
  width: 100%;

  & > ${Content} > * > & {
    padding: 0;
  }

  & > ${Content} > & {
    padding: 0;

    ${Heading} {
      font-weight: ${(props) => props.theme.global.typography.h4.fontWeight};
      font-size: ${(props) => props.theme.global.typography.h4.fontSize};
      line-height: ${(props) => props.theme.global.typography.lineHeight.title};
      padding-bottom: ${(props) => props.theme.global.padding.small};
    }
  }

  ${(props) => props.readOnly && readOnlyStyles}
`;

export default {
  Container,
  Content,
  Heading,
};
