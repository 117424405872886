/* @flow */
import React from "react";

import StyledEntryHeader from "./styled";

import Icon from "../Icon";

import type { Icon as TypeIcon } from "../../types";

type TitleProps = {
  icon?: TypeIcon,
  title: string,
};

type ActionContainerProps = {
  children?: any,
};

type HeaderProps = {
  children?: any,
};

const Title = ({ icon, title }: TitleProps) => (
  <StyledEntryHeader.TitleContaier>
    {icon && (
      <StyledEntryHeader.IconWrapper>
        <Icon type={icon} />
      </StyledEntryHeader.IconWrapper>
    )}
    <StyledEntryHeader.Title>{title}</StyledEntryHeader.Title>
  </StyledEntryHeader.TitleContaier>
);

const ActionContainer = ({ children }: ActionContainerProps) => (
  <StyledEntryHeader.ActionContainer>
    {children}
  </StyledEntryHeader.ActionContainer>
);

const Header = ({ children, ...otherProps }: HeaderProps) => (
  <StyledEntryHeader.EntryHeader {...otherProps}>
    {children}
  </StyledEntryHeader.EntryHeader>
);
export default { Title, ActionContainer, Header };
