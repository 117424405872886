/* @flow */
// $FlowFixMe
import { RefObject, useEffect } from "react";

export const useClickOutside = (
  ref: RefObject<HTMLElement | null>,
  onClickOutside: (event: SyntheticEvent<>) => void
) => {
  useEffect(() => {
    const handler = (event: SyntheticEvent<>) => {
      const { current: el } = ref;
      el && !el.contains(event.target) && onClickOutside(event);
    };
    // $FlowFixMe
    document.addEventListener("click", handler);
    return () => {
      // $FlowFixMe
      document.removeEventListener("click", handler);
    };
  }, [ref, onClickOutside]);
};
