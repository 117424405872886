/* @flow */
import React, { Component } from "react";
import {
  FaFilter as FilterIcon,
  FaTimesCircle as CancelIcon,
} from "react-icons/fa";

import StyledSearchBar from "./styled";
import Input from "../Input";
import Button from "../Button";
import Icon from "../Icon";

type Props = {
  onChange?: (text: string) => any,
  placeholder?: string,
};

type State = {
  focused: boolean,
  value: string,
};

class SearchBar extends Component<Props, State> {
  state = {
    focused: false,
    value: "",
  };

  inputRef: any = React.createRef();

  handleFocus = (isFocused: boolean) => {
    this.setState({ focused: isFocused });
  };

  setValue = (newValue: string) => {
    const { onChange } = this.props;

    this.setState({ value: newValue });

    if (onChange) {
      onChange(newValue);
    }
  };

  handleChange = (event: any) => {
    this.setValue(event.target.value);
  };

  handleReset = () => {
    this.setValue("");
    this.inputRef.current.focus();
  };

  render() {
    const { onChange, placeholder, ...otherProps } = this.props;
    const { focused, value } = this.state;

    return (
      <StyledSearchBar.Container active={focused || value}>
        <StyledSearchBar.Input
          name={"search"}
          id={"search"}
          type={"text"}
          placeholder={placeholder || "Search"}
          active={focused || value}
          onChange={this.handleChange}
          onFocus={() => this.handleFocus(true)}
          onBlur={() => this.handleFocus(false)}
          ref={this.inputRef}
          {...otherProps}
        />
        {value && (
          <Button model={"secondary"}>
            <CancelIcon onClick={this.handleReset} />
          </Button>
        )}
        <StyledSearchBar.Label htmlFor={"search"}>
          <Icon type={"search"} size={"small"} />
        </StyledSearchBar.Label>
      </StyledSearchBar.Container>
    );
  }
}

export default SearchBar;
