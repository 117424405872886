/** @flow @format */
import { action, observable, computed, autorun } from "mobx";

import LogStore from "./Log";
import PWAStore from "./PWA";
import backendService from "../services/backend";
import EntryModel from "../models/Entry";
import cacheUtil from "../utils/cache";
import fileUtil from "../utils/file";
import { uuidv4 } from "../utils/string";
import Uppy from "@uppy/core";
import AwsS3Multipart from "@uppy/aws-s3-multipart";
import keys from "../config/keys";
import { set } from "lodash-es";

import type {
  Tenant,
  ContentType,
  Entry,
  FormData,
  User,
  UserRole,
} from "../types";

const CACHE_KEY = "CACHE_ENTRY_API_REQUESTS";

export default class EntryStore {
  @observable
  isInitialized: boolean = false;
  @observable
  entries: EntryModel[] = [];
  @observable
  isFetching: boolean = false;
  @observable
  isCreating: boolean = false;
  @observable
  isUpdating: boolean = false;
  @observable
  isDeleting: boolean = false;
  @observable
  isSynchronizing: boolean = false;
  logStore: LogStore;
  pwaStore: PWAStore;
  fetchingQueue: $PropertyType<ContentType, "id">[] = [];

  constructor(logStore: LogStore, pwaStore: PWAStore) {
    this.logStore = logStore;
    this.pwaStore = pwaStore;
  }

  @action
  initialize = async (): Promise<void> => {
    try {
      autorun(() => {
        if (this.pwaStore.isOnline) {
          this.runSynchronization();
        }
      });
    } catch (err) {
      this.logStore.warning(`Errore nella inizializzazione EntryStore`, err);
    }
    this.isInitialized = true;
  };

  @action
  destroy = async (): Promise<void> => {
    this.entries = [];
    this.isFetching = false;
    this.isCreating = false;
    this.isUpdating = false;
    this.isDeleting = false;
  };

  @action
  fetchEntries = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">
  ): Promise<void> => {
    if (this.fetchingQueue.indexOf(contentTypeId) < 0) {
      this.isFetching = true;
      let remoteEntries = [];
      try {
        remoteEntries = await backendService.getEntries(
          tenantId,
          contentTypeId
        );
      } catch (err) {
        this.logStore.warning(
          `Errore nel caricamento dati, assicurarsi di essere connessi a internet`,
          err
        );
      }
      const newEntries = [];
      remoteEntries.forEach((entry) => {
        const entryModel = new EntryModel(entry.id, this.logStore);
        entryModel.updateFromJson(entry);
        newEntries.push(entryModel);
      });
      this.entries = this.entries.filter(
        (entry) => entry.contentTypeId !== Number(contentTypeId)
      );
      this.entries = this.entries.concat(newEntries);

      this.fetchingQueue = this.fetchingQueue.filter(
        (itemId) => itemId !== contentTypeId
      );
      this.isFetching = false;
    }
  };

  @action
  fetchEntry = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<Entry, "id">
  ): Promise<void> => {
    this.isFetching = true;
    let remoteEntry = null;

    try {
      remoteEntry = await backendService.getEntry(
        tenantId,
        contentTypeId,
        entryId
      );
    } catch (err) {
      this.logStore.warning(
        `Errore nel caricamento dati, assicurarsi di essere connessi a internet`,
        err
      );
    }

    if (remoteEntry != null) {
      const entryModel = new EntryModel(entryId, this.logStore);
      entryModel.updateFromJson(remoteEntry);
      this.entries = this.entries
        .filter((entry) => entry.id !== entryId)
        .concat(entryModel);
    }

    this.isFetching = false;
  };

  @action
  createEntry = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    formData: FormData
  ): Promise<EntryModel | null> => {
    this.isCreating = true;
    let createdEntry = null;
    let entryModel = null;

    try {
      createdEntry = await backendService.createEntry(
        tenantId,
        contentTypeId,
        formData
      );
      this.logStore.success("Dati creati correttamente", null);
    } catch (err) {
      this.logStore.warning(`Errore nella creazione dati`, err);
    }
    if (createdEntry != null) {
      entryModel = new EntryModel(createdEntry.id, this.logStore);
      entryModel.updateFromJson(createdEntry);

      this.entries.push(entryModel);
    }

    this.isCreating = false;
    return entryModel;
  };

  @action
  updateEntry = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<Entry, "id">,
    formData: FormData
  ): Promise<EntryModel | null> => {
    this.isUpdating = true;
    let updatedEntry = null;
    let entryModel = null;
    if (this.pwaStore.isOnline) {
      try {
        updatedEntry = await backendService.updateEntry(
          tenantId,
          contentTypeId,
          entryId,
          formData
        );

        this.logStore.success("Dati aggiornati correttamente", null);
      } catch (err) {
        this.logStore.warning(`Errore nel salvataggio dei dati`, err);
      }
      if (updatedEntry != null) {
        entryModel = this.entries.find((entry) => entry.id === entryId);

        if (!entryModel) {
          entryModel = new EntryModel(updatedEntry.id, this.logStore);
          this.entries.push(entryModel);
        }
        entryModel.updateFromJson(updatedEntry);
      }
    } else {
      cacheUtil.cacheAction(CACHE_KEY, {
        cacheItemId: uuidv4(),
        method: "UPDATE",
        tenantId,
        contentTypeId,
        entryId,
        data: formData,
      });

      this.logStore.info("Dati aggiornati in locale.", null);
    }

    this.isUpdating = false;
    return entryModel;
  };

  @action
  deleteEntry = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<Entry, "id">
  ): Promise<void> => {
    this.isDeleting = true;
    try {
      await backendService.deleteEntry(tenantId, contentTypeId, entryId);

      this.logStore.success("Dati cancellati correttamente", null);
      this.entries = this.entries.filter((entry) => entry.id !== entryId);
    } catch (err) {
      this.logStore.warning(`Errore nella cancellazione dei dati`, err);
    }

    this.isDeleting = false;
  };

  @computed
  get isExporting(): boolean {
    return this.entries.some((entryModel) => entryModel.isExporting);
  }

  @computed
  get isLoading(): boolean {
    return (
      this.isFetching ||
      this.isCreating ||
      this.isUpdating ||
      this.isDeleting ||
      this.isExporting
    );
  }

  @action
  cacheUploadFiles = (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<Entry, "id">,
    fieldId: string,
    prevValue: string[]
  ) => {
    cacheUtil.cacheUploadFileAction(CACHE_KEY, {
      cacheItemId: uuidv4(),
      method: "UPLOAD-FILE",
      tenantId,
      contentTypeId,
      entryId,
      fieldId,
      prevValue,
    });
  };

  @action
  fetchOfflineQueue = () => {
    let queue = cacheUtil.getItem(CACHE_KEY) || [];

    queue.forEach(async (action) => {
      let actionCompleted = false;
      if (action.method === "UPDATE") {
        this.updateEntry(
          action.tenantId,
          action.contentTypeId,
          action.entryId,
          action.data
        );
        actionCompleted = true;
      } else if (action.method === "UPLOAD-FILE") {
        actionCompleted = await new Promise(async (resolve, reject) => {
          const entryFilesQueue = cacheUtil.getItem(
            `upload_cache_${action.entryId}_${action.fieldId}`
          );

          if (entryFilesQueue && entryFilesQueue.length > 0) {
            const entryPatchData = {};

            const uppy = Uppy({ logger: Uppy.debugLogger }).use(
              AwsS3Multipart,
              {
                companionUrl: keys.PRESIGN_FILE_S3_URL,
              }
            );

            entryFilesQueue.forEach((file) => {
              uppy.addFile({
                name: file.name,
                type: file.type,
                data: fileUtil.dataURItoFile(file.src, file.name),
              });
            });
            const uploadResult = await uppy.upload();

            const newFilesURL = uploadResult.successful.map((fileMeta) =>
              fileMeta.uploadURL.split("?").shift()
            );
            const filesURL = action.prevValue.filter((src) => src.length > 0);

            set(entryPatchData, action.fieldId, filesURL.concat(newFilesURL));
            this.updateEntry(
              action.tenantId,
              action.contentTypeId,
              action.entryId,
              entryPatchData
            );

            cacheUtil.removeItem(
              `upload_cache_${action.entryId}_${action.fieldId}`
            );

            resolve(true);
          }
        });
      }

      if (actionCompleted) {
        queue = queue.filter(
          (queueAction) => queueAction.cacheItemId !== action.cacheItemId
        );
        cacheUtil.setItem(CACHE_KEY, queue);
      }
    });
  };

  @action
  runSynchronization = async (): Promise<void> => {
    if (this.pwaStore.isOnline) {
      this.isSynchronizing = true;

      await this.fetchOfflineQueue();

      this.isSynchronizing = false;
    }
  };

  @action
  fetchRoles = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ): Promise<UserRole[]> => {
    this.isFetching = true;
    let remoteRoles = [];

    try {
      remoteRoles = await backendService.getRoles(tenantId);
    } catch (err) {
      this.logStore.warning(
        `Errore nel caricamento dati, assicurarsi di essere connessi a internet`,
        err
      );
    }

    this.isFetching = false;
    return remoteRoles;
  };

  @action
  inviteUser = async (
    email: $PropertyType<User, "email">,
    role: $PropertyType<UserRole, "name">,
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ): Promise<void> => {
    this.isFetching = true;

    try {
      await backendService.inviteUser(email, role, tenantId);
      this.logStore.success("Utente invitato correttamente", null);
    } catch (err) {
      this.logStore.warning(
        `Errore nell'esecuzione della richiesta, assicurarsi di essere connessi a internet`,
        err
      );
    }

    this.isFetching = false;
  };
}
