/* @flow */
import styled from "styled-components";
import {
  transparentize,
  invert,
  meetsContrastGuidelines,
  getLuminance,
  darken,
  lighten,
} from "polished";
import { Link } from "react-router-dom";
import Button from "../../components/Button/styled";
import Dropdown from "../../components/Dropdown/styled";

const StyledBrandContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: opacity 0.2s ease;
  padding-top: ${(props) => props.theme.global.padding.small};
  padding-bottom: ${(props) => props.theme.global.padding.small};
  padding-left: ${(props) => props.theme.global.padding.medium};
  padding-right: ${(props) => props.theme.global.padding.xsmall};

  &:hover {
    opacity: 0.75;
  }

  h5 {
    margin-left: ${(props) => props.theme.global.padding.small};
    font-size: ${(props) => props.theme.global.typography.h5.fontSize};
    font-weight: ${(props) => props.theme.global.typography.text.fontWeight};
  }

  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    padding-top: 0;
  }
`;

const StyledBrand = styled.div`
  display: block;
  width: 52px;
  height: 52px;

  img {
    width: 100%;
  }
`;

const StyledNavToggler = styled.div`
  position: absolute;
  left: 0.5rem;
  top: 0.5rem;
  svg {
    fill: ${(props) => props.theme.global.colors.secondaryColor};
  }

  ${Button.SecondaryButton}:focus {
    background-color: transparent;
  }
`;

const StyledNavWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;

  ${Dropdown} {
    display: none;

    @media (min-width: ${(props) =>
        props.theme.global.breakpoints.large.value}) {
      display: block;
    }
  }
`;

const StyledScrollableNavWrapper = styled(StyledNavWrapper)`
  flex: 1 1 auto;
  overflow-y: auto;
  justify-content: center;
`;

const StyledNavItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 42px;
  margin: 0.5em;
  opacity: 0.75;
  font-weight: 400;
  border-radius: 0.5em;
  color: ${(props) => props.theme.global.colors.secondaryColor};

  cursor: pointer;
  padding-left: ${(props) => props.theme.global.padding.xsmall};
  padding-right: ${(props) => props.theme.global.padding.xsmall};

  transition: background-color 1s cubic-bezier(0.2, 0, 0, 1) 0s,
    color 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;

  svg {
    fill: ${(props) => props.theme.global.colors.secondaryColor};
    transition: fill 0.3s cubic-bezier(0.2, 0, 0, 1) 0s;
  }

  &:hover {
    opacity: 1;
  }

  &.active {
    opacity: 1;
    font-weight: 500;
    background-color: ${(props) =>
      transparentize(0.9, props.theme.global.colors.brand)};
    color: ${(props) => props.theme.global.colors.brand};

    svg {
      fill: ${(props) => props.theme.global.colors.brand};
    }
  }
`;

const StyledNavItemIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
`;

const StyledNavItemText = styled.span``;

const StyledMobileNavItem = styled(StyledNavItem)`
  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    display: none;
  }
`;

const StyledCollapseSidebarButton = styled.button`
  position: absolute;
  padding: 0;
  min-width: auto;
  min-height: auto;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 1rem;
  right: -0.8rem;
  top: 1.5rem;
  box-shadow: rgba(9, 30, 66, 0.08) 0px 0px 0px 1px,
    rgba(9, 30, 66, 0.08) 0px 2px 4px 1px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  background-color: ${(props) => props.theme.global.colors.white};
  border: 0px;
  cursor: pointer;

  &,
  &:active,
  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: none;
    background-color: ${(props) => props.theme.global.colors.brand};
  }

  &:hover,
  &:focus {
    background-color: ${(props) =>
      getLuminance(props.theme.global.colors.brand) > 0.5
        ? darken(0.07, props.theme.global.colors.brand)
        : lighten(0.07, props.theme.global.colors.brand)};

    ${(props) => {
      const { AA } = meetsContrastGuidelines(
        props.theme.global.colors.brand,
        props.theme.global.colors.secondaryColor
      );

      const color = AA
        ? props.theme.global.colors.secondaryColor
        : invert(props.theme.global.colors.secondaryColor);

      return `
            color: ${color};
            
            svg {
              fill: ${color};
            }
          `;
    }}
  }

  &:active {
    background-color: ${(props) =>
      getLuminance(props.theme.global.colors.brand) > 0.5
        ? darken(0.12, props.theme.global.colors.brand)
        : lighten(0.12, props.theme.global.colors.brand)};

    ${(props) => {
      const { AA } = meetsContrastGuidelines(
        props.theme.global.colors.brand,
        props.theme.global.colors.secondaryColor
      );

      const color = AA
        ? props.theme.global.colors.secondaryColor
        : invert(props.theme.global.colors.secondaryColor);

      return `
            color: ${color};
            
            svg {
              fill: ${color};
            }
          `;
    }}
  }
`;

const StyledSidebarContainer = styled.aside`
  position: fixed;
  width: 100vw;
  height: calc(100vh - ${(props) => props.theme.global.mobile.header.height});
  top: ${(props) => props.theme.global.mobile.header.height};
  left: 0;
  background: ${(props) => props.theme.global.colors.sidebar.backgroundColor};
  z-index: 500;
  border-right: 1px solid ${(props) => props.theme.global.colors.border.normal};
  border-top: 1px solid ${(props) => props.theme.global.colors.border.normal};
  display: ${(props) => (props.isOpen ? "flex" : "none")};
  flex-direction: column;
  justify-content: space-between;

  & > ${StyledNavWrapper}:last-child {
    border-top: 1px solid ${(props) => props.theme.global.colors.border.normal};
  }

  & ${StyledCollapseSidebarButton} {
    display: none;
  }

  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    position: relative;
    display: flex;
    top: 0;
    min-width: 0;
    flex-grow: 0;
    flex-shrink: 0;
    height: 100%;

    & > *:first-child {
      margin-top: ${(props) => props.theme.global.padding.medium};
    }

    & > *:last-child {
      margin-bottom: ${(props) => props.theme.global.padding.medium};
    }

    & > ${StyledNavWrapper}:last-child {
      border-top: 0;
    }

    & ${StyledCollapseSidebarButton} {
      display: block;
    }

    ${(props) =>
      props.isCollapsed
        ? `
        max-width: 70px;
        h5, ${StyledNavItemText} {
          display: none;
        }
        ${StyledBrandContainer} {
          padding-left: 1rem;
        }
        ${StyledBrand} img {
          max-width: 70%;
        }
        `
        : `
        max-width: 250px;
        `}
  }
`;

const StyledNavLogo = styled(Link)`
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.75;
  }

  img {
    height: 1.75rem;
  }
`;

const StyledMobileHeader = styled.nav`
  position: fixed;
  top: 0;
  height: ${(props) => props.theme.global.mobile.header.height};
  width: 100%;
  z-index: 500;
  background: ${(props) => props.theme.global.colors.sidebar.backgroundColor};
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${(props) => props.theme.global.breakpoints.large.value}) {
    display: none;
  }
`;

export default {
  Container: StyledSidebarContainer,
  NavToggler: StyledNavToggler,
  NavLogo: StyledNavLogo,
  NavWrapper: StyledNavWrapper,
  ScrollableNavWrapper: StyledScrollableNavWrapper,
  NavItem: StyledNavItem,
  NavItemIcon: StyledNavItemIcon,
  NavItemText: StyledNavItemText,
  MobileNavItem: StyledMobileNavItem,
  Brand: StyledBrand,
  BrandContainer: StyledBrandContainer,
  MobileHeader: StyledMobileHeader,
  CollapseSidebarButton: StyledCollapseSidebarButton,
};
