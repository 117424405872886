/* @flow */
import { isString } from "lodash-es";
import dayjs from "dayjs";

/**
 * Generare a RFC4122 version 4 compliant UUID.
 * @return {string} The generated UUID string
 */
export const uuidv4 = (): string => {
  let d = new Date().getTime();
  if (
    typeof performance !== "undefined" &&
    typeof performance.now === "function"
  ) {
    d += performance.now(); //use high-precision timer if available
  }
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
  });
};

/**
 * Validate input value to IPV4 format.
 * @return {boolean} Whether the input is a valid IPV4
 */
export const getIsIpv4 = (v: any) => {
  const ipv4Regex = /^(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)(?:\\.(?:25[0-5]|2[0-4]\\d|1\\d\\d|[1-9]\\d|\\d)){3}$/;
  return ipv4Regex.test(String(v));
};

/**
 * Validate input value to IPV6 format.
 * @return {boolean} Whether the input is a valid IPV6
 */
export const getIsIpv6 = (v: any) => {
  const ipv6Regex = /^((?:${v6seg}:){7}(?:${v6seg}|:)|(?:${v6seg}:){6}(?:${v4}|:${v6seg}|:)|(?:${v6seg}:){5}(?::${v4}|(:${v6seg}){1,2}|:)|(?:${v6seg}:){4}(?:(:${v6seg}){0,1}:${v4}|(:${v6seg}){1,3}|:)|(?:${v6seg}:){3}(?:(:${v6seg}){0,2}:${v4}|(:${v6seg}){1,4}|:)|(?:${v6seg}:){2}(?:(:${v6seg}){0,3}:${v4}|(:${v6seg}){1,5}|:)|(?:${v6seg}:){1}(?:(:${v6seg}){0,4}:${v4}|(:${v6seg}){1,6}|:)|(?::((?::${v6seg}){0,5}:${v4}|(?::${v6seg}){1,7}|:)))(%[0-9a-zA-Z]{1,})?$/;
  return ipv6Regex.test(String(v));
};

export const prettifyDate = (v: any): ?string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format("YYYY-MM-DD");
};

export const prettifyDateTime = (v: any): ?string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format("YYYY-MM-DD HH:mm:ss");
};

export const prettifyTime = (v: any): ?string => {
  const parsed = dayjs(v);
  return !v || !parsed.isValid() ? "" : parsed.format("HH:mm:ss");
};

export const getIsVideoSrc = (fileSrc: any): boolean =>
  isString(fileSrc) &&
  (fileSrc.startsWith("data:video") ||
    /\.(mpeg|mp4|mov|avi|ogv|webm|3gp|3g2)$/gim.test(fileSrc));

export const getIsAudioSrc = (fileSrc: any): boolean =>
  isString(fileSrc) &&
  (fileSrc.startsWith("data:audio") ||
    /\.(mp3|mpga|aac|oga|ogg|wav|weba|3gp|3g2)$/gim.test(fileSrc));

export const getIsImageSrc = (fileSrc: any): boolean =>
  isString(fileSrc) &&
  (fileSrc.startsWith("data:image") ||
    /\.(png|jpg|jpeg|svg|gif|ico|tif|tiff|webp)$/gim.test(fileSrc));

export const getIsDocumentSrc = (fileSrc: any): boolean =>
  isString(fileSrc) && /\.(pdf)$/gim.test(fileSrc);

export const fuzzyMatch = (text: string, searchToken: string): boolean => {
  const normalizedText = String(text).trim().toLowerCase();
  const normalizedSearchToken = String(searchToken).trim().toLowerCase();
  return normalizedText.includes(normalizedSearchToken);
};

export const escapeRegExp = (str: string) => {
  return str.replace(/[-[\]/{}()*+?.\\^$|]/g, "\\$&");
};

export const fixLeadingZero = (numStr?: string) => {
  if (!numStr) return numStr;
  const isNegative = numStr[0] === "-";
  if (isNegative) numStr = numStr.substring(1, numStr.length);
  const parts = numStr.split(".");
  const beforeDecimal = parts[0].replace(/^0+/, "") || "0";
  const afterDecimal = parts[1] || "";

  return `${isNegative ? "-" : ""}${beforeDecimal}${
    afterDecimal ? `.${afterDecimal}` : ""
  }`;
};

export const getThousandsGroupRegex = (thousandsGroupStyle: string) => {
  switch (thousandsGroupStyle) {
    case "lakh":
      return /(\d+?)(?=(\d\d)+(\d)(?!\d))(\.\d+)?/g;
    case "wan":
      return /(\d)(?=(\d{4})+(?!\d))/g;
    case "thousand":
    default:
      return /(\d)(?=(\d{3})+(?!\d))/g;
  }
};

export const applyThousandSeparator = (
  str: string,
  thousandSeparator: string,
  thousandsGroupStyle: string
) => {
  const thousandsGroupRegex = getThousandsGroupRegex(thousandsGroupStyle);
  let index = str.search(/[1-9]/);
  index = index === -1 ? str.length : index;
  return (
    str.substring(0, index) +
    str
      .substring(index, str.length)
      .replace(thousandsGroupRegex, "$1" + thousandSeparator)
  );
};
