/** @flow */
import React, { Component, Fragment } from "react";

import StyledSyncProgress from "./styled";
import Icon from "../Icon";

type Props = {
  isSynchronizing: boolean,
};

const SyncProgress = (props: Props) =>
  props.isSynchronizing ? (
    <StyledSyncProgress.Container>
      <StyledSyncProgress.IconWrapper>
        <Icon type={"syncAlt"} />
      </StyledSyncProgress.IconWrapper>
    </StyledSyncProgress.Container>
  ) : null;

export default SyncProgress;
