/* @flow */
import React, { useEffect, useState } from "react";

import StyledTabs from "./styled";
import Icon from "../Icon";
import TabsDropdown from "./TabsDropdown";

import type { Tab as TabType } from "../../types/Tab";

type TabListProps = {
  orientation?: "horizontal" | "vertical",
  readOnly?: boolean,
  tabs: TabType[],
};

const Tabs = ({
  orientation = "horizontal",
  readOnly = false,
  tabs = [],
}: TabListProps) => {
  const [selectedTabId, setSelectedTabId] = useState(null);
  useEffect(() => {
    const tabIds = tabs.map((tab) => tab.id);
    setSelectedTabId((selectedTabId) => {
      if (!tabIds.some((id) => id === selectedTabId)) {
        return tabIds.length > 0 ? tabIds[0] : null;
      }
      return selectedTabId;
    });
  }, [tabs, setSelectedTabId]);

  return (
    <StyledTabs.TabsContainer
      orientation={orientation}
      tabsNumber={tabs.length}
    >
      <StyledTabs.Header>
        {tabs.map((tab) => (
          <StyledTabs.HeaderTab
            key={tab.id}
            isActive={selectedTabId === tab.id}
            onClick={() => setSelectedTabId(tab.id)}
          >
            <StyledTabs.IconContainer>
              {tab.icon && <Icon type={tab.icon} size={"small"} />}
            </StyledTabs.IconContainer>
            <span>{tab.title}</span>
          </StyledTabs.HeaderTab>
        ))}
      </StyledTabs.Header>
      <TabsDropdown tabs={tabs} onChange={setSelectedTabId} />
      <StyledTabs.Content readOnly={readOnly}>
        {tabs.map((tab) => (
          <StyledTabs.ContentTab
            key={tab.id}
            isActive={selectedTabId === tab.id}
          >
            {tab.render()}
          </StyledTabs.ContentTab>
        ))}
      </StyledTabs.Content>
    </StyledTabs.TabsContainer>
  );
};

export default Tabs;
