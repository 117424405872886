/* @flow */
import styled, { css } from "styled-components";

const Container = styled.label`
  display: block;
  position: relative;
  box-sizing: border-box;
  cursor: ${(props) => (props.readOnly ? "not-allowed" : "pointer")};
  color: ${(props) => props.theme.global.colors.text.light};
`;

const readOnlyBoxStyle = css`
  &:hover,
  &:focus {
    border-color: ${(props) => props.theme.global.colors.border.normal};
  }
  cursor: not-allowed;
`;

const Box = styled.span`
  display: inline-block;
  box-sizing: border-box;
  position: relative;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  max-height: 1.15em;
  max-width: 1.15em;
  min-height: 1.15em;
  min-width: 1.15em;
  transition: opacity ${(props) => props.theme.global.animation.fade.duration}
    ease;
  transition: border-color
    ${(props) => props.theme.global.animation.fade.duration} ease;
  transition: background
    ${(props) => props.theme.global.animation.fade.duration} ease;
  border: 1px solid
    ${(props) =>
      props.checked
        ? props.theme.global.colors.brand
        : props.theme.global.colors.border.normal};
  background: ${(props) =>
    props.checked ? props.theme.global.colors.brand : "initial"};
  border-radius: 2px;
  &:focus {
    outline: none;
    border: 1px solid ${(props) => props.theme.global.colors.black};
  }
  &::-moz-focus-inner {
    border: none;
  }
  &::-moz-focus-outer {
    border: none;
  }
  &:after {
    display: none;
    content: "";
    position: absolute;
    left: 27%;
    top: 5%;
    width: 45%;
    height: 70%;
    border: solid white;
    border-width: 0 0.1em 0.1em 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  &:hover {
    opacity: 0.8;
  }
  ${(props) => props.readOnly && readOnlyBoxStyle}
`;

const Input = styled.input`
  display: none;
  box-sizing: border-box;
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  -webkit-appearance: none;
  :checked ~ ${Box} {
    background-color: ${(props) => props.theme.global.colors.brand};
  }
  :checked ~ ${Box}:after {
    display: block;
  }
`;

const Label = styled.label`
  margin-right: 0.5em;
  &:empty {
    margin-right: 0;
  }
`;

export default {
  Container,
  Input,
  Box,
  Label,
};
