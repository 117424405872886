/** @flow @format */
import { action, observable, computed } from "mobx";
import { isEmpty } from "lodash-es";

import LogStore from "./Log";
import AppStore from "./App";
import ContentTypeStore from "./ContentType";
import EntryStore from "./Entry";
import TenantStore from "./Tenant";
import backendService from "../services/backend";

import type { User } from "../types";

export default class AuthStore {
  @observable
  isInitialized: boolean = false;
  @observable
  user: ?User = null;
  @observable
  isLoggingIn: boolean = false;
  @observable
  isLoggingOut: boolean = false;
  logStore: LogStore;
  appStore: AppStore;
  contentTypeStore: ContentTypeStore;
  entryStore: EntryStore;
  tenantStore: TenantStore;
  @observable
  isUpdatingUserProfile: boolean = false;

  constructor(
    logStore: LogStore,
    appStore: AppStore,
    contentTypeStore: ContentTypeStore,
    entryStore: EntryStore,
    tenantStore: TenantStore
  ) {
    this.logStore = logStore;
    this.appStore = appStore;
    this.contentTypeStore = contentTypeStore;
    this.entryStore = entryStore;
    this.tenantStore = tenantStore;
  }

  @action
  initialize = async () => {
    try {
      this.user = await backendService.getCurrentUser();
    } catch (err) {
      this.logStore.warning(`Errore nella inizializzazione AuthStore`, err);
    }
    this.isInitialized = true;
  };

  @action
  destroy = async (): Promise<void> => {
    await Promise.all([
      this.logStore.destroy(),
      this.contentTypeStore.destroy(),
      this.entryStore.destroy(),
      this.appStore.destroy(),
      this.tenantStore.destroy(),
    ]);
    this.user = null;
    this.isLoggingIn = false;
    this.isLoggingOut = false;
  };

  @action
  login = async (email: string, password: string) => {
    this.isLoggingIn = true;
    let user;
    try {
      user = await backendService.login(email, password);
    } catch (err) {
      this.logStore.warning(`Errore login`, err);
    }
    this.user = user;
    this.isLoggingIn = false;
  };

  @action
  logout = async () => {
    this.isLoggingOut = true;
    try {
      await backendService.logout();
    } catch (err) {
      this.logStore.warning(`Errore logout`, err);
    }
    await this.destroy();
    this.isLoggingOut = false;
  };

  @action
  updateUserProfile = async (userData: User) => {
    this.isUpdatingUserProfile = true;

    try {
      await backendService.updateUserProfile(userData);
      this.user = userData;
      this.logStore.success("Profilo aggiornato correttamente", null);
    } catch (err) {
      this.logStore.warning(`Errore logout`, err);
    }

    this.isUpdatingUserProfile = false;
  };

  @computed
  get isLoggedIn(): boolean {
    return !isEmpty(this.user);
  }

  @action
  forgotPassword = async (email: string) => {
    try {
      await backendService.forgotPassword(email);
      this.logStore.success(
        "Richiesta eseguita con successo. Controlla la tua casella di posta.",
        null
      );
    } catch (err) {
      console.log(err);
      this.logStore.warning(
        `Errore durante l'esecuzione della richiesta.`,
        err
      );
    }
  };

  @action
  resetPassword = async (email: string, password: string, token: string) => {
    let success = true;
    try {
      await backendService.resetPassword(email, password, token);
      this.logStore.success("Password modificata con successo.", null);
    } catch (err) {
      console.log(err);
      this.logStore.warning(
        `Errore durante l'esecuzione della richiesta.`,
        err
      );
      success = false;
    }

    return success;
  };
}
