/* @flow */
import React from "react";

import Fieldset from "../../components/Fieldset";

type Item = any;

type Props = {
  title?: string,
  elevated?: boolean,
  items: Item[],
  renderItem: (item: Item) => any,
  getItemKey: (item: Item) => string,
  readOnly?: boolean,
  direction?: "horizontal" | "vertical",
};

const FieldsetGrouping = ({
  title,
  elevated,
  items,
  renderItem,
  getItemKey,
  readOnly = false,
  direction = "vertical",
}: Props) => {
  return (
    <Fieldset
      title={title}
      elevated={elevated}
      readOnly={readOnly}
      direction={direction}
    >
      {items.map((item) => (
        <React.Fragment key={getItemKey(item)}>
          {renderItem(item)}
        </React.Fragment>
      ))}
    </Fieldset>
  );
};

export default FieldsetGrouping;
