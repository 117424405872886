/** @flow */
/* https://github.com/react-bootstrap/react-bootstrap/blob/master/src/DropdownToggle.js */
import React from "react";
import { useDropdownToggle } from "react-overlays/DropdownToggle";
import useMergedRefs from "@restart/hooks/useMergedRefs";

import StyleDropdownToggle from "./styled";

type Props = {
  /**
   * An html id attribute, necessary for assistive technologies, such as screen readers.
   */
  id: string,

  split?: boolean,

  as?: any,

  className?: string,
  children?: any,
};

const DropdownToggle = React.forwardRef<Props, HTMLDivElement>(
  (
    {
      split,
      className,
      children,
      as: Component = StyleDropdownToggle,
      ...props
    }: Props,
    ref
  ) => {
    const [toggleProps, { toggle }] = useDropdownToggle();

    toggleProps.ref = useMergedRefs(toggleProps.ref, ref);

    // This intentionally forwards size and variant (if set) to the
    // underlying component, to allow it to render size and style variants.
    return (
      <Component
        onClick={toggle}
        className={`${className ? className : ""}  ${split ? `split` : ""}`}
        {...toggleProps}
        {...props}
      >
        {children}
      </Component>
    );
  }
);

export default DropdownToggle;
