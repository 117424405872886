/** @flow @format */
import { action, observable, computed } from "mobx";

import LogStore from "./Log";
import backendService from "../services/backend";

import type { ContentTypesACLRules, ContentType, Tenant } from "../types";

export default class ContentTypeStore {
  @observable
  isInitialized: boolean = false;
  @observable
  contentTypes: ContentType[] = [];
  @observable
  isFetching: boolean = false;
  logStore: LogStore;
  fetchingQueue: (
    | $PropertyType<Tenant, "id">
    | $PropertyType<Tenant, "name">
  )[] = [];

  constructor(logStore: LogStore) {
    this.logStore = logStore;
  }

  @action
  initialize = async (): Promise<void> => {
    try {
    } catch (err) {
      this.logStore.warning(
        `Errore nella inizializzazione ContentTypeStore`,
        err
      );
    }
    this.isInitialized = true;
  };

  @action
  destroy = async (): Promise<void> => {
    this.contentTypes = [];
    this.isFetching = false;
  };

  @action
  fetchContentTypes = async (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ): Promise<void> => {
    if (this.fetchingQueue.indexOf(tenantId) < 0) {
      this.fetchingQueue.push(tenantId);
      this.isFetching = true;
      let contentTypes = [];
      try {
        contentTypes = await backendService.getContentTypes(String(tenantId));
      } catch (err) {
        this.logStore.warning(
          `Errore nel caricamento tipologia dati, assicurarsi di essere connessi a internet`,
          err
        );
      }
      this.contentTypes = contentTypes;
      this.fetchingQueue = this.fetchingQueue.filter(
        (itemId) => itemId !== tenantId
      );
      this.isFetching = false;
    }
  };

  @action
  getContentType = (
    contentTypeId: $PropertyType<ContentType, "id">
  ): ContentType | null => {
    return (
      this.contentTypes.find(
        (contentType) => contentType.id == contentTypeId
      ) || null
    );
  };

  @action
  getContentType = (
    contentTypeId: $PropertyType<ContentType, "id">
  ): ContentType | null => {
    return (
      this.contentTypes.find(
        (contentType) => contentType.id == contentTypeId
      ) || null
    );
  };

  @computed
  get aclRules(): ContentTypesACLRules {
    const aclRules = {};
    this.contentTypes.forEach(
      (contentType) => (aclRules[String(contentType.id)] = contentType.acls)
    );
    return aclRules;
  }

  @computed
  get isLoading(): boolean {
    return this.isFetching;
  }
}
