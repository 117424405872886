/* @flow */
import React, { useEffect, useState } from "react";
import { inject, Observer } from "mobx-react";
import { withTranslation } from "react-i18next";

import type {
  Match as RouterMatch,
  Location as RouterLocation,
  RouterHistory,
} from "react-router-dom";
import type { TFunction } from "react-i18next";

import StyledEntryDetail from "../EntryDetail/styled";

import EntryModel from "../../models/Entry";
import Sidebar from "../Sidebar";
import ScreenContainer from "../../components/ScreenContainer";
import Container from "../../components/Container";
import EntryHeader from "../../components/EntryHeader";
import EntryContent from "../../components/EntryContent";
import BackButton from "../../components/BackButton";
import Form from "../../components/Form";
import Spinner from "../../components/Spinner";
import EmptyState from "../../components/EmptyState";
import Button from "../../components/Button";

import type {
  ContentType,
  Stores,
  Tenant,
  User,
  UISchema,
  UserRole,
} from "../../types";

type OwnProps = {
  tenantId?: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
  match: RouterMatch,
  history: RouterHistory,
  location: RouterLocation,
  t: TFunction,
};

type StoresProps = {
  entryId: $PropertyType<EntryModel, "id">,
  isLoading: boolean,
  fetchEntry: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">,
    contentTypeId: $PropertyType<ContentType, "id">,
    entryId: $PropertyType<EntryModel, "id">
  ) => Promise<any>,
  fetchRoles: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Promise<UserRole[] | null>,
  getTenant: (
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Tenant | null,
  entry: EntryModel | null,
  setApplicationConfiguration: (configuration: Object) => any,
  isAppOnline: boolean,
  inviteUser: (
    email: $PropertyType<User, "email">,
    role: $PropertyType<UserRole, "name">,
    tenantId: $PropertyType<Tenant, "id"> | $PropertyType<Tenant, "name">
  ) => Promise<any>,
};

type Props = OwnProps & StoresProps;

const mapStoresToProps = (stores: Stores, props: Props): StoresProps => {
  const tenantId = props.match.params.tenantId;
  const entryId = props.match.params.entryId;

  return {
    entryId,
    tenantId,
    isLoading: stores.contentType.isLoading || stores.entry.isLoading,
    fetchEntry: stores.entry.fetchEntry,
    fetchRoles: stores.entry.fetchRoles,
    getTenant: stores.tenant.getTenant,
    entry: stores.entry.entries.find((entry) => entry.id === entryId) || null,
    setApplicationConfiguration: stores.app.setApplicationConfiguration,
    isAppOnline: stores.pwa.isOnline,
    inviteUser: stores.entry.inviteUser,
  };
};

const UserDetailScreen = ({
  tenantId,
  entryId,
  isLoading,
  fetchEntry,
  fetchRoles,
  getTenant,
  entry,
  setApplicationConfiguration,
  isAppOnline,
  inviteUser,
  location,
  history,
  t,
}: Props) => {
  const [isFetching, setIsFetching] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [schema, setSchema] = useState({
    title: "Profilo utente",
    type: "object",
    required: ["email", "role"],
    properties: {
      email: {
        title: t("email"),
        type: "string",
      },
      role: {
        title: t("role"),
        type: "string",
        enum: [],
        enumNames: [],
      },
    },
  });

  let submitInviteUserForm = null;
  const bindSubmitForm = (submitForm) => (submitInviteUserForm = submitForm);

  const handleSubmit = async (e) => {
    if (submitInviteUserForm) {
      submitInviteUserForm(e);
    }
  };

  const initialize = async () => {
    if (tenantId) {
      const tenantData = getTenant(tenantId);
      if (tenantData) {
        setApplicationConfiguration(tenantData.configuration);
      }

      setIsFetching(true);
      const roles = await fetchRoles(tenantId);

      const roleOptions = roles ? roles.map((role) => role.name) : [];
      const roleLabels = roles ? roles.map((role) => role.label) : [];

      const schemaWithRoles = schema;

      schemaWithRoles.properties.role.enum = roleOptions;
      schemaWithRoles.properties.role.enumNames = roleLabels;

      setSchema(schemaWithRoles);
      setIsFetching(false);
    }
  };

  useEffect(() => {
    initialize();
  }, []);

  const uiSchema: UISchema = {};

  const actionLabel = entry ? "Modifica" : "Nuovo";

  const canRead = true;
  const canCreate = true;
  const canUpdate = true;
  const canSubmit = (entry == null ? canCreate : canUpdate) || true;

  const handleInviteUser = async (values: Object) => {
    if (tenantId) {
      setIsSubmitting(true);
      await inviteUser(values.email, values.role, tenantId);
      setIsSubmitting(false);
    }
  };

  return (
    <Observer
      render={() => (
        <ScreenContainer>
          <Sidebar />
          <Container>
            <EntryHeader.Header>
              <StyledEntryDetail.TitleWrapper>
                <BackButton
                  size={"large"}
                  location={location}
                  history={history}
                />
                <EntryHeader.Title icon={"customer"} title={schema.title} />
                <StyledEntryDetail.SubTitle>
                  {actionLabel}
                </StyledEntryDetail.SubTitle>
              </StyledEntryDetail.TitleWrapper>
              <EntryHeader.ActionContainer>
                {canSubmit && (
                  <Button
                    icon={"check"}
                    onClick={handleSubmit}
                    loading={isSubmitting}
                  >
                    {t("save")}
                  </Button>
                )}
              </EntryHeader.ActionContainer>
            </EntryHeader.Header>

            {isFetching && <Spinner size={150} />}

            {!isFetching && (
              <EntryContent>
                {(entry != null && !canRead) ||
                (entry == null && !canCreate) ? (
                  <EmptyState reason={"unauthorized"} />
                ) : (
                  <div style={{ padding: "1.5rem" }}>
                    <Form
                      key={JSON.stringify(schema)}
                      schema={schema}
                      uiSchema={uiSchema}
                      formData={{}}
                      stagesMetadata={[]}
                      onSubmit={handleInviteUser}
                      hasSubmitButton={false}
                      shouldPreventLeavingDirtyForm={true}
                      contentTypes={[]}
                      contentTypesACL={{}}
                      isOnline={isAppOnline}
                      tenantId={tenantId}
                      contentTypeId={0}
                      entryId={entryId}
                      bindSubmitForm={bindSubmitForm}
                    />
                  </div>
                )}
              </EntryContent>
            )}
          </Container>
        </ScreenContainer>
      )}
    />
  );
};

export default inject(mapStoresToProps)(
  withTranslation(["common"])(UserDetailScreen)
);
