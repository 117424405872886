/** @flow @format */
export default {
  auth: {
    login: "Login",
    username: "Username",
    password: "Password",
    submitLogin: "Login",
    email: "Email",
    confirm: "Conferma",
    backToLogin: "Torna al login",
    lostPassword: "Ho dimenticato la password",
    lostPasswordLabel: "Invieremo il link di recupero a",
    enterEmail: "Inserisci email",
    sendRecoveryLink: "Invia indirizzo di recupero",
    newPassword: "Nuova password",
    logout: "Logout",
  },
  common: {
    tenants: "Prodotti",
    profile: "Profilo",
    new: "Nuovo",
    edit: "Modifica",
    save: "Salva",
    email: "Email",
    role: "Ruolo",
  },
  tenantList: {
    title: "I tuoi prodotti",
  },
  contentTypeList: {
    title: "Home",
  },
  emptyState: {
    noData: "Nessun dato presente",
    unauthorized: "Non possiedi i permessi necessari per questa operazione",
  },
  offlineBanner: {
    message: "Applicazione offline",
  },
  updatePWASnackbar: {
    message:
      "E' disponibile una nuova versione dell'app. Clicca qui per aggiornare.",
  },
  tips: {
    title: "Con Staza puoi",
    tip1: "gestire le cartelle dei tuoi clienti",
    tip2: "integrarti facilmente con altri prodotti esterni",
    tip3: "verificare lo stato avanzamento delle partiche",
    tip4: "consultare ovunque i tuoi documenti",
    tip5: "digitalizzare i documenti aziendali",
    tip6: "consultare report personalizzati",
    tip7: "esportare i tuoi documenti in formati personalizzati",
  },
};
