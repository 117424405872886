/** @flow */
import React from "react";

import { useCubeQuery } from "@cubejs-client/react";

import { Line, Bar, Pie } from "react-chartjs-2";

import Spinner from "../Spinner";
import Table from "../Table";

type ChartRendererType = {
  vizState: {
    query: string,
    chartType: string,
  },
};

const COLORS_SERIES = ["#289FFA", "#2EE6A5", "#FCBB41", "#FD6278"];

const TypeToChartComponent = {
  line: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.category),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        borderColor: COLORS_SERIES[index],
        fill: false,
      })),
    };
    const options = {};
    return <Line data={data} options={options} />;
  },
  bar: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.category),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
        fill: false,
      })),
    };
    const options = {
      scales: {
        xAxes: [
          {
            stacked: true,
          },
        ],
      },
    };
    return <Bar data={data} options={options} />;
  },
  area: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.category),
      datasets: resultSet.series().map((s, index) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES[index],
      })),
    };
    const options = {
      scales: {
        yAxes: [
          {
            stacked: true,
          },
        ],
      },
    };
    return <Line data={data} options={options} />;
  },
  pie: ({ resultSet }) => {
    const data = {
      labels: resultSet.categories().map((c) => c.category),
      datasets: resultSet.series().map((s) => ({
        label: s.title,
        data: s.series.map((r) => r.value),
        backgroundColor: COLORS_SERIES,
        hoverBackgroundColor: COLORS_SERIES,
      })),
    };
    const options = {};
    return <Pie data={data} options={options} />;
  },
  number: ({ resultSet }) => {
    return (
      <div>
        {resultSet.seriesNames().map((s) => (
          <div>{resultSet.totalRow()[s.key]}</div>
        ))}
      </div>
    );
  },
  table: ({ resultSet }) => {
    const formatColumnHeader = (columnKey) => {
      return (
        columnKey
          .split(".")
          .pop()
          // insert a space before all caps
          .replace(/([A-Z])/g, " $1")
          // uppercase the first character
          .replace(/^./, function (str) {
            return str.toUpperCase();
          })
      );
    };

    const columnLabels = (
      <tr>
        {resultSet.tableColumns().map(({ key }, index) => (
          <Table.Cell
            key={`ch-${index}`}
            heading={true}
            style={{ whiteSpace: "nowrap" }}
          >
            {formatColumnHeader(key)}
          </Table.Cell>
        ))}
      </tr>
    );

    const data = resultSet.tablePivot().map((row) => Object.values(row));

    return (
      <div style={{ overflow: "auto" }}>
        <Table.Table head={columnLabels}>
          {data.map((row, rowIndex) => (
            <Table.Row key={rowIndex}>
              {row.map((cell, cellIndex) => (
                <Table.Cell
                  key={`c-${rowIndex}-${cellIndex}`}
                  style={{ whiteSpace: "nowrap" }}
                >
                  {cell}
                </Table.Cell>
              ))}
            </Table.Row>
          ))}
        </Table.Table>
      </div>
    );
  },
};

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key]),
  }))
  .reduce((a, b) => ({ ...a, ...b }));

const renderChart = (Component) => ({ resultSet, error }) =>
  (resultSet && <Component resultSet={resultSet} />) ||
  (error && error.toString()) || <Spinner />;

const ChartRenderer = ({ vizState = {} }: ChartRendererType) => {
  const { query, chartType } = vizState;
  const component = TypeToMemoChartComponent[chartType];
  const renderProps = useCubeQuery(query);
  return component && renderChart(component)(renderProps);
};

export default ChartRenderer;
