/* @flow */
// https://github.com/s-yadav/react-number-format
import React, { useCallback, useState, useEffect } from "react";

import Input from "../../components/Input";
import widgetUtils from "./utils";
import typeUtils from "../../utils/type";
import numberUtils from "../../utils/number";
import inputUtils from "../../utils/input";
import { fixLeadingZero } from "../../utils/string";
import WidgetReadOnlyEmptyValue from "../../components/WidgetReadOnlyEmptyValue";

type Props = {|
  id: string,
  name?: string,
  onBlur?: (event: any) => any,
  onChange?: (value: ?number) => any,
  value?: number,
  defaultValue?: number,
  min?: number,
  max?: number,
  step?: number,
  readOnly?: boolean,
  valueFormat: "integer" | "number",
  isOnline?: boolean,
  mask: string,
  prefix?: string,
  suffix?: string,
  format?: string,
  decimalScale?: number,
  fixedDecimalScale?: boolean,
  decimalSeparator?: string,
  thousandSeparator?: string | boolean,
  allowedDecimalSeparators?: string[],
  allowLeadingZeros?: boolean,
  allowEmptyFormatting?: boolean,
  allowNegative?: boolean,
  thousandsGroupStyle?: "thousand" | "lakh" | "wan",
  isNumericString?: boolean,
|};

const NumberWidget = ({
  id,
  name,
  onBlur,
  onChange,
  value,
  defaultValue,
  min,
  max,
  step,
  readOnly = false,
  valueFormat = "number",
  isOnline,
  mask = " ",
  prefix = "",
  suffix = "",
  format,
  decimalScale = 2,
  fixedDecimalScale,
  decimalSeparator = ",",
  thousandSeparator,
  allowedDecimalSeparators,
  allowLeadingZeros = false,
  allowEmptyFormatting = false,
  allowNegative = false,
  thousandsGroupStyle = "thousand",
  isNumericString = false,
}: Props) => {
  const [initialValue] = useState(value != null ? value : defaultValue);
  const [inputValue, setInputValue] = useState(
    widgetUtils.formatValueProp(
      initialValue || "",
      mask,
      format,
      prefix,
      suffix,
      allowNegative,
      decimalScale,
      fixedDecimalScale,
      allowEmptyFormatting,
      isNumericString,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
      thousandsGroupStyle
    )
  );
  const [inputValueAsString, setInputValueAsString] = useState(
    value ? value.toString() : defaultValue ? defaultValue.toString() : ""
  );

  let focusTimeout: TimeoutID;
  let focusedElm: HTMLElement | null;
  let selectionBeforeInput = {
    selectionStart: 0,
    selectionEnd: 0,
  };

  const getValueObject = (formattedValue: string, numAsString: string) => {
    const floatValue = parseFloat(numAsString);

    return {
      formattedValue,
      value: numAsString,
      floatValue: isNaN(floatValue) ? undefined : floatValue,
    };
  };

  /** caret specific methods **/
  const setPatchedCaretPosition = (
    el: HTMLInputElement,
    caretPos: number,
    currentValue: string
  ) => {
    /* setting caret position within timeout of 0ms is required for mobile chrome,
    otherwise browser resets the caret position after we set it
    We are also setting it without timeout so that in normal browser we don't see the flickering */
    inputUtils.setCaretPosition(el, caretPos);
    setTimeout(() => {
      if (el.value === currentValue) {
        inputUtils.setCaretPosition(el, caretPos);
      }
    }, 0);
  };

  /* This keeps the caret within typing area so people can't type in between prefix or suffix */
  const getCorrectCaretPosition = (
    value: string,
    caretPos: number,
    direction?: string
  ) => {
    //if value is empty return 0
    if (value === "") return 0;

    //caret position should be between 0 and value length
    caretPos = numberUtils.clamp(caretPos, 0, value.length);

    //in case of format as number limit between prefix and suffix
    if (!format) {
      const hasNegation = value[0] === "-";
      return numberUtils.clamp(
        caretPos,
        prefix.length + (hasNegation ? 1 : 0),
        value.length - suffix.length
      );
    }

    //in case if custom format method don't do anything
    //if (typeof format === "function") return caretPos;

    /* in case format is string find the closest # position from the caret position */

    //in case the caretPos have input value on it don't do anything
    if (format[caretPos] === "#" && numberUtils.charIsNumber(value[caretPos]))
      return caretPos;

    //if caretPos is just after input value don't do anything
    if (
      format[caretPos - 1] === "#" &&
      numberUtils.charIsNumber(value[caretPos - 1])
    )
      return caretPos;

    //find the nearest caret position
    const firstHashPosition = format.indexOf("#");
    const lastHashPosition = format.lastIndexOf("#");

    //limit the cursor between the first # position and the last # position
    caretPos = numberUtils.clamp(
      caretPos,
      firstHashPosition,
      lastHashPosition + 1
    );

    const nextPos = format.substring(caretPos, format.length).indexOf("#");
    let caretLeftBound = caretPos;
    const caretRightBound = caretPos + (nextPos === -1 ? 0 : nextPos);

    //get the position where the last number is present
    while (
      caretLeftBound > firstHashPosition &&
      (format[caretLeftBound] !== "#" ||
        !numberUtils.charIsNumber(value[caretLeftBound]))
    ) {
      caretLeftBound -= 1;
    }

    const goToLeft =
      !numberUtils.charIsNumber(value[caretRightBound]) ||
      (direction === "left" && caretPos !== firstHashPosition) ||
      caretPos - caretLeftBound < caretRightBound - caretPos;

    if (goToLeft) {
      //check if number should be taken after the bound or after it
      //if number preceding a valid number keep it after
      return numberUtils.charIsNumber(value[caretLeftBound])
        ? caretLeftBound + 1
        : caretLeftBound;
    }

    return caretRightBound;
  };

  const getCaretPosition = useCallback(
    (currentInputValue: string, formattedValue: string, caretPos: number) => {
      const numRegex = widgetUtils.getNumberRegex(
        true,
        false,
        decimalScale,
        format,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators
      );
      const inputNumber = (currentInputValue.match(numRegex) || []).join("");
      const formattedNumber = (formattedValue.match(numRegex) || []).join("");
      let j, i;

      j = 0;

      for (i = 0; i < caretPos; i++) {
        const currentInputChar = currentInputValue[i] || "";
        const currentFormatChar = formattedValue[j] || "";
        //no need to increase new cursor position if formatted value does not have those characters
        //case currentInputValue = 1a23 and formattedValue =  123
        if (
          !currentInputChar.match(numRegex) &&
          currentInputChar !== currentFormatChar
        )
          continue;

        //When we are striping out leading zeros maintain the new cursor position
        //Case currentInputValue = 00023 and formattedValue = 23;
        if (
          currentInputChar === "0" &&
          currentFormatChar.match(numRegex) &&
          currentFormatChar !== "0" &&
          inputNumber.length !== formattedNumber.length
        )
          continue;

        //we are not using currentFormatChar because j can change here
        while (
          currentInputChar !== formattedValue[j] &&
          j < formattedValue.length
        ) {
          j++;
        }

        j++;
      }

      if (typeof format === "string" && !inputValue) {
        //set it to the maximum value so it goes after the last number
        j = formattedValue.length;
      }

      //correct caret position if its outside of editable area
      j = getCorrectCaretPosition(formattedValue, j);

      return j;
    },
    [
      format,
      inputValue,
      decimalScale,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
    ]
  );

  const updateValue = useCallback(
    (params: {
      formattedValue: string,
      numAsString?: string,
      inputValue?: string,
      input?: HTMLInputElement,
      caretPos?: number,
    }) => {
      const { formattedValue, input } = params;
      let { numAsString, caretPos } = params;

      //set caret position, and value imperatively when element is provided
      if (input) {
        //calculate caret position if not defined
        if (!caretPos) {
          const newInputValue = params.inputValue || input.value;

          const currentCaretPosition = inputUtils.getCurrentCaretPosition(
            input
          );

          //get the caret position
          caretPos = getCaretPosition(
            newInputValue,
            formattedValue,
            currentCaretPosition
          );
        }

        //set the value imperatively, this is required for IE fix
        input.value = formattedValue;

        //set caret position
        setPatchedCaretPosition(input, caretPos, formattedValue);
      }

      //calculate numeric string if not passed
      if (numAsString === undefined) {
        numAsString = widgetUtils.removeFormatting(
          formattedValue,
          format,
          decimalScale,
          decimalSeparator,
          thousandSeparator,
          allowedDecimalSeparators
        );
      }

      //update state if value is changed
      if (formattedValue !== inputValue) {
        const number = typeUtils.coerceToType(valueFormat, numAsString);

        if (isNaN(number)) {
          setInputValue(String(initialValue));
          setInputValueAsString(String(initialValue));

          onChange && onChange(initialValue);
        } else {
          setInputValue(formattedValue);
          setInputValueAsString(numAsString);

          onChange && onChange(number);
        }
      }
    },
    [
      inputValue,
      valueFormat,
      initialValue,
      format,
      decimalScale,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
    ]
  );

  const handleChange = useCallback(
    (event: any) => {
      if (readOnly) return;

      event.persist();

      const el = event.target;
      let newInputValue = el.value;

      const lastValue = inputValue || initialValue || "";

      const currentCaretPosition = inputUtils.getCurrentCaretPosition(el);

      newInputValue = widgetUtils.correctInputValue(
        currentCaretPosition,
        lastValue.toString(),
        newInputValue,
        inputValueAsString,
        prefix,
        suffix,
        format,
        decimalScale,
        fixedDecimalScale,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators,
        selectionBeforeInput,
        allowNegative
      );

      let formattedValue =
        widgetUtils.formatInput(
          newInputValue,
          mask,
          format,
          prefix,
          suffix,
          allowNegative,
          decimalScale,
          decimalSeparator,
          thousandSeparator,
          allowedDecimalSeparators,
          allowEmptyFormatting,
          fixedDecimalScale,
          thousandsGroupStyle
        ) || "";

      const numAsString = widgetUtils.removeFormatting(
        formattedValue,
        format,
        decimalScale,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators
      );

      updateValue({
        formattedValue,
        numAsString,
        inputValue: newInputValue,
        input: el,
      });
    },
    [
      onChange,
      readOnly,
      valueFormat,
      setInputValue,
      inputValue,
      initialValue,
      inputValueAsString,
      prefix,
      suffix,
      format,
      decimalScale,
      fixedDecimalScale,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
      selectionBeforeInput,
      allowNegative,
      mask,
      allowEmptyFormatting,
      thousandsGroupStyle,
      setInputValueAsString,
    ]
  );

  const handleBlur = useCallback(
    (event) => {
      focusedElm = null;
      if (focusTimeout) {
        clearTimeout(focusTimeout);
      }

      let numAsString = inputValueAsString;
      let lastValue = inputValue;

      if (!format) {
        if (!allowLeadingZeros) {
          numAsString = fixLeadingZero(numAsString);
        }

        const formattedValue = widgetUtils.formatNumString(
          numAsString,
          mask,
          format,
          allowEmptyFormatting,
          decimalScale,
          fixedDecimalScale,
          prefix,
          suffix,
          allowNegative,
          thousandsGroupStyle,
          decimalSeparator,
          thousandSeparator,
          allowedDecimalSeparators
        );

        //change the state
        if (formattedValue !== lastValue) {
          // the event needs to be persisted because its properties can be accessed in an asynchronous way
          event.persist();
          lastValue = numAsString;

          updateValue({ formattedValue, numAsString });
        }
      }
      onBlur && onBlur(event);
    },
    [
      onBlur,
      inputValue,
      setInputValue,
      onChange,
      initialValue,
      valueFormat,
      inputValueAsString,
      mask,
      format,
      allowEmptyFormatting,
      decimalScale,
      fixedDecimalScale,
      prefix,
      suffix,
      allowNegative,
      thousandsGroupStyle,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
    ]
  );

  const handleFocus = useCallback((event) => {
    const el = event.target;
    const { selectionStart, selectionEnd, value = "" } = el;

    const caretPosition = getCorrectCaretPosition(value, selectionStart);

    //setPatchedCaretPosition only when everything is not selected on focus (while tabbing into the field)
    if (
      caretPosition !== selectionStart &&
      !(selectionStart === 0 && selectionEnd === value.length)
    ) {
      setPatchedCaretPosition(el, caretPosition, value);
    }
  }, []);

  /** required to handle the caret position when click anywhere within the input **/
  const handleMouseUp = useCallback((event) => {
    const el = event.target;

    /**
     * NOTE: we have to give default value for value as in case when custom input is provided
     * value can come as undefined when nothing is provided on value prop.
     */
    const { selectionStart, selectionEnd, value = "" } = el;

    if (selectionStart === selectionEnd) {
      const caretPosition = getCorrectCaretPosition(value, selectionStart);
      if (caretPosition !== selectionStart) {
        setPatchedCaretPosition(el, caretPosition, value);
      }
    }
  }, []);

  const handleKeyDown = useCallback(
    (event) => {
      const el = event.target;
      const { key } = event;
      const { selectionStart, selectionEnd, value = "" } = el;
      let expectedCaretPosition;

      const ignoreDecimalSeparator =
        decimalScale !== undefined && fixedDecimalScale;
      const numRegex = widgetUtils.getNumberRegex(
        false,
        !!ignoreDecimalSeparator ? ignoreDecimalSeparator : false,
        decimalScale,
        format,
        decimalSeparator,
        thousandSeparator,
        allowedDecimalSeparators
      );
      const negativeRegex = new RegExp("-");
      const isPatternFormat = typeof format === "string";

      selectionBeforeInput = {
        selectionStart,
        selectionEnd,
      };

      //Handle backspace and delete against non numerical/decimal characters or arrow keys
      if (key === "ArrowLeft" || key === "Backspace") {
        expectedCaretPosition = selectionStart - 1;
      } else if (key === "ArrowRight") {
        expectedCaretPosition = selectionStart + 1;
      } else if (key === "Delete") {
        expectedCaretPosition = selectionStart;
      }

      //if expectedCaretPosition is not set it means we don't want to Handle keyDown
      //also if multiple characters are selected don't handle
      if (
        expectedCaretPosition === undefined ||
        selectionStart !== selectionEnd
      ) {
        // place here onKeyDown event to parent, if required
        return;
      }

      let newCaretPosition = expectedCaretPosition;
      const leftBound =
        isPatternFormat && format ? format.indexOf("#") : prefix.length;
      const rightBound =
        isPatternFormat && format
          ? format.lastIndexOf("#") + 1
          : value.length - suffix.length;

      if (key === "ArrowLeft" || key === "ArrowRight") {
        const direction = key === "ArrowLeft" ? "left" : "right";
        newCaretPosition = getCorrectCaretPosition(
          value,
          expectedCaretPosition,
          direction
        );
      } else if (
        key === "Delete" &&
        !numRegex.test(value[expectedCaretPosition]) &&
        !negativeRegex.test(value[expectedCaretPosition])
      ) {
        while (
          !numRegex.test(value[newCaretPosition]) &&
          newCaretPosition < rightBound
        )
          newCaretPosition++;
      } else if (
        key === "Backspace" &&
        !numRegex.test(value[expectedCaretPosition])
      ) {
        /* NOTE: This is special case when backspace is pressed on a
          negative value while the cursor position is after prefix. We can't handle it on onChange because
          we will not have any information of keyPress
          */
        if (
          selectionStart <= leftBound + 1 &&
          value[0] === "-" &&
          typeof format === "undefined"
        ) {
          const newValue = value.substring(1);
          //persist event before performing async task
          event.persist();

          updateValue({
            formattedValue: newValue,
            caretPos: newCaretPosition,
            input: el,
          });
        } else if (!negativeRegex.test(value[expectedCaretPosition])) {
          while (
            !numRegex.test(value[newCaretPosition - 1]) &&
            newCaretPosition > leftBound
          ) {
            newCaretPosition--;
          }
          newCaretPosition = getCorrectCaretPosition(
            value,
            newCaretPosition,
            "left"
          );
        }
      }

      if (
        newCaretPosition !== expectedCaretPosition ||
        expectedCaretPosition < leftBound ||
        expectedCaretPosition > rightBound
      ) {
        event.preventDefault();
        setPatchedCaretPosition(el, newCaretPosition, value);
      }

      /* NOTE: this is just required for unit test as we need to get the newCaretPosition,
            Remove this when you find different solution */
      if (event.isUnitTestRun) {
        setPatchedCaretPosition(el, newCaretPosition, value);
      }
    },
    [
      decimalScale,
      format,
      decimalSeparator,
      thousandSeparator,
      allowedDecimalSeparators,
    ]
  );

  if (readOnly) {
    return <div>{inputValue || <WidgetReadOnlyEmptyValue />}</div>;
  }

  return (
    <div>
      <Input
        id={id}
        name={name}
        onBlur={handleBlur}
        onChange={handleChange}
        onMouseUp={handleMouseUp}
        onFocus={handleFocus}
        onKeyDown={handleKeyDown}
        value={inputValue}
        min={min}
        max={max}
        step={step}
        readOnly={readOnly || !isOnline}
      />
    </div>
  );
};

NumberWidget.mapSchemaToProps = (schema: Object, uiSchema: Object) => {
  let props = {};
  props.defaultValue = schema.default || uiSchema["ui:emptyValue"];
  props.autoFocus = !!uiSchema["ui:autofocus"];
  props.min = schema.minimum || uiSchema.minimum;
  props.max = schema.maximum || uiSchema.maximum;
  props.step = schema.multipleOf || uiSchema.multipleOf;
  props.readOnly = schema.readOnly;
  props.prefix = schema.prefix || uiSchema.prefix;
  props.mask = schema.mask || uiSchema.mask;
  props.suffix = schema.suffix || uiSchema.suffix;
  props.format = schema.format || uiSchema.format;
  props.decimalScale = schema.decimalScale || uiSchema.decimalScale;
  props.fixedDecimalScale =
    schema.fixedDecimalScale || uiSchema.fixedDecimalScale;
  props.decimalSeparator = schema.decimalSeparator || uiSchema.decimalSeparator;
  props.thousandSeparator =
    schema.thousandSeparator || uiSchema.thousandSeparator;
  props.allowedDecimalSeparators =
    schema.allowedDecimalSeparators || uiSchema.allowedDecimalSeparators;
  props.allowLeadingZeros =
    schema.allowLeadingZeros || uiSchema.allowLeadingZeros;
  props.allowEmptyFormatting =
    schema.allowEmptyFormatting || uiSchema.allowEmptyFormatting;
  props.allowNegative = schema.allowNegative || uiSchema.allowNegative;
  props.thousandsGroupStyle =
    schema.thousandsGroupStyle || uiSchema.thousandsGroupStyle;
  props.isNumericString = schema.isNumericString || uiSchema.isNumericString;

  if (schema.type === "integer") {
    props.valueFormat = "integer";
  }
  return props;
};

export default NumberWidget;
