/* @flow */
import styled from "styled-components";

const StyledRoot = styled.div`
  position: relative;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;

  font-family: ${(props) => props.theme.global.typography.paragraph.fontFamily};
  font-size: ${(props) => props.theme.global.typography.paragraph.fontSize};
  // font-weight: ${(props) =>
    props.theme.global.typography.paragraph.fontWeight};

  h1,
  h2,
  h3 {
    font-family: ${(props) => props.theme.global.typography.heading.fontFamily};
    // font-size: ${(props) => props.theme.global.typography.heading.fontSize};
    // font-weight: ${(props) =>
      props.theme.global.typography.heading.fontWeight};
  }

  code: {
    font-family: ${(props) =>
      props.theme.global.typography.paragraph.fontFamily};
    font-size: ${(props) => props.theme.global.typography.paragraph.fontSize};
    font-weight: ${(props) =>
      props.theme.global.typography.paragraph.fontWeight};
  }
`;

export default StyledRoot;
