/* @flow */
import React from "react";

import StyledButton from "./styled";
import Spinner from "../Spinner";
import Icon from "../Icon";

import type { Icon as TypeIcon } from "../../types";

type Props = {
  children?: any,
  model?: "primary" | "secondary",
  color?: string,
  size?: "small" | "medium" | "large",
  loading?: boolean,
  block?: boolean,
  icon?: TypeIcon,
};

const Button = ({ loading, icon, children, model, ...otherProps }: Props) => {
  const ButtonComponent =
    model === "secondary"
      ? StyledButton.SecondaryButton
      : StyledButton.PrimaryButton;
  return (
    <ButtonComponent type={"button"} {...otherProps}>
      {loading && <Spinner type={"spin"} />}
      {!loading && icon && <Icon type={icon} />}
      <span>{!loading && children}</span>
    </ButtonComponent>
  );
};

export default Button;
